/* global BigInt */

import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import ContextApi from "./components/context/ContextApi";
import { store } from './store/store'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import { Web3AuthProvider } from './context/useWeb3auth'
import { WagmiProviderWrapper } from "./context/useWagmiConnector";
import { ReactSVG } from "react-svg";
import { Check, CloseSmall, I } from "./assets/images";
import useWindowDimensions from "./context/useWindowDimmension";

const App = () => {
  const { width } = useWindowDimensions()

  return (
    <WagmiProviderWrapper>
      <Web3AuthProvider>
        <Provider store={store}>
          <BrowserRouter>
            <ContextApi>
              <MainRoutes />
            </ContextApi>
            <ToastContainer
              theme="dark"
              autoClose={width < 520 ? 2000 : 5000}
              closeOnClick
              icon={({ theme, type }) => {
                if (type === 'error')
                  return <ReactSVG src={CloseSmall} className="svg_container" />
                else if (type === 'success')
                  return <ReactSVG src={Check} className="svg_container" />
                else return <ReactSVG src={I} className="svg_container info_svg_width_24" />
              }} />
          </BrowserRouter>
        </Provider>
      </Web3AuthProvider>
    </WagmiProviderWrapper>
  );
};

export default App;
