import { Select } from "antd";

import { useMemo, useState, useRef, useEffect } from "react";
import { debounce } from 'lodash'
import { toast } from "react-toastify";

import api from "../../util/api";
import {
    MUserWhite,
} from "../../assets/images";
import { delayForMS, somethingWrong } from "../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { updateEventAction, updateEventLoadingStatus } from "../../store/slices/eventSlice";

export function InviteFriends() {
    const fetchRef = useRef(0);
    const divRef = useRef(null);
    const dispatch = useDispatch();
    const { event } = useSelector((state) => state.event);
    const { _id, invitations } = event ?? {}

    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState([]) //{ value: val?._id, label: val?.username, key: val?._id }

    useEffect(() => {
        const searchInput = document.getElementById('inviteFriendsContainer');

        const onClick = () => {
            setTimeout(() => {
                // Check if the device is an iOS device
                const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                // If it's an iOS device, scroll the page to show the input field
                if (isIOS) {
                    window.scrollTo(0, searchInput.offsetTop);
                }
            }, 400);
        };

        // Add the event listener
        if (searchInput) {
            searchInput?.addEventListener('click', onClick)
        }

        return () => {
            // Later, when you want to remove the event listener
            if (searchInput) {
                searchInput?.removeEventListener('click', onClick);
            }
        }
    }, [])

    useEffect(() => {
        if (Array.isArray(invitations))
            setSelected(invitations.map((val) => {
                return { value: val?._id, label: val?.username, key: val?._id }
            }))
    }, [invitations])

    function handleClose(open) {
        console.log('open', open, selected)
        if (open === false && _id) {
            handleCreateInvitations()
        }
    }

    async function handleDeselect(value) {
        await delayForMS(1000)
        handleCreateInvitations()
    }

    async function handleCreateInvitations() {
        try {
            dispatch(updateEventLoadingStatus('loading'))
            const alreadyInvited = invitations.map((val) => val._id)
            const newInvitations = selected.filter(val => !alreadyInvited.includes(val?.value)).map(val => val?.value)

            const updatedInvitations = selected.map(val => val?.value)

            if ((Array.isArray(newInvitations) && newInvitations.length) || (invitations.length !== updatedInvitations.length)) {
                dispatch(updateEventLoadingStatus('loading'))
                await api.patch(`event/${_id}`, { invitations: updatedInvitations, newInvitations })
                dispatch(updateEventAction({ ...event, invitations: selected.map((val) => { return { _id: val?.value, username: val?.label } }) }))
                toast.success('Invitation updated successfully')
            }

        } catch (ex) {
            console.log('ex', ex)
            toast.error(somethingWrong)
        } finally {
            dispatch(updateEventLoadingStatus('idle'))
        }
    }

    const handleSelectChange = (value) => {
        console.log('change called', value)
        setSelected(value)
    };

    const debounceTimeout = 800
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            api.get(`user/search?text=${value}`)
                .then((res) => {
                    if (fetchId !== fetchRef.current) {
                        return;
                    }
                    if (Array.isArray(res?.data))
                        setOptions(res?.data);
                    setFetching(false);
                }).catch((ex) => {
                    toast.error('Something went wrong while searching for users! Please try again')
                })
        };
        return debounce(loadOptions, debounceTimeout);
    }, [
        // fetchOptions, 
        debounceTimeout]);

    return (
        <div id={'inviteFriendsContainer'} className="select-container">
            <Select
                id="inviteFriends"
                value={selected}
                labelInValue
                filterOption={false}
                options={options}
                mode="multiple"
                className=""
                onSearch={debounceFetcher}
                fieldNames={{ label: 'username', value: '_id' }}
                onChange={handleSelectChange}
                onDeselect={handleDeselect}
                onDropdownVisibleChange={handleClose}
                placeholder='Type username to invite friend'
            />
            <img src={MUserWhite} className="custom-suffix" />
        </div>)

}