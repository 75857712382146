import React from "react";
import { Typography } from "@mui/material";
import { DollarDark } from "../../../assets/images";
import { formatNumber } from "../../../util/helper";
import { get } from "lodash";
import { ModalContainer } from "../../modalContainer";
import Button from "../../button/Button";

const RefundOrKickPlayer = ({ data, onCancel, onConfirm, open }) => {
    const { type, player } = data ?? {}
    const { user, amount } = player ?? {}
    return (
        <ModalContainer
            open={open}
            handleClose={onCancel}
            heading={`${type === 'REFUND' ? 'Refund' : 'Kick'} participant`}>
            <Typography
                id="modal-modal-description"
                sx={{ mt: 2, color: "#FFFFFF" }}
            >
                Are you sure you want to {type === 'REFUND' ? 'refund' : 'kick'} the following participant :
            </Typography>
            <div className="box__one__cancelRefund">
                <div className="box__one__left">
                    <span> {get(user, '[0].username')} </span>
                </div>
                <div className="box__one__right">
                    <span className="span_one">
                        <img src={DollarDark} alt="" />
                        <p>{formatNumber(amount ?? 0)}</p>
                    </span>
                </div>
            </div>
            <div className="cancel_refund_btns">
                <Button onClick={onCancel} colorVariant="pink" >
                    <span>Cancel</span>
                </Button>
                <Button onClick={onConfirm} colorVariant="green" >
                    <span>Confirm</span>
                </Button>
            </div>
        </ModalContainer>
    );
};

export default RefundOrKickPlayer;
