import React from "react";
import "./comment.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close } from "../../../assets/images";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const AddComment = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button onClick={handleOpen}>New comment </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            Add a comment
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="input__groups__outcome">
              <label> Name your event </label>
              <textarea type="text" placeholder="Write something..." />
            </div>
            <button className="popups__btn"> Send </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default AddComment;
