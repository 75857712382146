import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../util/api';
import { queryString } from '../../util/helper';

export const ORGANISED_EVENT = "ORGANISED"
export const CURRENT_EVENT = "CURRENT"
export const HISTORY_EVENT = "HISTORY"

// Define the async thunk to fetch data from the API
export const fetchTrendingEventsAction = createAsyncThunk('event/fetchEventsTrending', async (query) => {
    try {
        const response = await api.get(`event?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchTimeUpEventsAction = createAsyncThunk('event/fetchEventsTimeUp', async (query) => {
    try {
        const response = await api.get(`event?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});


// Define the async thunk to fetch data from the API
export const fetchEventsAction = createAsyncThunk('event/fetchEvents', async (query) => {
    try {
        const response = await api.get(`event?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchTimeUpEventsActionAll = createAsyncThunk('event/fetchEventsTimeUpAll', async (query) => {
    try {
        const response = await api.get(`event?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchEventAction = createAsyncThunk('event/fetchEvent', async ({ eventId, query = {} }) => {
    try {
        const response = await api.get(`event/${eventId}?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});


export const fetchUserEventsAction = createAsyncThunk('event/fetchUserEvents', async (query) => {
    try {
        const response = await api.get(`event/user?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchUserOrganisedEvent = createAsyncThunk('event/organisedUserEvent', async (query) => {
    try {
        const response = await api.get(`event/userorganisedevents?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchFriendsTrendingEventsAction = createAsyncThunk('event/fetchFriendsTrendingEventsAction', async (query) => {
    try {
        const response = await api.get(`event/friends?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchFriendsEventsAction = createAsyncThunk('event/fetchFriendsEvents', async (query) => {
    try {
        const response = await api.get(`event/friends?${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchFriendsLeaderboardAction = createAsyncThunk('event/fetchFriendsLeaderboardAction', async (query) => {
    try {
        const response = await api.get(`user/leaderboard?leaderBoardType=FRIEND&${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});

export const fetchOrganisersLeaderboardAction = createAsyncThunk('event/fetchOrganisers', async (query) => {
    try {
        const response = await api.get(`user/leaderboard?leaderBoardType=ORGANIZER&${queryString(query)}`);
        return response;
    } catch (error) {
        throw error;
    }
});