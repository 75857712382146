import React, { useState } from "react";
import "../games/games.scss";
import EventCard from "../eventCard";
import BottomContentInfo from "./bottomContentInfo";
import { formatNumber } from "../../util/helper";
import { get } from "lodash";
import { TabsComponent } from "../TabsComponent";
import moment from "moment";

const MyGamesContainer = ({
  tab,
  handleClick,
  handleOpen,
  handleOpenLink,
  userHistoryEvents,
  userCurrentEvents,
  userOrganisedEvents,
  otherUserData,
  className
}) => {

  const [organisedSubTab, setOrganisedSubTab] = useState('')

  return (
    <>
      <div className={`my__games ${className}`}>
        <div className=" tabs_container">
          <TabsComponent
            tabsList={[{ name: 'History', value: 'history' }, { name: 'Current', value: 'current' }, { name: 'Organised', value: 'organised' }]}
            setTab={(val) => {
              handleClick(val);
              setOrganisedSubTab('')
            }}
            currentTab={tab}
          />
          {
            tab === "organised" ?
              <TabsComponent
                tabsList={[{ name: 'Participate time', value: 'playtime' }, { name: 'Waiting decision', value: 'decision' }, { name: 'Done', value: 'done' }]}
                setTab={(val) => setOrganisedSubTab(val)}
                currentTab={organisedSubTab}
                sizeVariant="small"
                className="mt_6"
              />
              : <></>
          }
        </div>

        {tab === "history" &&
          userHistoryEvents &&
          Array.isArray(userHistoryEvents)
          ? userHistoryEvents.map((historyEventData) => (
            <>
              <EventCard data={historyEventData} />
              <div className="bottom_info">
                {historyEventData?.plays?.length > 0 &&
                  historyEventData.plays.map((item, index) => {
                    const totalAmount = Number(item.totalAmount);
                    const price = formatNumber(totalAmount);
                    const playStatus = get(item, 'challenge[0].playStatus');
                    const potentialWin = get(item, 'challenge[0].claim[0].amount', 0)
                    const formattedPotentialPrice = formatNumber(playStatus === "WIN" ? potentialWin : totalAmount);
                    const potentialPrice = potentialWin < 0 ? price : formattedPotentialPrice;
                    const Profit_Loss =
                      playStatus === "WIN" && potentialWin >= 0
                        ? "Won"
                        : playStatus === "LOSS" || potentialWin < 0
                          ? "Lost"
                          : playStatus === "REFUND"
                            ? "Refund"
                            : "Lost";
                    const backgroundColor = playStatus === "WIN" && potentialWin >= 0 ? "#cae9ff" : "#FFCACA";
                    const title = get(item, 'challenge[0].title', "#Unknown event");
                    return (
                      <BottomContentInfo
                        roundedBottom={index === historyEventData?.plays.length - 1}
                        variant={playStatus === 'WIN' && potentialWin >= 0 ? 'green' : 'pink'}
                        playPrice={price || "0K"}
                        potentialPrice={potentialPrice}
                        Profit_Loss={Profit_Loss}
                        handleOpen={handleOpen}
                        playOnContent={title}
                      />
                    );
                  })}
              </div>
            </>
          ))
          : null}

        {tab === "current" &&
          userCurrentEvents &&
          Array.isArray(userCurrentEvents)
          ? userCurrentEvents.map((currenEvent) => (
            <>
              <EventCard data={currenEvent} />
              <div className="bottom_info">
                {currenEvent?.plays?.length > 0 &&
                  currenEvent.plays.map((item, index) => {
                    const totalAmount = Number(item.totalAmount);
                    const price = formatNumber(totalAmount);
                    const playStatus = item.challenge[0].playStatus
                    const potentialWin = totalAmount * get(item, 'challenge[0].odd', 1) - totalAmount;
                    const potentialPrice = formatNumber(playStatus === 'REFUND' ? totalAmount : potentialWin);
                    const title = get(item, 'challenge[0].title', "#Unknown event");
                    const Profit_Loss_Status = playStatus === 'REFUND' ? 'Refunded' : 'Potential return';
                    return (
                      <BottomContentInfo
                        roundedBottom={index === currenEvent.plays.length - 1}
                        variant="pink_gray"
                        playPrice={price || "0K"}
                        potentialPrice={potentialPrice || "0K"}
                        handleOpen={handleOpen}
                        playOnContent={title}
                        Profit_Loss={Profit_Loss_Status}
                      />
                    );
                  })}
              </div>
            </>
          ))
          : null}

        {tab === "organised" &&
          userOrganisedEvents &&
          Array.isArray(userOrganisedEvents)
          ? userOrganisedEvents.filter(val => {
            if (organisedSubTab === 'done')
              return val?.status === 'COMPLETE' || val?.status === 'REFUND'
            else if (organisedSubTab === 'decision')
              return val?.status === 'DEFAULT' && moment().isAfter(val?.endTime)
            else if (organisedSubTab === 'playtime')
              return val?.status === 'DEFAULT' && moment().isBefore(val?.endTime)
            return val
          }).map((organisedEvent, ind) => (
            <div key={ind} className="organizer_event_card_container">
              <EventCard data={organisedEvent} type={'ORGANISED'} />
            </div>
          ))
          : null}
      </div>
    </>
  );
};

export default MyGamesContainer;
