import React, { useEffect, useState } from "react";
import "./players.scss";
import { Typography } from "@mui/material";
import {
  noImageProfile,
} from "../../../assets/images";
import { toast } from "react-toastify";
import { formatNumber, somethingWrong } from "../../../util/helper";
import api from "../../../util/api";
import { findIndex, get } from "lodash";
import { BadgeMakaoMoney, BadgePlayerCount } from "../../badge";
import { ModalContainer } from "../../modalContainer";
import { Loader } from "../../loader";
import { useSelector } from "react-redux";

const PlayersPopup = ({ handleClose, open, eventId, data }) => {

  const challenges = useSelector((state) => state.event?.event?.challenges);

  const { playersCount, volume } = data ?? {}
  const [loading, setLoading] = useState(false)
  const [players, setPlayers] = useState([])

  useEffect(() => {
    if (eventId)
      handleGetPlayers()
  }, [eventId])

  async function handleGetPlayers() {
    try {
      setLoading(true)
      const playersRes = await api.get(`event/${eventId}/players?page=1&pageSize=500`)
      setPlayers(playersRes?.data)
    } catch (ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalContainer
      open={open}
      handleClose={handleClose}
      heading="Participants">
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Loader loading={loading} />
        <div className="box__head__players">
          <BadgePlayerCount count={formatNumber(playersCount ?? 0)} />
          <BadgeMakaoMoney amount={formatNumber(volume ?? 0)} />
        </div>
        {
          players.map((val, ind) => (
            <div key={ind} className="box__one__players">
              <div className="box__one__left truncate">
                <img src={get(val, 'user[0].img') ? get(val, 'user[0].img') : noImageProfile} alt=""
                  onError={(event) => {
                    event.target.src = noImageProfile;
                  }} />
                <p className=" truncate"> @{get(val, 'user[0].username')} </p>
              </div>
              <div className="box__one__right">
                <span className="span_one">
                  <p>#{findIndex(challenges, (o) => o?._id === val?.challenge) + 1}</p>
                </span>
                <span className="span_two">
                  <p>{formatNumber(val?.amount)} USDT</p>
                </span>
              </div>
            </div>))
        }
      </Typography>
    </ModalContainer>
  );
};

export default PlayersPopup;
