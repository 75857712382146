import React, { useEffect, useState } from "react";
import "./profile.scss";
import {
  EyeButton,
  LinkButtonIcon,
  Verified,
  ProfileEdit,
  noImageProfile,
  AddFriend,
} from "../../assets/images";
import { Link, useParams } from "react-router-dom";
import MyGamesEventInfo from "../myGamesEventInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserAction } from "../../store/actions/userAction";
import Following from "../Popups/following/Following";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import api from "../../util/api";
import { userLoadingAction } from "../../store/slices/userSlice";
import Button from "../button/Button";
import { Loader } from "../loader";
import { BadgeTrustNote } from "../badge";
import { ReactSVG } from "react-svg";
import ReadMore from "../Popups/readMore/ReadMore";
import useWindowDimensions from "../../context/useWindowDimmension";

const Profile = () => {
  const { _id } = useParams()

  const dispatch = useDispatch();
  const { width } = useWindowDimensions()
  const { user, status, } = useSelector((state) => state.user);
  const { username, description, img, _id: userId, userTrustNote, links, friends, referralCode } = user ?? {}
  const [showFriends, setShowFriends] = useState(false)

  useEffect(() => {
    dispatch(fetchUserAction())
  }, [])

  async function handleAddFriend() {
    try {
      dispatch(userLoadingAction(true))
      const addFriend = await api.post('user/friend', { friendId: _id })

      toast.info('Friend added successfully')
    } catch (_ex) {
      toast.error(somethingWrong)
    } finally {
      dispatch(userLoadingAction(false))
    }
  }

  async function handleCopyReferralLink() {
    if (referralCode) {
      const url = window.location.origin + '/signup/' + referralCode
      await navigator.clipboard.writeText(url);
      toast.success('Referral link copied successfully')
    } else toast.error('Please update your profile once to create referral link!')
  }

  const descriptionCharLength = width > 540 ? 180 : 136

  return (
    <MyGamesEventInfo
      renderLeftProfile={(
        tab,
        openLink,
        tabSat,
        handleOpenLink,
        handleClickSat,
        handleCloseLink,
        MyGamesContainer
      ) => (
        <>
          <Loader loading={status === 'loading'} />
          {openLink &&
            <ReadMore
              handleClose={() => {
                handleCloseLink(false);
              }}
              open={openLink}
              description={description}
              links={links} />}

          <Following _id={_id ?? userId} open={showFriends} setOpen={setShowFriends} />

          <div className="profile">
            <div className="profile__left">
              <Link to="/edit-profile">
                <img src={ProfileEdit} alt="" className="profile_edit" />
              </Link>
              <div className="profile__left__content">
                <div className="profile__img ">
                  <div className="image_container" >
                    <img src={img ?? noImageProfile} alt=""
                      onError={(event) => {
                        event.target.src = noImageProfile;
                      }} />
                    <img
                      src={Verified}
                      alt=""
                      style={{
                        position: "absolute",
                        top: "56%",
                        right: "4px",
                        width: "20px",
                      }}
                    />
                  </div>
                </div>
                <div className="leaderboards__tabs">
                  <div className="box__tabs">
                    <Link
                      to=""
                      className=" truncate"
                    >
                      {username ? `@${username}` : 'unknown'}
                    </Link>
                    <BadgeTrustNote
                      trustNote={userTrustNote}
                      noReview={!(userTrustNote || userTrustNote == 0)}
                      onClick={() => handleClickSat("4.9/5")} />
                    <Link
                      to=""
                      style={{ flexShrink: 0 }}
                    >
                      Rank : 2873
                    </Link>
                  </div>
                </div>
                <div onClick={() => handleOpenLink(true)} className="description pointer">
                  <p
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      color: "white",
                      textShadow: "rgba(255, 255, 255, 0.09)",
                    }}
                  >
                    {description?.slice(0, descriptionCharLength)}
                    {description && description.length > descriptionCharLength ? (
                      <span
                        style={{
                          color: "rgba(255, 255, 255, 0.3)",
                          cursor: "pointer",
                        }}

                        onClick={() => handleCloseLink(true)}>
                        ... Read more
                      </span>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
                <div className="buttons__two">
                  <div className=" width_50">
                    {
                      ((_id === userId) || !_id) ?
                        <Button
                          onClick={() => friends?.length > 0 ?
                            setShowFriends(true) :
                            toast.info('Please add friends to see list.')}
                          disabled={!friends?.length}
                          colorVariant="outline_cyan"
                          iconSvgVariant="stroke_current_color"
                        >
                          <div className=" flex_center">
                            {friends?.length > 0 ?
                              <ReactSVG src={EyeButton} className="svg_container" /> : <></>}
                            <span>{friends?.length > 0 ? 'Friends' : 'No friends'}</span>
                          </div>
                        </Button>
                        :
                        <Button disabled={status === 'loading'} onClick={handleAddFriend}
                          colorVariant="outline_cyan" >
                          <div className=" flex_center">
                            <ReactSVG src={AddFriend} className="svg_container" />
                            <span>Add Friend</span>
                          </div>
                        </Button>
                    }
                  </div>

                  <div className=" width_50">
                    {/* {tab === "trends" ?
                      <Button
                        onClick={() => {
                          toast.info('This feature is coming soon.')
                        }}
                        colorVariant="outline_cyan">
                        <div className=" flex_center truncate">
                          <ReactSVG src={Chart_analysis} className="svg_container" />
                          <span>My stats</span>
                        </div>
                      </Button>
                      : */}
                    <Button onClick={handleOpenLink}
                      colorVariant="outline_cyan"
                      iconSvgVariant="stroke_current_color">
                      <div className=" flex_center">
                        <ReactSVG src={LinkButtonIcon} className="svg_container" />
                        <span>More</span>
                      </div>
                    </Button>
                    {/* } */}
                  </div>
                </div>
                <div className="button_single">
                  <div className="w_fit">
                    <Button onClick={handleCopyReferralLink}
                      colorVariant="dark_outline_cyan">
                      <div className=" flex_center">
                        <ReactSVG src={AddFriend} className="svg_container" />
                        <span>My referral link</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile__right">{MyGamesContainer}</div>
          </div>
        </>
      )}
    />
  );
};

export default Profile;
