import React, { useState } from "react";
import "./CustomStepper.scss";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { Steps } from "antd";

const CustomStepper = ({ step, children }) => {
  // const [step, setStep] = useState(0);

  const titles = [
    {
      title: "Create event",
    },
    {
      title: "Participation time",
    },
    {
      title: "Final decision",
    },
  ];
  //Commented code of now for reffernce, will remove later 

  // const com = [
  //   <StepOne activeStep={step} setActiveStep={setStep} />,
  //   <StepTwo activeStep={step} setActiveStep={setStep} />,
  //   <StepThree />,
  // ];

  return (
    <>
      {/* <Steps
        // onChange={(e) => setStep(e)}
        current={step}
        labelPlacement="vertical"
        items={titles}
      /> */}
      {children}
    </>
  );
};

export default CustomStepper;
