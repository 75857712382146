import "./stapper.scss";
import React, { useState, useEffect } from "react";
import {
  share,
  QRcode,
  LinkBlackBg
} from "../../assets/images";
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import { InviteFriends } from "../inviteFriends";
import { fetchEventAction } from "../../store/actions/eventAction";
import { useDispatch, useSelector } from "react-redux";
import { ChallengeSection } from "../challengeSection";
import { Loader } from "../loader";
import { RefundAndCancelEventActions } from "./RefundAndCancelEventActions";
import Button from "../button/Button";
import { ModalContainer } from "../modalContainer";
import { QRCode } from 'antd';
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { ActionButtons } from "./ActionButtons";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";


const StepTwo = ({
  activeStep,
  setActiveStep,
  ...props
}) => {
  const { _id } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Full path of the current route
  const shareUrl = window.location.origin + '/event/' + _id;
  const { event, status } = useSelector((state) => state.event);
  const { challenges, createdAt, startTime, endTime, decisionTakenTime, name, createdBy, fetchingUserId } = event ?? {}

  const [showShare, setShowShare] = useState(false)
  const [showQR, setShowQR] = useState(false)

  useEffect(() => {
    if (_id)
      dispatch(fetchEventAction({ eventId: _id }))
  }, [_id])

  async function handleCopyLink() {
    await navigator.clipboard.writeText(shareUrl);
    toast.success('Link copied successfully')
  }

  if (createdAt && endTime && !moment().isBetween(createdAt, endTime))
    return <div className=" flex_center min_h_60vh text_white width_90_mx_auto">
      <div>Event participate time has been passed! Please go to <span onClick={() => navigate(`/event/${_id}/decision`)} className="pointer underline">decision</span> or <span onClick={() => navigate(`/event/${_id}`)} className="pointer underline">event</span> page for more information</div>
    </div >

  //If a particular event does't belongs to user
  if (fetchingUserId && createdBy && (fetchingUserId !== createdBy))
    return <div className=" flex_center min_h_60vh text_white width_90_mx_auto">
      <div>This Event does not belongs to you!</div>
    </div>

  return (
    <>
      <Loader loading={status === 'loading'} />

      <div className="form__event">

        <div className="action_buttons_container_organizer">
          <ActionButtons
            usingFor={'ORGANIZER'}
          />
        </div>

        <div className=" w_full challenges_container">
          <ChallengeSection challenges={challenges}
            data={{ startTime, eventId: _id, decisionTakenTime }}
            type='PLAY' />
        </div>

        <div className="share_container">
          <div className=" width_50">
            <Button onClick={() => setShowShare(true)} colorVariant={'green'} >
              <div className=" flex_center">
                <ReactSVG src={share} className="svg_container" />
                <span>Share</span> </div>
            </Button>
          </div>
          <div className=" width_50">
            <Button onClick={() => setShowQR(true)} colorVariant={'green'} iconSvgVariant="stroke_current_color">
              <div className=" flex_center">
                <ReactSVG src={QRcode} className="svg_container" />
                <span>QR code</span> </div>
            </Button>
          </div>
        </div>

        <InviteFriends />

        <div className="bottom">
          <RefundAndCancelEventActions showDistribute={false} data={event} />
        </div>
      </div>

      <ModalContainer handleClose={() => setShowQR(false)} open={showQR}>
        <div className="event__boxes">
          <div className="more__event__box justify_center">
            <span>  Share with QR code </span>
          </div>


          <div className="more__event__box justify_center white__background">
            <QRCode value={shareUrl} />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer handleClose={() => setShowShare(false)} open={showShare}>
        <div className="event__boxes">
          <div className="more__event__box justify_center">
            <span> Share this event </span>
          </div>


          <div className="more__event__box justify_center">
            <div className="Demo__some-network">

              <div onClick={handleCopyLink} className="">
                <img src={LinkBlackBg} alt="" style={{ width: 32, height: 32 }} />
              </div>

            </div>

            <div className="Demo__some-network">
              <FacebookMessengerShareButton
                url={shareUrl}
                appId="521270401588372"
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
            </div>

            <div className="Demo__some-network">
              <TwitterShareButton
                url={shareUrl}
                title={name}
                className="Demo__some-network__share-button"
              >
                <XIcon size={32} round />
              </TwitterShareButton>
            </div>

            <div className="Demo__some-network">
              <TelegramShareButton
                url={shareUrl}
                title={name}
                className="Demo__some-network__share-button"
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>

            <div className="Demo__some-network">
              <WhatsappShareButton
                url={shareUrl}
                title={name}
                separator=", "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
          </div>
        </div>
      </ModalContainer>

    </>
  );
};

export default StepTwo;
