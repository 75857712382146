import React from "react";
import "./notification.scss";
import { BackIcon, Mail, Message, Telegram } from "../../assets/images";
import { Switch } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

const label = { inputProps: { "aria-label": "Color switch demo" } };

const NotificationSettings = () => {
  const navigate = useNavigate();

  const participantNotifications = [
    { label: 'Successful transaction', extra: `Predictions & claims` },
    { label: 'Prediction results', extra: 'Success/Fail' },
    { label: 'Invitations to chalelnges', extra: '' },
    { label: 'Prediction proposal status', extra: '' },
    { label: 'Referral code use', extra: '' },
    { label: 'Review & claim event', extra: '' },
  ]

  const organiserNotifications = [
    { label: 'Event status', extra: '' },
    { label: 'New prediction proposal', extra: '' },
    { label: 'New participation', extra: '' },
  ]

  // const platforms = [
  //   { label: '', icon: Telegram },
  //   { label: '', icon: Mail },
  //   { label: '', icon: Message }
  // ]

  return (
    <div className="notification__settings">
      <div className="notifications__content">
        <div className="noti__head">
          <div className="noti__head__left">
            <ReactSVG onClick={() => navigate(-1)}
              src={BackIcon} className="svg_container pointer" />
          </div>
          <div className="noti__head__right"></div>
        </div>
        {/* <div className="platform_list_container">
          {platforms.map((val, ind) => (<div key={ind} className="switch_label_container">
            <span><ReactSVG src={val?.icon} className="svg_container" /></span>
            <Switch {...label} defaultChecked color="success" />
          </div>))}
        </div> */}
        <div className="noti__divs">
          <div className="section_title">Participant’s notifications</div>
          <div className="noti__div_container">
            {participantNotifications.map((val, ind) => (<div key={ind} className="box">
              <span> {val?.label} {val?.extra ? <span className="title_extra">({val?.extra})</span> : <></>}</span>
              <Switch {...label} defaultChecked color="success" />
            </div>))}
          </div>
        </div>

        <div className="noti__divs">
          <div className="section_title">Organiser’ notifications</div>
          <div className="noti__div_container">
            {organiserNotifications.map((val, ind) => (<div key={ind} className="box">
              <span> {val?.label} {val?.extra ? <span className="title_extra">({val?.extra})</span> : <></>}</span>
              <Switch {...label} defaultChecked color="success" />
            </div>))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
