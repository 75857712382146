import { Box, Modal, useMediaQuery } from "@mui/material";
import { Close } from "../../assets/images";
import './modalContainer.scss'

export function ModalContainer({
    children,
    handleClose,
    open,
    heading,
    topRightSideComponent,
    showClose = true }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: useMediaQuery('(max-width:540px)') ? 2 : 2,
        mt: 1,
        mb: 1
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="popup">
                <div className="heading_container">
                    {showClose && <div className="close_button">
                        <img
                            src={Close}
                            alt="close-img"
                            className=""
                            onClick={handleClose}
                        />
                    </div>}
                    <div className="modal_heading leading_tight">
                        {heading}
                    </div>
                    {topRightSideComponent &&
                        <div className="right_side_extra">
                            {topRightSideComponent}
                        </div>
                    }
                </div>
                {children}
            </Box>
        </Modal>)
}