import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import "./timeLine.scss";
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { toast } from "react-toastify";
import Button from "../../button/Button";
import { ModalContainer } from "../../modalContainer";
import { DateTimeField } from "@mui/x-date-pickers";

const timePicker = [
    { name: '3min', value: 3, key: 'minutes' },
    { name: '5min', value: 5, key: 'minutes' },
    { name: '10min', value: 10, key: 'minutes' },
    { name: '30min', value: 30, key: 'minutes' },
    { name: '1h', value: 1, key: 'hours' },
    { name: '1,5h', value: 1.5, key: 'hours' },
    { name: '3h', value: 3, key: 'hours' },
    { name: '6h', value: 6, key: 'hours' },
    { name: '12h', value: 12, key: 'hours' },
    { name: '1d', value: 1, key: 'days' },
    { name: '2d', value: 2, key: 'days' },
    { name: '7d', value: 7, key: 'days' },
    { name: '15d', value: 15, key: 'days' },
    { name: '1m', value: 1, key: 'months' },
    { name: '3m', value: 1, key: 'months' },
    { name: '6m', value: 6, key: 'months' },
    { name: '1y', value: 1, key: 'years' },
]

function TimeComponent({ t, index, chipState, handleClick, usingFor }) {
    return (
        <div className="time_modal" style={{ backgroundColor: chipState === index ? '#27362C' : '#121316', width: usingFor === 'endTime' ? '38px' : '42px', }}
        >
            <div onClick={() => handleClick(index, t.value, t.key)} >
                {
                    usingFor === 'endTime' ? <p id={t.value}>{t.name}</p> : <p id={t.value}>+{t.name}</p>
                }
            </div>

        </div>
    )
}
function DateWithTimeComponent({ setDateTimeState, dateTimeState, setIsError }) {
    const [error, setError] = useState(false)
    const [date, setDate] = useState()

    const handleDateInputChange = (newValue) => {
        const currentDate = moment();
        const inputDate = moment(newValue);
        if (inputDate.isValid() && inputDate.isAfter(currentDate)) {
            setDate(newValue)
            setDateTimeState(newValue)
            setError(false)
            setIsError(false)
        } else {
            setError(true)
            setIsError(true)
        }
    };
    return (
        <div className="date_time_modal">
            <LocalizationProvider dateAdapter={AdapterMoment} >
                <DateTimeField variant="standard"
                    ampm={false}
                    value={dateTimeState}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    format="DD/MM/YY HH:mm"
                    sx={{
                        "& .MuiInputBase-input": {
                            color: 'white !important',
                            fontSize: '14px !important',
                            textAlign: 'center'
                        }
                    }}
                    slotProps={{
                        textField: {
                            placeholder: 'DD/MM/YY HH:MM',
                            helperText: error ? "Enter future date " : "",
                            error: error
                        }
                    }}
                    onChange={(newValue) => handleDateInputChange(newValue)}
                />
            </LocalizationProvider>
        </div>
    )
}

const EditTimeline = ({ handleClose, open, handleFormDataChangeObject, editTimelineUsingFor, data }) => {
    const { decisionTime, endTime } = data ?? {}

    const [usingFor, setUsingFor] = useState('endTime') //DECISION-TIME
    const [dateTimeState, setDateTimeState] = useState()
    const [dateTimeState2, setDateTimeState2] = useState()
    const [chipOneState, setChipOneState] = useState(false);
    const [chipTwoState, setChipTwoState] = useState(false);
    const [chipDateOneState, setChipDateOneState] = useState()
    const [chipDateTwoState, setChipDateTwoState] = useState()
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (editTimelineUsingFor === 'endTime' || editTimelineUsingFor === 'decisionTime')
            setUsingFor(editTimelineUsingFor)
    }, [editTimelineUsingFor])


    function handleSave() {
        if (isError) {
            return
        }
        if (usingFor === 'endTime') {
            if (!(chipDateOneState || dateTimeState)) {
                toast.error('Please select participation time.')
                return
            }
            if (chipDateOneState) {
                const date = moment().add(chipDateOneState.value, chipDateOneState.unit)
                handleFormDataChangeObject([{ name: 'endTimeCreate', value: moment() }, { name: 'endTime', value: date }])
            }
            if (dateTimeState) {
                handleFormDataChangeObject([{ name: 'endTimeCreate', value: moment() }, { name: 'endTime', value: dateTimeState }])
                setChipOneState('')
            }

            if (!decisionTime)
                setUsingFor('decisionTime')
            else
                handleClose()
        }
        else {
            if (chipDateTwoState) {
                if (endTime) {
                    const date = moment(endTime).add(chipDateTwoState.value, chipDateTwoState.unit)
                    handleFormDataChangeObject([{ name: 'decisionTimeCreate', value: moment() }, { name: 'decisionTime', value: date }])
                }
            }
            if (dateTimeState2) {
                handleFormDataChangeObject([{ name: 'decisionTimeCreate', value: moment() }, { name: 'decisionTime', value: dateTimeState2 }])
                setChipTwoState('')
            }
            setUsingFor('endTime')
            handleClose()
        }
    }


    const handleChange = (index, value, unit) => {
        if (usingFor === 'endTime') {
            if (chipOneState === index) {
                setChipOneState(null);
            } else {
                setChipOneState(index);
                setChipDateOneState({
                    value: value,
                    unit: unit
                })
                const date = moment().add(value, unit)
                setDateTimeState(date)
            }
        } else {
            if (chipTwoState === index) {
                setChipTwoState(null);
            } else {
                setChipTwoState(index);
                setChipDateTwoState({
                    value: value,
                    unit: unit
                })
                const date = moment(dateTimeState).add(value, unit)
                setDateTimeState2(date)
            }
        }
    };

    function handleResetChip() {
        setChipOneState(null);
        setChipTwoState(null);
        setChipDateOneState()
        setChipDateTwoState()
    }


    return (
        <ModalContainer
            open={open}
            handleClose={handleClose}
            heading={usingFor === 'endTime' ? `Participation time` : `Decision deadline`}
            topRightSideComponent={<div style={{ opacity: .5, color: 'white' }} className="font_xm">{usingFor === 'endTime' ? `1/2` : `2/2`}</div>}>
            <>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ maxWidth: 400, color: 'white' }}>
                    <div className=" font_xxs font_normal">
                        {usingFor === 'endTime' ? `After this time, participants can't participate anymore. The odds are fixed and won't change because no further participate is allowed.` : `If the organizer doesn't settle the event, all participants are automatically refunded after the deadline.`}
                    </div>

                    <div className="picker_container">
                        <div className="picker_modal">
                            {
                                usingFor === 'endTime' ?
                                    <>
                                        {
                                            timePicker.map((t, index) => (
                                                <TimeComponent key={index} index={index} t={t} handleClick={handleChange} chipState={chipOneState} usingFor={usingFor} />
                                            ))
                                        }
                                        <DateWithTimeComponent
                                            setDateTimeState={(date) => {
                                                setDateTimeState(date);
                                                handleResetChip();
                                            }}
                                            dateTimeState={dateTimeState}
                                            setIsError={setIsError}
                                        />

                                    </> : <>
                                        {
                                            timePicker.map((t, index) => (
                                                (t.name === '1d' || t.name === '2d' || t.name === '7d' || t.name === '15d' || t.name === '1m' || t.name === '3m' || t.name === '6m' || t.name === '1y') &&
                                                <TimeComponent key={index} index={index} t={t} handleClick={handleChange} chipState={chipTwoState} usingFor={usingFor} />

                                            ))
                                        }
                                        <DateWithTimeComponent
                                            setDateTimeState={(date) => {
                                                setDateTimeState2(date);
                                                handleResetChip();
                                            }}
                                            dateTimeState={dateTimeState2}
                                            setIsError={setIsError}
                                        />
                                    </>
                            }
                        </div>
                    </div>

                    <div className={isError ? " mt_22" : " mt_16"}>
                        <Button onClick={handleSave} colorVariant="light_gray">
                            <div className=" flex_center">
                                {usingFor === 'endTime' ? 'Next' : 'Save'}
                            </div>
                        </Button>
                    </div>
                </Typography>
            </>
        </ModalContainer>
    );
};

export default EditTimeline;