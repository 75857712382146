import { useEffect, useState } from "react";
import { useWeb3Auth } from "../../context/useWeb3auth";
import api from "../../util/api";
import { toast } from "react-toastify";
import { formatNumber, somethingWrong } from "../../util/helper";
import { Loader } from "../loader";
import { findIndex, get } from "lodash";
import EventCard from "../eventCard";
import BottomContentInfo from "../myGamesEventInfo/bottomContentInfo";
import "../games/games.scss";
import Button from "../button/Button";
import ReviewDecision from "../Popups/reviewDecision/ReviewDecision";


export function ChallengesReviewAndClaim() {
    const { loggedIn } = useWeb3Auth()

    const [loading, setLoading] = useState(false)
    const [challengesForReviewsAndClaims, setChallengesForReviewsAndClaims] = useState([])
    const [claimingChallenge, setClaimingChallenge] = useState({})

    useEffect(() => {
        if (loggedIn)
            fetchEventsForReview()

    }, [loggedIn]);

    async function fetchEventsForReview(params) {
        try {
            setLoading(true)
            const { data } = await api.get(
                `event/reviewEventsChallenges`
            );
            setChallengesForReviewsAndClaims(data);
        } catch (ex) {
            toast.error(ex?.message ?? somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    async function handleReviewSuccess(eventId) {
        const rawChallenges = [...challengesForReviewsAndClaims]
        const foundIndex = findIndex(rawChallenges, (val) => val?.eventId === eventId)
        if (foundIndex > -1) {
            rawChallenges.splice(foundIndex, 0)
            setChallengesForReviewsAndClaims(rawChallenges)
        }
    }

    return (
        <div className="challenges_review_and_claim">
            <Loader loading={loading} />
            <div className="title">Events to review & Claim</div>
            <div className="my__games user_events_container">
                {challengesForReviewsAndClaims?.map((val, ind) => (
                    <div key={ind} style={{ display: 'flex', flexDirection: 'column' }}>
                        <EventCard data={get(val, 'event[0]')} />
                        <div className="bottom_info margin_0">
                            {Array.isArray(val?.userChallengeData) && val?.userChallengeData.length > 0 ?
                                val.userChallengeData.map((item, index) => {
                                    const totalAmount = Number(item.amount);
                                    const price = formatNumber(totalAmount);
                                    const playStatus = get(item, 'playStatus');
                                    const potentialWin = get(item, 'claimableAmount', 0)
                                    const formattedPotentialPrice = formatNumber(playStatus === "WIN" ? potentialWin : totalAmount);
                                    const potentialPrice = potentialWin < 0 ? price : formattedPotentialPrice;
                                    const Profit_Loss =
                                        playStatus === "WIN" && potentialWin >= 0
                                            ? "Won"
                                            : playStatus === "LOSS" || potentialWin < 0
                                                ? "Lost"
                                                : playStatus === "REFUND"
                                                    ? "Refund"
                                                    : "Lost";
                                    const title = get(item, 'title', "#Unknown event");
                                    return (
                                        <BottomContentInfo
                                            roundedBottom={index === val?.userChallengeData.length - 1}
                                            variant={playStatus === 'WIN' && potentialWin >= 0 ? 'green' : 'pink'}
                                            playPrice={price || "0K"}
                                            potentialPrice={potentialPrice}
                                            Profit_Loss={Profit_Loss}
                                            playOnContent={title}
                                        />
                                    );
                                }) : <></>}
                        </div>
                        <div className="claim_button">
                            <Button onClick={() => setClaimingChallenge(val)} colorVariant="dark_outline_cyan" className={' w_fit'} >
                                <span>Review & Claim</span>
                            </Button>
                        </div>
                    </div>))}
            </div>
            {
                claimingChallenge?.eventId ?
                    <ReviewDecision
                        handleClose={() => setClaimingChallenge({})}
                        open={true}
                        eventId={get(claimingChallenge, 'event[0]._id')}
                        handleClaimSuccess={() => handleReviewSuccess(get(claimingChallenge, 'eventId'))}
                        blockChain={get(claimingChallenge, 'event[0].blockChain')}
                    />
                    : <></>
            }

        </div>)
}