import React from "react";
import "./edit.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close, Eye } from "../../../assets/images";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditLinks = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button onClick={handleOpen}> Pop up edit links </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            Edit links
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="vertical__buttons__popups">
              <div className="vertical__buttons__first__pop">
                <img src={Eye} alt="img" />
                <span> Watch live event </span>
              </div>
              <h5> https://www.twitch.tv/ </h5>
              <div className="vertical__buttons__secound__pop">
                <span> Join our discord to discuss the event </span>
              </div>
              <h5> https://discord.com/ </h5>
              <div className="vertical__buttons__third__pop">
                <span> Steve on Instagram </span>
              </div>
              <h5> https://www.instagram.com/ </h5>
              <div className="vertical__buttons__third__pop">
                <span> Link name </span>
              </div>
              <h5> https://www.newlink/ </h5>
            </div>
          </Typography>
          <button className="popups__btn"> Save </button>
        </Box>
      </Modal>
    </>
  );
};

export default EditLinks;
