import { BiggestPredictions } from "./BiggestPredictions";
import './index.scss'

export function BiggestPredictionsAll() {

    return (
        <div className="biggest_predictions_container_all">
            <div className="title"> {'Biggest predictions'} </div>
            <BiggestPredictions usingOn={'PREDICATIONS_ALL'} />
        </div>
    )
}