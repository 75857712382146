import { useMemo } from "react";
import { Alram } from "../../assets/images";
import moment from "moment";
import Button from "../button/Button";
import { ReactSVG } from "react-svg";
import { CountDown } from "../countdown";

export function TimelineCounterButton({ onClick, status, endTime, decisionTime, decisionTakenTime }) {

    const targetDate = useMemo(() => {
        if (endTime && decisionTime) {
            return moment().isBefore(endTime)
                ? endTime : moment().isBefore(decisionTime) ? decisionTime : null
        }
        return null;
    }, [endTime, decisionTime]);

    return (<Button onClick={onClick}
        colorVariant={
            ((status === 'COMPLETE') || status === 'REFUND' || moment().isAfter(endTime)) ?
                'outline_cyan' : moment().diff(endTime, 'minute') >= -60 ?
                    'outline_pink' : 'dark_outline_cyan'
        }
        iconSvgVariant="stroke_current_color" >
        <div className=" flex_center">
            {decisionTakenTime ?
                <div className="font_sm">{status === 'COMPLETE' ? 'Event settled'
                    : status === 'REFUND' ? 'Event Canceled'
                        : 'Decision Pending'}</div>
                : targetDate ?
                    <>
                        <ReactSVG src={Alram} className="svg_container" style={{ stroke: "currentcolor", transform: `scale(1.25)`, marginTop: -4 }} />
                        <div className="countdown_lower">
                            <CountDown date={targetDate} />
                        </div>
                    </>
                    : <div className="font_sm">Decision Pending</div>}
        </div>
    </Button>)
}