import React, { useContext, useState } from "react";
import "./moreForThis.scss";
import {
  Case,
  Copy,
  Danger,
  LinkShare,
  QR,
  Send,
} from "../../assets/images";
import { ModalContainer } from "../modalContainer";
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,

  TelegramIcon,
  TelegramShareButton,

  TwitterShareButton,

  WhatsappIcon,
  WhatsappShareButton,

  XIcon,
} from "react-share";
import EventContext from "../../context/eventContext";
import { toast } from "react-toastify";
import { Input, QRCode, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { updateUserBlacklistAction } from "../../store/actions/userAction";
import { fetchEventsAction } from "../../store/actions/eventAction";
import { useWeb3Auth } from "../../context/useWeb3auth";
import { Loader } from "../loader";
import api from "../../util/api";
import { delayForMS, somethingWrong } from "../../util/helper";
import { currentNodeEnv } from "../../context/useWagmiConnector";
import { useNavigate } from "react-router-dom";

const MoreforThisEvent = ({ handleClose, open = true }) => {
  const { loggedIn } = useWeb3Auth()
  // Full path of the current route
  const baseUrl = window.location.origin;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUserId = sessionStorage.getItem('userId')
  const data = useContext(EventContext);
  const { _id, name, description, blockChain, createdBy, fetchingUserId } = data ?? {}

  const [showShare, setShowShare] = useState(false)
  const [showQR, setShowQR] = useState(false)
  const [referralDetails, setReferralDetails] = useState()
  const [loadingReferralLink, setLoadingReferralLink] = useState(false)


  let shareUrl = `${baseUrl + '/event/' + _id}`;
  if (fetchingUserId !== createdBy)
    shareUrl += '?event-referral=' + referralDetails?._id
  const title = name;


  async function handleShareReferralLink(type = 'COPY') {
    try {
      if (!loggedIn)
        return toast.error('Please login to share link!')

      let response
      if (!referralDetails?._id) {
        setLoadingReferralLink(true)
        response = await api.post(`event/${_id}/event-referral`)
        setReferralDetails(response?.data)
        await delayForMS(2000)
      }

      if (type === 'SOCIAL')
        setShowShare(true)
      else if (type === 'QR')
        setShowQR(true)
      else if (type === 'COPY') {
        let copyUrl = `${baseUrl + '/event/' + _id}`;

        if (fetchingUserId !== createdBy) {
          const referralId = response?.data?._id ?? referralDetails?._id
          copyUrl += '?event-referral=' + referralId
        }

        console.log('share ur', copyUrl)
        await navigator.clipboard.writeText(copyUrl);
        toast.success('Link copied successfully')
      }

    } catch (ex) {
      toast?.error(ex?.message ?? somethingWrong)
    } finally {
      setLoadingReferralLink(false)
    }

  }

  async function handleCopyEventInfo() {
    navigate(`/create-event?clone=${_id}`)
  }

  const handleBlacklistOrganiser = () => {
    if (!loggedIn)
      return toast.error('Please login to add this user to your blocklist')

    if (createdBy === currentUserId) {
      toast.info('You cannot blacklist yourself.')
    } else {
      dispatch(updateUserBlacklistAction({ blacklistUserId: createdBy }))
      toast.info("Blacklisted this event's Organiser successfully.")
      dispatch(fetchEventsAction({ page: 1, pageSize: 20, sortAt: 'volume', sortType: 'dsc' }))
    }
    handleClose()
  }

  const handleRedirect = () => {
    if (blockChain?.contractAddress) {
      const chainUrl = currentNodeEnv === 'production' ? 'https://polygonscan.com/' : 'https://sepolia.etherscan.io/'
      const url = chainUrl + `address/${blockChain?.contractAddress}`
      window.open(url, '_blank');
    } else toast.error('This event is not existed on etherscan')
  };

  return (
    <ModalContainer handleClose={handleClose} open={open}>
      <Loader loading={loadingReferralLink} />
      <div className="event__boxes">
        <div onClick={handleCopyEventInfo} className="more__event__box">
          <img src={Copy} alt="" />
          <span> Clone event </span>
        </div>
        <div onClick={() => handleShareReferralLink('SOCIAL')} className="more__event__box">
          <img src={Send} alt="" />
          <span> Share </span>
        </div>
        <div onClick={() => handleShareReferralLink('QR')} className="more__event__box">
          <img src={QR} alt="" />
          <span> QR code </span>
        </div>
        <div onClick={() => handleShareReferralLink('COPY')} className="more__event__box">
          <img src={LinkShare} alt="" />
          <span> Copy link </span>
        </div>
        <div className="more__event__box" onClick={handleBlacklistOrganiser}>
          <img src={Danger} alt="" />
          <span> Blacklist organiser </span>
        </div>
        <div className="more__event__box" onClick={handleRedirect}>
          <img src={Case} alt="" />
          <span> View event on Etherscan </span>
        </div>

      </div>

      <ModalContainer
        heading={'Share this event'}
        handleClose={() => setShowShare(false)}
        open={showShare}>
        <div className="event__boxes">
          {/* <div className="more__event__box justify_center">
            <span> Share this challenge </span>
          </div> */}


          <div className="more__event__box justify_center">
            <div className="Demo__some-network">
              <FacebookMessengerShareButton
                url={shareUrl}
                appId="521270401588372"
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
            </div>

            <div className="Demo__some-network">
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <XIcon size={32} round />
              </TwitterShareButton>
            </div>

            <div className="Demo__some-network">
              <TelegramShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>

            <div className="Demo__some-network">
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=", "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
          </div>
        </div>
      </ModalContainer>

      <ModalContainer
        heading={'Share with QR code'}
        handleClose={() => setShowQR(false)}
        open={showQR}>
        <div className="event__boxes">
          {/* <div className="more__event__box justify_center">
            <span>  Share with QR code </span>
          </div> */}


          <div className="more__event__box justify_center">
            <QRCode value={shareUrl}
              bgColor="white" />
          </div>
        </div>
      </ModalContainer>
    </ModalContainer >

  );
};

export default MoreforThisEvent;
