import React from "react";
import "./claimspopup.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close } from "../../../assets/images";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ClaimsPopup = ({ handleClose, open }) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  return (
    <>
      {/* <Button onClick={handleOpen}> Claim pop-up </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            Claim
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="date__amt">
              <span>Enter your claim </span>
              <span style={{ color: "#BAEAC8" }}>USDT$</span>
            </div>
            <span className="down__text__claim">
              {" "}
              You have 76 USDT$ left to claim{" "}
            </span>
          </Typography>
          <div className="date__amt__head">
            <span>Service fee</span>
            <span>1 %</span>
          </div>
          <button className="popups__btn_st"> Claim </button>
        </Box>
      </Modal>
    </>
  );
};

export default ClaimsPopup;
