import React, { useEffect, useState } from "react";

import "./style.scss";
import { Heart } from "../../assets/images";
import { toast } from "react-toastify";
import { Loader } from "../loader";
import { LOGIN_MODAL_EVENTS } from "@web3auth/ui";
import { useWeb3Auth } from "../../context/useWeb3auth";
import { useLocation, useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { web3Auth, login, isLoading, loggedIn } = useWeb3Auth();

  const [modalVisible, setModalVisible] = useState(false)
  // const [history, setHistory] = useState(() => {
  //   // Retrieve history from localStorage or initialize with an empty array
  //   const savedHistory = localStorage.getItem('history');
  //   return savedHistory ? JSON.parse(savedHistory) : [];
  // });
  // console.log('histroy', history, document.referrer)

  // useEffect(() => {
  //   setHistory((prevHistory) => {
  //     const newHistory = [...prevHistory, location.pathname];
  //     localStorage.setItem('history', JSON.stringify(newHistory)); // Save to localStorage
  //     return newHistory;
  //   });
  // }, [location]);

  useEffect(() => {
    if (loggedIn)
      navigate('/dashboard')
  }, [loggedIn])

  useEffect(() => {
    return () => {
      toast?.dismiss('popupBlocked')
    }
  }, [])

  useEffect(() => {
    if (web3Auth) {
      // emitted when modal visibility changes.
      web3Auth.on(LOGIN_MODAL_EVENTS.MODAL_VISIBILITY, (isVisible) => {
        if (isVisible) {
          setModalVisible(isVisible)
          removeAndUpdateModal()
        }
      });

      web3Auth.on(LOGIN_MODAL_EVENTS.MODAL_VISIBILITY, (isVisible) => {
        setModalVisible(true)
      });
    }
  }, [web3Auth, web3Auth?.status])

  useEffect(() => {
    if (web3Auth?.status) {
      setTimeout(() => {
        handleShowModal()
      }, 500);
    }
  }, [web3Auth?.status])

  async function handleShowModal() {
    await login()
  }

  function removeAndUpdateModal() {
    try {
      // Assuming you have a reference to the element you want to move
      const elementToMove = document.getElementById('w3a-parent-container');

      // Assuming you have a reference to the new parent element
      const newParentElement = document.getElementById('welcome__screen__right__w3a__container');

      if (newParentElement && elementToMove) {
        // Move the element to the new parent
        newParentElement.appendChild(elementToMove);
      }
    } catch (ex) {
      console.log('ex in updating element', ex?.message)
    }
  }

  return (
    <div className="welcome__screen">
      <Loader loading={isLoading || modalVisible === false} />
      <div className={`welcome__screen__left`}>
        <div>
          <img src={Heart} alt="img" />
          <span> Welcome to </span>
          <h1>MAKAO</h1>
        </div>
        <p className="pointer"> Tap to get stated </p>
      </div>
      <hr className="seprate__line" />

      <div id="welcome__screen__right" className={`welcome__screen__right`}>
        <div className="welcome__screen__right__content">
          {
            modalVisible === false ?
              <div className="loading"> Loading... </div>
              : <></>
          }
          <h3> Get Started </h3>
          <div id="welcome__screen__right__w3a__container" className="">

          </div>
          <div className=" footer__container">
            <h4 className=" warring">WARNING</h4>
            <p className=" disclaimer">Makao Protocol is a 100% autonomous protocol running on publicly accessible blockchains. This website is a graphical user interface for visualizing data from, and interacting with, the Makao Protocol Smart Contracts on EVMS or side chains, via your Web 3 injected wallet. Makao does not host any markets, nor takes custody of funds.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
