import React from "react";
import "./style.scss";

const Button = ({ children, onClick, className, colorVariant = '', sizeVariant = '', iconSvgVariant = 'fill_current_color', ...restProps }) => {

  return (
    <button
      className={`custom_button ${colorVariant} ${sizeVariant} ${className} ${iconSvgVariant}`}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
