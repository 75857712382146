import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchFriendsLeaderboardAction } from "../../../store/actions/eventAction";
import { noImage, noImageProfile } from "../../../assets/images";
import { formatNumber } from "../../../util/helper";
import { HeadingAndViewAll } from "./HeadingAndViewAll";

export function FriendsLeaderboard() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { friends, status } = useSelector((state) => state.event.friendsLeaderboard);


    useEffect(() => {
        // if (Array.isArray(friends) && friends.length === 0)
        dispatch(fetchFriendsLeaderboardAction({ page: 1, pageSize: 20 }))
    }, [])

    return <div className="leaderboards" style={{ paddingBottom: "0" }}>
        {Array.isArray(friends) && friends.length ?
            <>  
                <HeadingAndViewAll onClick={() => navigate("/leaderboards")} title={'Friends Leaderboard'}/>
                <div className="leaderboards__content">
                    <div className="main__grid">

                        {friends.map((val, ind) => (
                            <div onClick={() => navigate(`/profile/${val?._id}`)} className="box__down cursor__pointer" key={ind}>
                                <div className="left">
                                    <span className="leader">{ind + 1}<span className="leader_place">{`${ind === 0 ? 'st' : ind === 1 ? 'nd' : ind === 2 ? 'rd' : 'th'}`}</span></span>
                                    {/* <img src={val.imgPosition} alt="img" /> */}
                                    <span>
                                        <img src={val?.img ?? noImageProfile} alt="img"
                                            onError={(event) => {
                                                event.target.src = noImageProfile;
                                            }} />
                                        <p> {val.username} </p>
                                    </span>
                                </div>
                                <div className="right" >
                                    {val?.performance ? `${formatNumber(val?.performance * 100)}%` : ''}
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </> :
            <></>}

    </div>
}