import { toast } from "react-toastify"
import api from "../../util/api"
import { somethingWrong } from "../../util/helper"
import { updateEventAction, updateEventWithObjAction } from "../../store/slices/eventSlice"
import { useDispatch } from "react-redux";
import { findIndex, set } from "lodash";
import CancelAndRefundEvent from "../Popups/cancel&refund/CancelAndRefundEvent";
import { useEffect, useState } from "react";
import { makaoFixtureAbi } from "../../abi/makaoFixtureAbi";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { Loader } from "../loader";
import Button from "../button/Button";

export function RefundAndCancelEventActions({ showDistribute, data }) {

    const dispatch = useDispatch();
    const { challenges, _id, selectedChallengeIds, status, blockChain } = data ?? {}
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
    const [loading, setLoading] = useState(false)

    const { data: distributeHash, isPending: isDistributePending, error: distributeError, isError: isDistributeError, writeContract: distributeWriteContract } = useWriteContract();
    const { isLoading: isDistributeConfirming, isSuccess: isDistributeConfirmed, error: distributeReceiptError, isError: isDistributeReceiptError } = useWaitForTransactionReceipt({ hash: distributeHash })

    const { data: cancelAndRefundHash, isPending: isCancelAndRefundPending, error: cancelAndRefundError, isError: isCancelAndRefundError, writeContract: cancelAndRefundWriteContract } = useWriteContract();
    const { isLoading: isCancelAndRefundConfirming, isSuccess: isCancelAndRefundConfirmed, error: cancelAndRefundReceiptError, isError: isCancelAndRefundReceiptError } = useWaitForTransactionReceipt({ hash: cancelAndRefundHash })


    useEffect(() => {
        if (isDistributeError) {
            if (typeof distributeError?.name === 'string' && distributeError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(distributeError?.message)
        }
        else if (isDistributeReceiptError) {
            if (typeof distributeReceiptError?.name === 'string' && distributeReceiptError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(distributeReceiptError?.message)
        }

    }, [isDistributeError, isDistributeReceiptError])

    useEffect(() => {
        if (isCancelAndRefundError) {
            if (typeof cancelAndRefundError?.name === 'string' && cancelAndRefundError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(cancelAndRefundError?.message)
        }
        else if (isCancelAndRefundReceiptError) {
            if (typeof cancelAndRefundReceiptError?.name === 'string' && cancelAndRefundReceiptError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(cancelAndRefundReceiptError?.message)
        }

    }, [isCancelAndRefundError, isCancelAndRefundReceiptError])

    //After all blockchain event confirmed
    useEffect(() => {
        if (isDistributeConfirmed)
            handleDistributeWin()
    }, [isDistributeConfirmed])

    useEffect(() => {
        if (isCancelAndRefundConfirmed)
            handleCancelAndRefund()
    }, [isCancelAndRefundConfirmed])

    async function handleBlockChainDistributeWin() {
        distributeWriteContract({
            address: blockChain.contractAddress,
            abi: makaoFixtureAbi,
            functionName: 'resolve',
            args: [challenges?.filter(val => selectedChallengeIds.includes(val?._id))?.map((val => val?.digest))]
        });
    }

    async function handleBlockChainCancelAndRefund() {
        cancelAndRefundWriteContract({
            address: blockChain.contractAddress,
            abi: makaoFixtureAbi,
            functionName: 'cancel',
        });
    }

    async function handleDistributeWinClick() {
        if (Array.isArray(selectedChallengeIds) && selectedChallengeIds.length === 0)
            return toast.info('Please select at least a prediction before making decision!')
        else handleBlockChainDistributeWin()
    }

    async function handleDistributeWin() {
        try {
            setLoading(true)
            if (Array.isArray(selectedChallengeIds) && selectedChallengeIds.length === 0)
                return toast.info('Please select at least a prediction before making decision!')

            const decisionRes = await api.post(`event/${_id}/decision/win`, { winnerChallenges: selectedChallengeIds })

            const { event } = decisionRes?.data ?? {}
            const rawChallenges = challenges.map((val) => { return selectedChallengeIds.includes(val?._id) ? { ...val, playStatus: 'WIN' } : val })
            dispatch(updateEventWithObjAction({ decisionTakenTime: event?.decisionTakenTime, challenges: rawChallenges, status: 'COMPLETE' }))
            toast.success('Terminate and distribute winnings process successful')

        } catch (ex) {
            toast.error(somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    async function handleCancelAndRefund() {
        try {
            setLoading(true)
            const decisionRes = await api.post(`event/${_id}/decision/refund`)
            const { event } = decisionRes?.data ?? {}
            const rawChallenges = challenges.map((val) => { return { ...val, playStatus: 'REFUND' } })
            dispatch(updateEventWithObjAction({ decisionTakenTime: event?.decisionTakenTime, challenges: rawChallenges, status: 'REFUND' }))
            toast.success('Cancel and refund process successful')
            setShowCancelConfirmation(false)
        } catch (ex) {
            toast.error(somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    return <>
        <Loader loading={isDistributePending || isDistributeConfirming} />

        {showDistribute && (
            <Button
                disabled={loading || status === 'COMPLETE' || status === 'REFUND'}
                onClick={handleDistributeWinClick}
                colorVariant={'green'}
                className="">
                <p> {status === 'COMPLETE' ? 'Event winnings already distributed' : 'Terminate and distribute winnings'} </p>
            </Button>)
        }

        <Button
            onClick={() => setShowCancelConfirmation(true)}
            disabled={loading || status === 'COMPLETE' || status === 'REFUND'}
            colorVariant={'pink'}
            sizeVariant="small"
            className={'mt_16'}>
            <p> {status === 'REFUND' ? 'Event already canceled' : 'Cancel the event and refund all participants'} </p>
        </Button>

        <CancelAndRefundEvent
            data={data}
            open={showCancelConfirmation}
            handleClose={() => setShowCancelConfirmation(false)}
            handleConfirm={handleBlockChainCancelAndRefund}
            loading={isCancelAndRefundConfirming || isCancelAndRefundPending} />
    </>
}