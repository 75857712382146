import React, { useEffect, useState } from "react";
import "./allLeaderBoards.scss";
import { BackIcon, Chart_analysis, Dollar, noImageProfile } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import api from "../../util/api";
import { toast } from "react-toastify";
import { formatNumber, somethingWrong } from "../../util/helper";
import { Loader } from "../loader";
import { TabsComponent } from "../TabsComponent";
import Button from "../button/Button";
import { ReactSVG } from "react-svg";

const AllLeaderBoards = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("players");
  const [time, setTime] = useState('WEEK')


  const [loading, setLoading] = useState(true);
  const [friendsLeaderboard, setFriendsLeaderboard] = useState([])
  const [playersLeaderboard, setPlayersLeaderboard] = useState([])
  const [organiserLeaderboard, setOrganiserLeaderboard] = useState([])


  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  useEffect(() => {
    if (tab === 'players')
      fetchPlayersLeaderboard()
    else if (tab === 'friends')
      fetchFriendsLeaderboard()
    else if (tab === 'organsier')
      fetchOrganiserLeaderboard()
  }, [tab, time])


  const handleClick = (type) => {
    setTab(type);
  };

  async function fetchFriendsLeaderboard() {
    try {
      setLoading(true)
      const res = await api.get(`user/leaderboard?leaderBoardType=FRIEND&type=${time}&page=1&pageSize=20`)
      setFriendsLeaderboard(res?.data)

    } catch (_ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  async function fetchOrganiserLeaderboard() {
    try {
      setLoading(true)
      const res = await api.get(`user/leaderboard?leaderBoardType=ORGANIZER&type=${time}&page=1&pageSize=20`)
      setOrganiserLeaderboard(res?.data)

    } catch (_ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  async function fetchPlayersLeaderboard() {
    try {
      setLoading(true)
      const res = await api.get(`user/leaderboard?leaderBoardType=PLAYER&type=${time}&page=1&pageSize=20`)
      setPlayersLeaderboard(res?.data)

    } catch (_ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Loader loading={loading} />
      <div className="leaderBoards">
        <Link to="/dashboard">
          <img src={BackIcon} alt="" className="backIcon_leaderboards" />
        </Link>
        {/* <div className="">
          <Link to="/profile-stats">
            <Button colorVariant="outline_cyan status">
              <ReactSVG src={Chart_analysis} className="svg_container" />
              <span> My stats </span>
            </Button>
          </Link>
        </div> */}
        <div className="leaderboards__tabs">
          <TabsComponent
            setTab={(value) => handleClick(value)}
            currentTab={tab}
            tabsList={[
              { name: 'Participants', value: 'players' },
              { name: 'Friends', value: 'friends' },
              { name: 'Organisers', value: 'organsier' }]} />
        </div>
        <div className="button__week">
          <select value={time} onChange={(e) => setTime(e.target.value)}>
            <option value="WEEK"> Week </option>
            <option value="MONTH"> Month </option>
            <option value="3MONTH"> 3 Month </option>
            <option value="YEAR"> Year </option>

          </select>
        </div>

        <div className="leaderBoards__content">
          {
            tab === 'friends' && friendsLeaderboard.map((val, ind) => (<div key={ind} className="all__boxes">
              <div key={ind} onClick={() => navigate(`/profile/${val?._id}`)} className="box__down cursor__pointer" >
                <div className="left">
                  <span className="leader">{ind + 1}<span className="leader_place">{`${ind === 0 ? 'st' : ind === 1 ? 'nd' : ind === 2 ? 'rd' : 'th'}`}</span></span>
                  {/* <img src={val.imgPosition} alt="img" /> */}
                  <span>
                    <img src={val?.img ?? noImageProfile} alt="img"
                      onError={(event) => {
                        event.target.src = noImageProfile;
                      }} />
                    <p> {val.username} </p>
                  </span>
                </div>
                <div className="right" style={{
                  color: "#BAEAC8",
                  // #FFCACA
                }}>
                  {val?.performance ? `${formatNumber(val?.performance * 100)}%` : ''}
                </div>
              </div>
            </div>))
          }
          {
            tab === 'players' && playersLeaderboard.map((val, ind) => (<div key={ind} className="all__boxes">
              <div key={ind} onClick={() => navigate(`/profile/${val?._id}`)} className="box__down cursor__pointer" >
                <div className="left">
                  <span className="leader">{ind + 1}<span className="leader_place">{`${ind === 0 ? 'st' : ind === 1 ? 'nd' : ind === 2 ? 'rd' : 'th'}`}</span></span>
                  {/* <img src={val.imgPosition} alt="img" /> */}
                  <span>
                    <img src={val?.img ?? noImageProfile} alt="img"
                      onError={(event) => {
                        event.target.src = noImageProfile;
                      }} />
                    <p> {val.username} </p>
                  </span>
                </div>
                <div className="right" style={{
                  color: "#BAEAC8",
                  // #FFCACA
                }}>
                  {val?.performance ? `${formatNumber(val?.performance * 100)}%` : ''}
                </div>
              </div>
            </div>))
          }
          {
            tab === 'organsier' && organiserLeaderboard.map((val, ind) => (<div key={ind} className="all__boxes">
              <div key={ind} onClick={() => navigate(`/profile/${val?._id}`)} className="box__down cursor__pointer" >
                <div className="left">
                  <span className="leader">{ind + 1}<span className="leader_place">{`${ind === 0 ? 'st' : ind === 1 ? 'nd' : ind === 2 ? 'rd' : 'th'}`}</span></span>

                  {/* <img src={val.imgPosition} alt="img" /> */}
                  <span>
                    <img src={val?.img ?? noImageProfile} alt="img"
                      onError={(event) => {
                        event.target.src = noImageProfile;
                      }} />
                    <p> {val.username} </p>
                  </span>
                </div>
                <div className="right" style={{
                  color: "#BAEAC8",
                  display: 'flex',
                  gap: 4
                  // #FFCACA
                }}>
                  <img src={Dollar} alt="" />
                  <div>{formatNumber(val?.totalVolume)}</div>
                </div>
              </div>
            </div>))
          }
        </div>
      </div>
    </>
  );
};

export default AllLeaderBoards;
