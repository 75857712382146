import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Approval = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}> Info waiting prediction approval</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <Typography
            id="modal-modal-title"
            className="infoPopup_heading"
            variant="h6"
            component="h2"
            style={{ fontSize: "24px" }}
          >
            Waiting prediction approval
          </Typography>
          <Typography
            id="modal-modal-title-desc"
            variant="h6"
            component="h2"
            style={{ fontSize: "16px", fontWeight: "400" }}
          >
            When participants makes an prediction proposal he needs to wait for the
            organiser approval before it can be participated.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Approval;
