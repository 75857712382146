// EventCardInfo.jsx
import React from "react";
import { ReactSVG } from "react-svg";
import { DollarDark, I } from "../../assets/images";

const BottomContentInfo = ({
  handleOpen,
  playPrice,
  potentialPrice,
  playOnContent = "#1 Steve",
  Profit_Loss = "Potential return",
  roundedBottom,
  variant = 'green'
}) => {
  return (
    <div className={'side_way_cards'}>
      <span
        className={`left_side ${variant} ${roundedBottom ? 'rounded' : ''}`}
      >
        <p> You participate on #{playOnContent} </p>
        <div className="price__dollar__img">
          <p> {playPrice} </p>
          <ReactSVG src={DollarDark} className="svg_container" />
        </div>
      </span>
      <span
        className={`right_side ${variant} ${roundedBottom ? 'rounded' : ''}`}
      >
        <p>{Profit_Loss}</p>
        <div className="price__dollar__img">
          <p> {potentialPrice} </p>
          <ReactSVG src={DollarDark} className="svg_container" />
        </div>
        <img
          src={I}
          alt=""
          style={{
            position: "absolute",
            top: "2px",
            right: "3px",
          }}
          onClick={handleOpen}
        />
      </span>
    </div>
  );
};

export default BottomContentInfo;
