import React, { useEffect, useState } from "react";
import "./notification.scss";
import { BackIcon, EditIcon, noImage } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { Loader } from "../loader";
import api from "../../util/api";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import moment from "moment";
import { get } from "lodash";
import { ReactSVG } from "react-svg";

function HandleDifferentNotifications({ data }) {
  const navigate = useNavigate();

  const { type, metaData } = data ?? {}

  switch (type) {
    case 'INVITATION':
      return <div onClick={() => navigate(`/event/${metaData?.eventId}`)} className="box">
        <img src={get(data, 'event[0].img', noImage)} alt=""
          onError={(event) => {
            // Replace the failed image source with the default image source
            event.target.src = noImage;
          }} />
        <span> @{get(data, 'user[0].username')} invited you to an event {get(data, 'event[0].name')} </span>
      </div>
    case 'ORGANISED_EVENT':
      return <div onClick={() => navigate(`/event/${metaData?.eventId}`)} className="box">
        <img src={get(data, 'event[0].img', noImage)} alt=""
          onError={(event) => {
            // Replace the failed image source with the default image source
            event.target.src = noImage;
          }} />
        <span> @{get(data, 'user[0].username')} participated on your event {get(data, 'event[0].name')} </span>
      </div>
    case 'FRIEND_EVENT':
      return <div onClick={() => navigate(`/event/${metaData?.eventId}`)} className="box">
        <img src={get(data, 'event[0].img', noImage)} alt=""
          onError={(event) => {
            // Replace the failed image source with the default image source
            event.target.src = noImage;
          }} />
        <span> @{get(data, 'user[0].username')} from your friend list created an event {get(data, 'event[0].name')} </span>
      </div>
    case 'PROPOSAL_STATUS':
      return <div onClick={() => navigate(`/event/${metaData?.eventId}`)} className="box">
        <img src={get(data, 'event[0].img', noImage)} alt=""
          onError={(event) => {
            // Replace the failed image source with the default image source
            event.target.src = noImage;
          }} />
        <span> @{get(data, 'user[0].username')} has accepted your proposal for event {get(data, 'event[0].name')} </span>
      </div>
    case 'PLAY_STATUS':
      return <div onClick={() => navigate(`/event/${metaData?.eventId}`)} className="box">
        <img src={get(data, 'event[0].img', noImage)} alt=""
          onError={(event) => {
            // Replace the failed image source with the default image source
            event.target.src = noImage;
          }} />
        <span>You {metaData?.status === 'WIN' ? 'win' : metaData?.status === 'LOSS' ? 'loss' : metaData?.status === 'REFUND' ? 'got refund' : metaData?.status?.toLowerCase()} for the event {get(data, 'event[0].name')} </span>
      </div>

    default:
      <div></div>
  }
}

const Notification = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    handleGetNotifications()
  }, []);

  async function handleGetNotifications() {
    try {
      setLoading(true)
      const notificationsRes = await api.get(`notification?page=1&pageSize=20`)
      setNotifications(notificationsRes?.data)
    } catch (ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }


  return (
    <>
      <Loader loading={loading} />
      <div className="notification">
        <div className="notifications__content">
          <div className="noti__head">
            <div className="noti__head__left">
              <ReactSVG onClick={() => navigate(-1)}
                src={BackIcon} className="svg_container pointer" />
            </div>
            {/* <div className="noti__head__center">Notification</div> */}
            <div className="noti__head__right">
              <ReactSVG onClick={() => navigate("/notification-settings")}
                src={EditIcon} className="svg_container pointer" />
            </div>
          </div>

          {
            notifications.map((val, ind) => (
              <div key={ind} className="noti__divs">
                <div className="noti__div_container">
                  <p
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "14px",
                      paddingBottom: "3px",
                      color: "#FFFFFF",
                    }}
                  >
                    {moment(val?._id).fromNow()}
                  </p>
                  {
                    Array.isArray(val?.notifications) &&
                    val.notifications.map((res, ind) => (
                      <div key={ind} style={{ marginTop: 5 }}>
                        <HandleDifferentNotifications data={res} />
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>

    </>
  );
};

export default Notification;
