import { Typography } from "@mui/material";
import { get } from "lodash";
import React, { useState } from "react";
import { ButtonFalseSvg, ButtonTrueSvg, DangerPopup, Gallery, LinkShare } from "../../../assets/images";
import { Challenge } from "./challenge";
import Button from "../../button/Button";
import { ReactSVG } from "react-svg";

function ReviewModel({
  currentReviewData,
  currentPage,
  feedback,
  showLink,
  link,
  onSubmitReview,
  setFeedback,
  setShowLink,
  setLink,
  handleFileChange,
  onNext,
}) {
  const [wronglyFeed, setWronglyFeed] = useState(false);

  const eventTitle = currentReviewData?.eventName ?? "NO CHALLENGE NAME";
  const totalEventPotentialWin =
    currentReviewData?.totalEventPotentialWin ?? 0;
  const challengeId =
    currentPage === "WALLET"
      ? get(currentReviewData, "playData[0].challenge", undefined)
      : currentReviewData.challenge;
  const event_id =
    currentPage === "WALLET"
      ? get(currentReviewData, "playData[0].event", undefined)
      : currentReviewData.event;
  const title = currentReviewData.title;
  const claimableAmount = get(currentReviewData, 'claimableAmount', 0);
  const playStatus = claimableAmount > 0 ? 'WIN' : 'LOSS';
  const eventPlayStatus = totalEventPotentialWin > 0 ? 'WIN' : 'LOSS';
  const challengeVolume = currentReviewData?.challengeVolume ?? 0

  return (
    <div>
      <Typography
        id="modal-modal-title"
        className="popup_heading"
        variant="h6"
        component="h2"
        style={{ marginTop: "1rem" }}
      >
        Review decision
      </Typography>
      <div>
        {!wronglyFeed ? (
          <>
            <div className="arrow-up">
              <img src={DangerPopup} alt="" style={{ width: "25px" }} />
            </div>
            <p className="review_info review_info_with_icon">
              Important : Wrong reviews will affect your trust note.
            </p>
          </>
        ) : (
          <p className="review_info review_info_with_icon">
            Describe: why it was a wrong decision with proof.
          </p>
        )}

        {!wronglyFeed && (
          <>
            {currentPage === "WALLET" && (
              <div className="">
                <Challenge
                  asEvent={true}
                  amount={totalEventPotentialWin}
                  title={eventTitle}
                  playStatus={eventPlayStatus}
                  img={get(currentReviewData, "eventImg")}
                  badgeType={eventPlayStatus === 'WIN' ? 'PLAY-WIN' : 'PLAY-LOSS'}
                />
              </div>
            )}

            <Challenge
              amount={playStatus === "LOSS" ? 0 : claimableAmount}
              title={title}
              badgeType={playStatus === 'WIN' ? 'PLAY-WIN' : 'PLAY-LOSS'}
              challengeVolume={challengeVolume}
            />
          </>
        )}

        {wronglyFeed && (
          <div className="feedback_container">
            <div className="label">
              Feedback <div className=" optional_text">Optional</div>
            </div>
            <div className=" text_area_container">
              <div className=" right_absolute">
                <div onClick={() => setShowLink((prev) => !prev)}>
                  <img src={LinkShare} alt="" />
                </div>
                <div className=" file_input">
                  <img src={Gallery} alt="" className="" />
                  <input
                    type="file"
                    id="fileInput"
                    placeholder="Image"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
              </div>
              <textarea
                onChange={(e) => setFeedback(e.target.value)}
                value={feedback}
                className="feedback_textarea"
                placeholder="Type here"
                rows={3}
                minLength={3}
              ></textarea>
            </div>

            {showLink && (
              <div className="link_input_container">
                <div className="label">Link</div>
                <div>
                  <input
                    className="link_input"
                    type="text"
                    placeholder="Type link here"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {!wronglyFeed && (
          <div className="icons">
            <ReactSVG
              onClick={() => {
                currentReviewData.reviewedChallenge = true;
                onSubmitReview({ userReview: true, challengeId, event_id });
                onNext();
              }} src={ButtonTrueSvg} className="svg_container pointer" />
            <ReactSVG
              onClick={() => {
                setWronglyFeed(true);
              }} src={ButtonFalseSvg} className="svg_container pointer" />
          </div>
        )}

        {wronglyFeed && (
          <Button
            className="submit-btn"
            onClick={() => {
              setWronglyFeed(false);
              currentReviewData.reviewedChallenge = false;
              onSubmitReview({ userReview: false, challengeId, event_id });
              onNext();
            }}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
}

export default ReviewModel;
