import React, { useEffect, useState } from "react";
import "../trendingNowViewAll/trending.scss";
import PlayersPopup from "../Popups/players/PlayersPopup";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimeUpEventsActionAll } from "../../store/actions/eventAction";
import EventCard from "../eventCard";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import { Loader } from "../loader";
import { useWeb3Auth } from "../../context/useWeb3auth";

const TimeAlmostUpAll = () => {
  const { loggedIn } = useWeb3Auth()
  const timeUpEventsAll = useSelector((state) => state.event?.timeUpEventsAll);
  const { events, status, page, pageSize, disableLoadMore } = timeUpEventsAll ?? {}
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (Array.isArray(events) && events.length === 0)
      handleFetchEvents({ page, pageSize })
  }, [loggedIn])

  async function handleFetchEvents() {
    try {
      dispatch(fetchTimeUpEventsActionAll({ page, pageSize, sortAt: 'endTime', sortType: 'asc' }))

    } catch (ex) {
      toast.error(somethingWrong)
    }
  }


  return (
    <>
      <Loader loading={status === 'loading'} />
      <PlayersPopup handleClose={handleClose} open={open} />
      <h1 >
        {" "}
        Almost Out of Time{" "}
      </h1>
      <div className="trending_images">
        {events?.map((val, ind) => (
          <div key={ind} style={{ width: '100%', minWidth: "100%" }}>
            <EventCard data={val} />
          </div>
        ))}
      </div>
      <div className=" loadmore__container">
        <button disabled={disableLoadMore} onClick={handleFetchEvents} className="btn">Load More</button>
      </div>
    </>
  );
};

export default TimeAlmostUpAll;
