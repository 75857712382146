import React, { useState } from "react";
import "./profileStats.scss";
import {
  BackIcon,
  Badge,
  Chart_analysis,
  EyeButton,
  I,
  KYCButton,
  ShieldBlack,
  ShieldClose,
  ShieldTick,
  Verified,
  noImageProfile,
} from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import Button_Com from "../button_com/Button_Com";
import InfoKYCverified from "../infoPopups/infoKYCverified/InfoKYCverified";
import CurrentTop from "../infoPopups/currentTop/CurrentTop";
import Celebrity from "../infoPopups/celebrity/Celebrity";
import Current1000 from "../infoPopups/currentTop1000/Current1000";
import Trusted from "../infoPopups/trusted/Trusted";
import Unknown from "../infoPopups/unknown/Unknown";
import NotTrusted from "../infoPopups/not_trusted/NotTrusted";
// import Chart from "../profile/Chart";
import SingleChart from "../profile/Chart";

const ProfileStats = () => {
  const [tabSat, setTabSat] = useState("4.9/5");
  const handleClickSat = (type) => {
    setTabSat(type);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openOGs, setOpenOGs] = React.useState(false);
  const handleOpenOGs = () => setOpenOGs(true);
  const handleCloseOGs = () => setOpenOGs(false);

  const [openCelebrity, setOpenCelebrity] = React.useState(false);
  const handleOpenCelebrity = () => setOpenCelebrity(true);
  const handleCloseCelebrity = () => setOpenCelebrity(false);

  const [openTop1000, setOpenTop1000] = React.useState(false);
  const handleOpenTop1000 = () => setOpenTop1000(true);
  const handleCloseTop1000 = () => setOpenTop1000(false);

  const [openTrusted, setOpenTrusted] = React.useState(false);
  const handleOpenTrusted = () => setOpenTrusted(true);
  const handleCloseTrusted = () => setOpenTrusted(false);

  const [openUnknown, setOpenUnknown] = React.useState(false);
  const handleOpenUnknown = () => setOpenUnknown(true);
  const handleCloseUnknown = () => setOpenUnknown(false);

  const [openNotTrusted, setOpenNotTrusted] = React.useState(false);
  const handleOpenNotTrusted = () => setOpenNotTrusted(true);
  const handleCloseNotTrusted = () => setOpenNotTrusted(false);

  const navigate = useNavigate();

  return (
    <>
      <InfoKYCverified handleClose={handleClose} open={open} />
      <CurrentTop handleClose={handleCloseOGs} open={openOGs} />
      <Celebrity handleClose={handleCloseCelebrity} open={openCelebrity} />
      <Current1000 handleClose={handleCloseTop1000} open={openTop1000} />
      <Trusted handleClose={handleCloseTrusted} open={openTrusted} />
      <Unknown handleClose={handleCloseUnknown} open={openUnknown} />
      <NotTrusted handleClose={handleCloseNotTrusted} open={openNotTrusted} />

      <div className="profile__stats">
        <div className="profile__left__stats">
          <div className="profile__left__content">
            <img
              src={BackIcon}
              alt=""
              className="backIcon"
              onClick={() => navigate(-1)}
            />
            <div className="profile__img">
              <img src={noImageProfile} alt="" />
              <img src={Verified} alt="" className="verified_tag" />
            </div>
            <div className="leaderboards__tabs_stats">
              <div className="box__tabs">
                <Link
                  to=""
                  // onClick={() => handleClickSat("@Satoshi")}
                  // className={tabSat === "@Satoshi" ? "active" : ""}
                >
                  @Satoshi
                </Link>
                <Link
                  to=""
                  onClick={() => handleClickSat("4.9/5")}
                  className={tabSat === "4.9/5" ? "active" : ""}
                  style={{ display: "flex", alignItems: "center", gap: "7px" }}
                >
                  <img src={ShieldBlack} alt="" />
                  4.9/5
                </Link>
                <Link
                  to=""
                  // onClick={() => handleClickSat("Rank : 2873")}
                  // className={tabSat === "Rank : 2873" ? "active" : ""}
                >
                  Rank : 2873
                </Link>
              </div>
            </div>
            <div className="buttons__two">
              <Button_Com className="add_friend">
                <img src={EyeButton} alt="" /> My friends{" "}
              </Button_Com>
              <Button_Com className="links">
                <img src={Chart_analysis} alt="" /> Stats{" "}
              </Button_Com>
            </div>
            <div className="chart_img">
              {/* <img src={Chart} alt="" /> */}
              <SingleChart />
            </div>
          </div>
        </div>
        <div className="profile__right__stats">
          <h2> Certifications </h2>
          <div className="four__badges">
            <div className="part1">
              <div className="badge__one" style={{ position: "relative" }}>
                <span>
                  <img src={Badge} alt="" className="badges_img" />
                </span>
                <span style={{ color: "#BAEAC8" }}> KYC verified </span>
                <img
                  src={I}
                  alt=""
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  onClick={handleOpen}
                />
              </div>
              <div
                className="badge__one"
                style={{ position: "relative", background: "transparent" }}
              >
                <span>
                  <img src={Badge} alt="" className="badges_img" />
                </span>
                <span style={{ color: "#BAEAC8" }}> Celebrity status</span>
                <img
                  src={I}
                  alt=""
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenCelebrity}
                />
              </div>
            </div>
            <div className="part1">
              <div
                className="badge__one"
                style={{ position: "relative", background: "transparent" }}
              >
                <span>
                  <img src={Badge} alt="" className="badges_img" />
                </span>
                <span> Current Top 1000 All time </span>
                <img
                  src={I}
                  alt=""
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenTop1000}
                />
              </div>
              <div
                className="badge__one"
                style={{ position: "relative", background: "transparent" }}
              >
                <span>
                  <img src={Badge} alt="" className="badges_img" />
                </span>
                <span> Top 100 & OGs</span>
                <img
                  src={I}
                  alt=""
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenOGs}
                />
              </div>
            </div>
          </div>

          <div className="kyc_btn">
            <img src={KYCButton} alt="" className="kyc__btn__img" />
          </div>

          <h2 className="trust__lvls__h2"> Trust levels (4,7/5) </h2>
          <div className="trust__lvl">
            <div className="trust__lvl__one" style={{ position: "relative" }}>
              <span>
                <img
                  src={ShieldTick}
                  alt=""
                  className="trust__lvl_img"
                  style={{ width: "30px" }}
                />
              </span>
              <span> Trusted</span>
              <img
                src={I}
                alt=""
                style={{
                  position: "absolute",
                  top: "45px",
                  right: "12px",
                  cursor: "pointer",
                }}
                onClick={handleOpenTrusted}
              />
            </div>
            <div
              className="trust__lvl__two"
              style={{ position: "relative", background: "transparent" }}
            >
              <span>
                <img src={ShieldClose} alt="" className="trust__lvl_img_two" />
              </span>
              <span> Unknown </span>
              <img
                src={I}
                alt=""
                style={{
                  position: "absolute",
                  top: "45px",
                  right: "12px",
                  cursor: "pointer",
                }}
                onClick={handleOpenUnknown}
              />
            </div>
            <div
              className="trust__lvl__three"
              style={{ position: "relative", background: "transparent" }}
            >
              <span>
                <img src={ShieldClose} alt="" className="trust__lvl_img_two" />
              </span>
              <span> Not Trusted </span>
              <img
                src={I}
                alt=""
                style={{
                  position: "absolute",
                  top: "45px",
                  right: "12px",
                  cursor: "pointer",
                }}
                onClick={handleOpenNotTrusted}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileStats;
