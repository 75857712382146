import React from "react";
import { Typography } from "@mui/material";
import { formatNumber } from "../../../util/helper";
import { get } from "lodash";
import { ModalContainer } from "../../modalContainer";
import Button from "../../button/Button";
import { Challenge } from "../reviewDecision/challenge";

const RefundChallengeOrPlayer = ({ data, onCancel, onConfirm, open, loading }) => {
  const { logic, title, challengeVolume, odd, changePercentage } = data ?? {}

  return (
    <ModalContainer
      open={open}
      handleClose={onCancel}
      heading="Cancel & Refund">
      <Typography
        id="modal-modal-description"
        sx={{ mt: 2, color: "#FFFFFF" }}
      >
        Are you sure you want to terminate and refund the following prediction :
      </Typography>

      <div className="refund_challenge_confirmation">
        <div className="refund_challenge_container">
          <Challenge
            title={title ?? logic}
            challengeVolume={formatNumber(get(challengeVolume, '[0].volume', 0) ?? 0)}
            changePercentage={changePercentage}
            odd={odd}
            badgeType="ODD"
            positionNumber={'1'}
          />
        </div>
      </div>

      <div className="cancel_refund_btns">
        <Button disabled={loading} onClick={onCancel} colorVariant="pink" >
          <span>Cancel</span>
        </Button>
        <Button disabled={loading} onClick={onConfirm} colorVariant="green" >
          <span>Confirm</span>
        </Button>
      </div>
    </ModalContainer>
  );
};

export default RefundChallengeOrPlayer;
