import React from "react";
import { Link } from "react-router-dom";
import "./historyReferalls.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close, Up } from "../../../assets/images";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HistoryReferrals = ({ handleClose, open }) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <>
      {/* <Button onClick={handleOpen}>Transaction history Referrals pop up</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <div className="arrow-up">
            <img src={Up} alt="Image" style={{ width: "25px" }} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            App Referral
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "#FFFFFF" }}
          >
            <Link style={{ color: "white" }}>Info</Link> <br />
            <span className="popup_text"> @nico </span> created an account using
            your username as referral.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="date__amt__head">
              <span>Date</span>
              <span>Amount</span>
            </div>
            <div className="date__amt">
              <span>15/09/1999 09:32 </span>
              <span style={{ color: "#BAEAC8" }}>5,875k USDT</span>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default HistoryReferrals;
