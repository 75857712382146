
import { Select } from "antd";

import { useMemo, useState, useRef } from "react";
import { debounce } from 'lodash'
import { toast } from "react-toastify";

import {
    noImage,
} from "../../../assets/images";
import api from "../../../util/api";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

export function HeaderSearchBar() {
    const navigate = useNavigate();
    const fetchRef = useRef(0);

    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [visible, setVisible] = useState(false)

    function handleDropDownClick(data) {
        const { type, value, label } = data ?? {}
        console.log('data', data)

        switch (type) {
            case 'FRIEND':
            case 'USER':
                navigate(`/profile/${value}`)
                break;
            case 'FRIEND_EVENT':
            case 'PRIVATE_SECRET_EVENT':
            case 'EVENT':
                navigate(`/event/${value}`)
                break;
            case 'CATEGORY':
                navigate(`/events/${label}/${value}`)
                break;
            default:
                navigate(`/event/${value}`)
            // 'DAY' is the default case
        }

        setVisible(false)
    }

    const handleSelectChange = (value) => {
        setFetching(true)
    };

    function handleClose(open) {
        setVisible(open)
    }

    const debounceTimeout = 800
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setFetching(true);

            if (value && value?.length) {
                setOptions([]);

                api.get(`event/search?searchStr=${value}`)
                    .then((res) => {
                        if (fetchId !== fetchRef.current) {
                            return;
                        }
                        const rawOptions = []
                        const trackedOptions = []
                        const { users, userFriends, privateSecretEvents, friendsEvents, events, categories } = res?.data ?? {}
                        if (Array.isArray(users)) {
                            for (let val of userFriends) {
                                rawOptions.push({ value: val?._id, label: val?.username ?? val?.email, img: val?.img, type: 'FRIEND', })
                                trackedOptions.push(val?._id)
                            }
                        }

                        if (Array.isArray(users)) {
                            for (let val of users) {
                                //Removing duplicate
                                if (!trackedOptions.includes(val?._id))
                                    rawOptions.push({ value: val?._id, label: val?.username ?? val?.email, img: val?.img, type: 'USER', })
                            }
                        }

                        if (Array.isArray(privateSecretEvents)) {
                            for (let val of privateSecretEvents) {
                                rawOptions.push({ value: val?._id, label: val?.name, img: val?.img, type: 'PRIVATE_SECRET_EVENT', })
                            }
                        }

                        if (Array.isArray(friendsEvents)) {
                            for (let val of friendsEvents) {
                                //Removing duplicate
                                if (!trackedOptions.includes(val?._id))
                                    rawOptions.push({ value: val?._id, label: val?.name, img: val?.img, type: 'FRIEND_EVENT', })
                            }
                        }

                        if (Array.isArray(events)) {
                            for (let val of events) {
                                //Removing duplicate
                                if (!trackedOptions.includes(val?._id))
                                    rawOptions.push({ value: val?._id, label: val?.name, img: val?.img, type: 'EVENT', })
                            }
                        }

                        if (Array.isArray(categories)) {
                            for (let val of categories) {
                                rawOptions.push({ value: val?._id, label: val?.title, img: val?.img, type: 'CATEGORY', })
                            }
                        }

                        setOptions(rawOptions);
                    }).catch((ex) => {
                        toast.error('Something went wrong while searching for users! Please try again')
                    }).finally(() => {
                        setFetching(false)
                    })
            } else if (!value?.length) {
                setOptions([])
                setFetching(false)
            } else {
                setFetching(false)
            }
        };
        return debounce(loadOptions, debounceTimeout);
    }, [
        // fetchOptions, 
        debounceTimeout]);

    return <>
        <Select
            id="searchBar"
            open={visible}
            labelInValue
            filterOption={false}
            options={options}
            showSearch={true}
            className="antd__select__searchbar"
            onSearch={debounceFetcher}
            // fieldNames={{ label: 'email', value: '_id' }}
            onChange={handleSelectChange}
            onDropdownVisibleChange={handleClose}
            placeholder='Type user, event and category'
            suffixIcon={false}
            dropdownStyle={{
                maxHeight: '60vh',
                overflow: 'auto',
                backgroundColor: '#0D0D0E',
                border: 1,
                borderStyle: 'solid',
                borderColor: 'rgba(255, 248, 248, 0.5)'
            }}
            dropdownRender={() => {
                return (
                    <div className="custom__dropdown_container">
                        {fetching && <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}><SyncLoader color="#fff" /></div>}
                        {
                            options.map((val, ind) => (
                                <div className=" custom__dropdown"
                                    // onMouseDown={(e) => {
                                    //     e.preventDefault();
                                    //     e.stopPropagation();
                                    // }}
                                    onClick={() => handleDropDownClick(val)}
                                    key={ind}
                                >
                                    <div className="item_container">
                                        {/* <div> */}
                                        <img src={val?.img ?? noImage} alt="img"
                                            className={(val?.type === 'PRIVATE_SECRET_EVENT' ||
                                                val?.type === 'FRIEND_EVENT' || val?.type === 'EVENT') ?
                                                'item__img__event' : 'item__img'}
                                            onError={(event) => {
                                                event.target.src = noImage;
                                            }} />
                                        {/* </div> */}
                                        <div className=" label">{val?.label}</div>
                                        <div className="type">{val?.type === 'FRIEND' ? 'Friend' : val?.type === 'FRIEND_EVENT' ? 'Private' : val?.type === 'PRIVATE_SECRET_EVENT' ? 'Secret' : ''}</div>
                                    </div>
                                </div>))}
                    </div>
                )
            }}

        />
    </>

}