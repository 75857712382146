import { configureStore } from '@reduxjs/toolkit'
import util from './slices/utilSlice'
import event from './slices/eventSlice'
import user from './slices/userSlice'

export const store = configureStore({
  reducer: {
    util,
    event,
    user
  },
})