import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../util/api';

// Define the async thunk to fetch data from the API
export const fetchUserAction = createAsyncThunk('user/fetchUser', async () => {
    try {
        const response = await api.get('user');
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const fetchOtherUserAction = createAsyncThunk('user/fetchOtherUser', async ({ _id }) => {
    try {
        const response = await api.get(`user/${_id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const updateUserAction = createAsyncThunk('user/updateUser', async (payload) => {
    try {
        const response = await api.patch('user', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const updateUserImgAction = createAsyncThunk('user/updateUserImg', async (payload) => {
    try {
        const response = await api.patch('user/img', payload, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const updateUserBlacklistAction = createAsyncThunk('user/updateUserBlacklist', async (payload) => {
    try {
        const response = await api.patch('user/blacklist', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const unblacklistUserAction = createAsyncThunk('user/unBlacklistUser', async (payload) => {
    try {
        const response = await api.patch('user/unblacklist', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const isBlacklistedUserAction = createAsyncThunk('user/isBlacklistUser', async (eventId) => {
    try {
        const response = await api.get(`user/isblacklisted/${eventId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
});
