import moment from "moment";
import { Alram, EyeButton, Message, MultiUser, Setting, Shield, noImage } from "../../assets/images";
import Button from "../button/Button";
import { CountDown } from "../countdown";
import { useNavigate } from "react-router-dom";
import "./ActionButtons.scss"
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import ManagePlayers from "../Popups/managePlayers/ManagePlayers";
import PopupTimeline from "../Popups/Pop up Timeline/PopupTimeline";
import InfoOrganizer from "../infoPopups/infoOrganizer/InfoOrganizer";
import LinksForUser from "../Popups/linksForUser/LinksForUser";
import { toast } from "react-toastify";
import ReadMore from "../Popups/readMore/ReadMore";
import { useWeb3Auth } from "../../context/useWeb3auth";
import { ReactSVG } from "react-svg";
import EditLinks from "../Popups/editLinks/EditLinks";
import EditEventLinks from "../Popups/editEventLinks";
import { TimelineCounterButton } from "./TimelineCounterButton";
import useWindowDimensions from "../../context/useWindowDimmension";

export function ActionButtons({ usingFor, isBlacklisted }) {

    const { loggedIn } = useWeb3Auth()
    const { width } = useWindowDimensions()
    const navigate = useNavigate();
    const { event } = useSelector((state) => state.event);
    const { _id, status, user: eventOwner, commentsCount, endTime, decisionTime, decisionTakenTime, description, videoLink, discordLink, instagramLink, createdBy, fetchingUserId, links, volume, playersCount } = event ?? {}

    const [showManagePlayers, setShowManagePlayers] = useState(false)
    const [showTimeline, setShowTimeline] = useState(false)
    const [showOrganizerInfo, setShowOrganizerInfo] = useState(false);
    const [showLinks, setShowLinks] = useState(false)
    const [showEditLinks, setShowEditLinks] = useState(false)
    const [showComments, setShowComments] = useState(false)


    function handleShowTimeLine() {
        if (!isBlacklisted)
            setShowTimeline(true)
        else
            toast.error("Event's Organizer blocked you, You can't interact with his events.");
    }

    function handleShowComments() {
        if (loggedIn) {
            if (isBlacklisted)
                toast.error("Event's Organizer blocked you, You can't interact with his events.");
            else
                setShowComments(true)

        } else
            toast.error("Please login");
    }

    const handleShowOrganizer = (e) => { e.stopPropagation(); setShowOrganizerInfo(true) };

    function handleSettingButtonClick() {
        if (moment().isSameOrAfter(endTime))
            navigate(`/event/${_id}/decision`)
        else
            navigate(`/event/${_id}/play`)
    }

    const targetDate = useMemo(() => {
        if (endTime && decisionTime) {
            return moment().isBefore(endTime)
                ? endTime : moment().isBefore(decisionTime) ? decisionTime : null
        }
        return null;
    }, [endTime, decisionTime]);


    const descriptionCharLength = width > 540 ? 180 : 136
    return (
        <div className="box__divs">
            {usingFor === 'EVENT' ? <div className="w_full">
                <div className=" organizer_button">
                    <div onClick={() => navigate(`/profile/${createdBy}`)} className="img_container pointer">
                        <img src={get(eventOwner, '[0].img') ?? noImage} alt="img"
                            onError={(event) => {
                                event.target.src = noImage;
                            }} />
                    </div>
                    <div className="details_container">
                        <div onClick={() => navigate(`/profile/${createdBy}`)} className=" username pointer">
                            By {get(eventOwner, '[0].username')}
                            <img src={Shield} alt="img" className="profile_badge" />
                        </div>
                        <div onClick={() => setShowComments(true)} className="text__event pointer">
                            {description?.slice(0, descriptionCharLength)}
                            {description && description.length > descriptionCharLength ? (
                                <span
                                    style={{
                                        color: "rgba(255, 255, 255, 0.3)",
                                        cursor: "pointer",
                                    }}

                                    onClick={() => setShowComments(true)}>
                                    ... Read more
                                </span>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div> : <></>}

            {
                usingFor === 'ORGANIZER' ?
                    <div className="width_half">
                        <Button onClick={() => setShowManagePlayers(true)} colorVariant={'outline_cyan'}>
                            <div className="flex_center">
                                <ReactSVG src={MultiUser} className="svg_container" />
                                <span className='truncate'>Participants</span> </div>
                        </Button>
                    </div> : <></>
            }

            <div className={usingFor === 'ORGANIZER' ? 'width_half' : "width_60"}>
                <TimelineCounterButton
                    onClick={handleShowTimeLine}
                    status={status}
                    endTime={endTime}
                    decisionTime={decisionTime}
                    decisionTakenTime={decisionTakenTime}
                />
            </div>

            {usingFor === 'EVENT' ?
                <div className=" width_40">
                    <Button onClick={handleShowComments} colorVariant={'outline_cyan'}>
                        <div className=" flex_center">
                            <ReactSVG src={Message} className="svg_container" />
                            <span>More</span>
                        </div>
                    </Button>
                </div> : <></>
            }


            {/* <div className=" width_40">
                <Button onClick={() => setShowLinks(true)} className={' drop_shadow'}>
                    <div className=" flex_center">
                        <ReactSVG src={Setting} className="svg_container" />
                        <span>links</span>
                    </div>
                </Button>
            </div> */}
            {/* <div className=" width_half">
                <Button onClick={() => setShowEditLinks(true)} className={' drop_shadow'}>
                    <div className=" flex_center">
                        <ReactSVG src={Setting} className="svg_container" />
                        <span>Edit links</span>
                    </div>
                </Button>
            </div> */}

            {
                usingFor === 'ORGANIZER' && <>
                    <div className=" width_half">
                        <Button onClick={() => navigate(`/event/${_id}/edit`)} >
                            <div className=" flex_center">
                                <ReactSVG src={Setting} className="svg_container" />
                                <span>Edit event</span> </div>
                        </Button>
                    </div>
                    <div className=" width_half">

                        <Button onClick={() => navigate(`/event/${_id}`)} >
                            <div className=" flex_center">
                                <ReactSVG src={EyeButton} className="svg_container" />
                                <span className="truncate">Event page</span> </div>
                        </Button>
                    </div>
                </>
            }

            {
                usingFor === 'EVENT' && (fetchingUserId === createdBy) &&
                <div className=" single_action_button">
                    <Button onClick={handleSettingButtonClick} colorVariant={'dark_outline_cyan'}>
                        <div className=" flex_center"> <span>EVENT SETTINGS</span> </div>
                    </Button>
                </div>
            }

            {
                showManagePlayers &&
                <ManagePlayers
                    eventId={_id}
                    data={{ volume, playersCount, endTime }}
                    open={showManagePlayers}
                    setOpen={setShowManagePlayers} />
            }

            <PopupTimeline
                handleClose={() => setShowTimeline(false)}
                open={showTimeline}
                data={{ endTime, decisionTime, status, decisionTakenTime }}
            />

            <LinksForUser
                handleClose={() => setShowLinks(false)}
                open={showLinks}
                data={{ _id, username: get(eventOwner, '[0].username'), videoLink, discordLink, instagramLink }}
            />
            {/* <EditEventLinks
                handleClose={() => setShowEditLinks(false)}
                open={showEditLinks}
                eventId={_id}
            /> */}

            <ReadMore
                handleClose={() => {
                    setShowComments(false);
                }}
                open={showComments}
                eventId={_id}
                description={description}
                links={links}
                commentsCount={commentsCount} />

            <InfoOrganizer
                handleClose={() => setShowOrganizerInfo(false)}
                open={showOrganizerInfo}
            />
        </div >

    )
}