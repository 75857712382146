// Web3Auth Libraries
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { web3AuthInstance } from "./web3AuthInstance"


export default function Web3AuthConnectorInstance() {
  // Create Web3Auth Instance
  return Web3AuthConnector({
    web3AuthInstance,
  });
}