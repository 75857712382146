import React, { useEffect, useState } from "react";
import "./managePlayers.scss";
import { Typography } from "@mui/material";
import {
  noImageProfile,
} from "../../../assets/images";
import api from "../../../util/api";
import { toast } from "react-toastify";
import { Loader } from "../../loader";
import { formatNumber, somethingWrong } from "../../../util/helper";
import { findIndex, get } from "lodash";
import RefundOrKickPlayer from "../cancel&refund/RefundOrKickPlayer";
import { fetchEventAction } from "../../../store/actions/eventAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ModalContainer } from "../../modalContainer";
import { BadgeMakaoMoney, BadgePlayerCount, TextBadge } from "../../badge";


const ManagePlayers = ({ open, setOpen, eventId, data }) => {
  const dispatch = useDispatch();
  const challenges = useSelector((state) => state.event?.event?.challenges);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { playersCount, volume, endTime } = data ?? {}
  const [loading, setLoading] = useState(false)
  const [players, setPlayers] = useState([])
  const [tab, setTab] = useState('PLAYERS')
  const [playerForRefund, setPlayerForRefund] = useState({})

  useEffect(() => {
    if (eventId)
      handleGetPlayers()
  }, [eventId])

  const isDuringPlay = moment().isBefore(endTime);

  async function handleGetPlayers() {
    try {
      setLoading(true)
      const playersRes = await api.get(`event/${eventId}/players?page=1&pageSize=500`)
      setPlayers(playersRes?.data)
    } catch (ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  async function handleClickRefund() {
    try {
      setLoading(true)
      await api.post(`event/${eventId}/user/${get(playerForRefund, 'player.playBy')}/refund`, { type: playerForRefund?.type })

      setPlayers(players.filter((val) => val?._id !== playerForRefund?.player?._id))
      dispatch(fetchEventAction({ eventId }))
      toast.success(`Participant ${playerForRefund?.type === 'REFUND' ? 'refunded' : 'kicked'} successfully`)
      setPlayerForRefund({})
    } catch (ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalContainer
      open={open}
      handleClose={handleClose}
      heading="Manage participants">
      <Loader loading={loading} />
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="box__head">
          <BadgePlayerCount onClick={() => setTab('PLAYERS')} count={formatNumber(playersCount ?? 0)} />
          <BadgeMakaoMoney onClick={() => setTab('PLAYS')} amount={formatNumber(volume ?? 0)} />
        </div>
        {players.map((val, ind) => (
          <div key={ind} className="box__one__manage">
            <div className="box__one__left truncate">
              <img src={get(val, 'user[0].img') ? get(val, 'user[0].img') : noImageProfile} alt=""
                onError={(event) => {
                  event.target.src = noImageProfile;
                }} />
              <p className="truncate"> {get(val, 'user[0].username')} </p>
            </div>
            {
              tab === 'PLAYS' ?
                <div className="box__one__right">
                  <span className="span_one">
                    <p>#{findIndex(challenges, (o) => o?._id === val?.challenge) + 1}</p>
                  </span>
                  <span className="span_two">
                    <p>{formatNumber(val?.amount)} USDT</p>
                  </span>
                </div>
                :
                <div className="box__one__right_manage">
                  <TextBadge
                    colorVariantClass="pink"
                    text='Refund'
                    onClick={() => {
                      toast.info('Feature coming soon!')
                      // (isDuringPlay || true && !setPlayerForRefund({ type: 'REFUND', player: val })) &&
                      //   toast.info('A participant only could be refunded during the participate time.')
                    }}
                  />
                  <TextBadge
                    colorVariantClass="pink"
                    text='+Kick'
                    onClick={() => {
                      toast.info('Feature coming soon!')
                      // (isDuringPlay || true && !setPlayerForRefund({ type: 'KICK', player: val })) &&
                      //   toast.info('A participant only could be kicked out during the participate  time.')
                    }}
                  />
                </div>
            }
          </div>))
        }
      </Typography>
      <RefundOrKickPlayer
        open={playerForRefund?.type}
        onCancel={() => setPlayerForRefund({})}
        onConfirm={handleClickRefund}
        data={playerForRefund} />
    </ModalContainer>
  );
};

export default ManagePlayers;
