import "./stapper.scss";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { fetchEventAction } from "../../store/actions/eventAction";
import { useDispatch, useSelector } from "react-redux";
import { ChallengeSection } from "../challengeSection";
import { RefundAndCancelEventActions } from "./RefundAndCancelEventActions";
import { Loader } from "../loader";
import { ActionButtons } from "./ActionButtons";
import TrustNotes from "../infoPopups/trustNotes/TrustNotes";
import TrustNoteProgressBar from "../TrustNote__ProgressBar";
import { getAverageTrustNote } from "../../util/helper";
import { BadgeTrustNote } from "../badge";


const StepThree = () => {
  const { _id } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { event, status: eventLoading } = useSelector((state) => state.event);

  const { challenges, createdAt, startTime, endTime, decisionTakenTime, decisionTime, averageReview, createdBy, fetchingUserId, status, blockChain } = event ?? {}

  const [selectedChallengeIds, setSelectedChallengeIds] = useState([])
  const [showTrustNoteInfo, setShowTrustNoteInfo] = useState(false)

  const averageTrustScore = useMemo(() => getAverageTrustNote(averageReview), [averageReview]);

  useEffect(() => {
    if (_id)
      dispatch(fetchEventAction({ eventId: _id }))
  }, [_id])

  function handleSelectChallenge(_id) {
    if (selectedChallengeIds.includes(_id))
      setSelectedChallengeIds(prev => prev.filter(val => val !== _id))
    else
      setSelectedChallengeIds(prev => prev.concat(_id))
  }

  // if (decisionTime && moment().isAfter(decisionTime))
  //   return <div className=" flex_center min_h_60vh text_white width_90_mx_auto">
  //     <div>Event decision time has been passed! Please go to <span onClick={() => navigate(`/event/${_id}`)} className="pointer underline">challenge page</span> for more information</div>
  //   </div>

  if (createdAt && endTime && moment().isBetween(moment(createdAt).subtract(30, 'second'), endTime))
    return <div className=" flex_center min_h_60vh text_white width_90_mx_auto">
      <div>Event is still in participate duration! Please go to <span onClick={() => navigate(`/event/${_id}/play`)} className="pointer underline">participate page</span> for more information</div>
    </div>


  //If a particular event does't belongs to user
  if (fetchingUserId && createdBy && (fetchingUserId !== createdBy))
    return <div className=" flex_center min_h_60vh text_white width_90_mx_auto">
      <div>This event does not belongs to you!</div>
    </div>

  return (
    <>
      <Loader loading={eventLoading === "loading"} />

      <TrustNotes show={showTrustNoteInfo} setShow={setShowTrustNoteInfo} />

      <div className="form__event">
        <div className="action_buttons_container_organizer">
          <ActionButtons usingFor={"ORGANIZER"} />
        </div>

        <div className=" w_full challenges_container">
          <ChallengeSection
            challenges={challenges}
            onSelectChallenge={handleSelectChallenge}
            data={{ startTime, eventId: _id, selectedChallengeIds }}
            type="DECISION"
            showPendingApproval={false}
          />
        </div>

        <div className="bottom">
          {decisionTakenTime ? (
            <>
              <div className="terminate">
                <p>
                  {status === "REFUND"
                    ? "Event has been refunded"
                    : "Decision has been made already"}
                </p>
              </div>
              {Array.isArray(averageReview) && averageReview.length &&
                <TrustNoteProgressBar
                  {...{
                    status,
                    averageTrustScore,
                    setShowTrustNoteInfo,
                  }}
                />
              }

            </>
          ) : !moment().isAfter(decisionTime) && (
            <RefundAndCancelEventActions showDistribute={true} data={{ _id, challenges, selectedChallengeIds, blockChain }} />
          )}
        </div>
      </div>
    </>
  );
};

export default StepThree;
