import "./tenisImg.scss";

import React, { useMemo, useState } from "react";
import { noImage } from "../../assets/images";

import PlayersPopup from "../Popups/players/PlayersPopup";
import { useNavigate } from "react-router-dom";
import { formatNumber, getAverageTrustNote } from "../../util/helper";
import MoreforThisEvent from "../moreForThisEvent/MoreforThisEvent";
import EventContext from "../../context/eventContext";
import moment from 'moment'
import { get } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Badge60MinutesCountDown, BadgeMakaoMoney, BadgeOrganizerFee, BadgePlayerCount, BadgeThreeDot, BadgeTrustNote } from "../badge";


const EventCard = ({ data, type = 'EVENT', isReviewGiven = true }) => {
  const navigate = useNavigate();

  const isBlacklisted = useSelector((state) => state.user?.user?.isBlacklisted);
  const friendsEvents = useSelector((state) => state.event.friendsEvents?.events);
  const userId = useSelector((state) => state.user?.user?._id)

  const { name, img, _id, playersCount, volume, fees, averageReview, decisionTakenTime, createdAt, createdBy, endTime, user, status } = data ?? {}

  const [open, setOpen] = React.useState(false);
  const [showMore, setShowMore] = useState(false)

  const handleOpen = () => !isBlacklisted ? setOpen(true) : toast.error("Event's Organiser blocked you, You can't interact with his events.");
  const handleClose = () => setOpen(false);

  function handleNavigate() {
    switch (type) {
      case 'ORGANISED':
        if (createdBy !== userId)
          navigate(`/event/${_id}`)
        else {
          if (moment().isBetween(createdAt, endTime))
            navigate(`/event/${_id}/play`)
          else if (moment().isSameOrAfter(endTime))
            navigate(`/event/${_id}/decision`)
        }
        break;

      default:
        navigate(`/event/${_id}`)
      // 'DAY' is the default case
    }

  }

  const isFriendsPlayingFromFriendsEvents = useMemo(() => {
    if (Array.isArray(friendsEvents))
      return friendsEvents.some(eventObj => eventObj?._id === data?._id);
    return false
  }, [friendsEvents, data]);

  //checking for by default in api
  const areFriendsPlaying = get(user, '[0].areFriendsPlaying')
  const averageTrustScore = useMemo(() => getAverageTrustNote(averageReview), [averageReview]);


  return (
    <EventContext.Provider value={data}>
      <div onClick={handleNavigate} className="event__card cursor__pointer">
        <div className="event__img__container">
          <img src={img ?? noImage} alt="img" className="event__img"
            onError={(event) => {
              event.target.src = noImage;
            }} />
          <div className="overlay"></div>
        </div>

        <div className="card_action_images">
          <div className=" left_section">

            {isReviewGiven && decisionTakenTime &&
              <BadgeTrustNote trustNote={averageTrustScore} noReview={(Array.isArray(averageReview) && averageReview.length === 0) || !averageReview} sizeVariant="small" />
            }

            {
              status === 'DEFAULT' && moment(endTime).diff(moment(), 'minutes') < 60 && moment(endTime).diff(moment(), 'minutes') > 0 &&
              <Badge60MinutesCountDown date={endTime} />
            }


            <BadgeMakaoMoney onClick={handleOpen} amount={formatNumber(volume ?? 0)} />
            <BadgePlayerCount onClick={handleOpen} count={formatNumber(playersCount ?? 0)}
            // className={` ${type === 'FRIENDS' || areFriendsPlaying || isFriendsPlayingFromFriendsEvents ? 'friend__playing' : ''}`} 
            />

            {type === 'ORGANISED' && <BadgeOrganizerFee onClick={handleOpen} fee={formatNumber(volume * (fees / 100))} />}

          </div>
          <div>
            <BadgeThreeDot onClick={(e) => {
              e.stopPropagation();
              setShowMore(true)
            }} />
          </div>
        </div>
        <div className="text">
          <p>{name?.slice(0, 110)}</p>
        </div>
      </div>

      {open &&
        <PlayersPopup eventId={_id} data={{ playersCount, volume }} handleClose={handleClose} open={open} />
      }

      {
        showMore &&
        <MoreforThisEvent
          handleClose={() => setShowMore(false)}
          open={showMore}
        />
      }

    </EventContext.Provider >
  );
};

export default EventCard;
