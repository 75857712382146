/* global BigInt */

import React, { useEffect, useMemo, useState } from "react";
import "./place.scss";
import { Box, Modal, Typography } from "@mui/material";
import { Close } from "../../../assets/images";
import PlayLoading from "../Transactions1/Transaction1";
import api from "../../../util/api";
import { toast } from "react-toastify";
import { TokenType, formatNumber, somethingWrong } from "../../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { get, find } from 'lodash'
import { updateEventAction } from "../../../store/slices/eventSlice";
import { useReadContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { tokenAddress } from "../../../config/web3AuthInstance";
import { tokenAbi } from "../../../abi/tokenAbi";
import { makaoFixtureAbi } from "../../../abi/makaoFixtureAbi";
import { useWeb3Auth } from "../../../context/useWeb3auth";
import { ModalContainer } from "../../modalContainer";
import { BadgeOdd } from "../../badge";
import Button from "../../button/Button";
import { useLocation, useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const tokenAbiArray = tokenAbi;

const Place_a_bet_popup = ({ handleClose, open, data, eventId }) => {

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const eventReferral = searchParams?.get('event-referral')

  const { balance: ethBalance, address: userAddress, getBalance } = useWeb3Auth()

  const dispatch = useDispatch();

  const { odd, title, logic, _id, status, signedSignature, changePercentage } = data ?? {}
  const event = useSelector((state) => state.event?.event)
  const { fees, platformFees, userBalance, volume, blockChain, tokenType, createdBy, fetchingUserId } = event ?? {}

  const [openTran, setOpenTran] = useState(false);
  const [amount, setAmount] = useState(0)
  const [referralDetails, setReferralDetails] = useState()

  const [alreadyHavingTokenAllowance, setAlreadyHavingTokenAllowance] = useState(false)

  const { data: stakeApproveHash, isPending: isStakeApprovePending, error: stakeApproveError, isError: isStackApproveError, writeContract: stakeApproveWriteContract } = useWriteContract();
  const { isLoading: isStakeApproveConfirming, isSuccess: isStakeApproveConfirmed, error: stakeApproveReceiptError, isError: isStakeApproveReceiptError } = useWaitForTransactionReceipt({ hash: stakeApproveHash })

  const { data: stakeHash, isPending: isStakePending, error: stakeError, isError: isStakeError, writeContract: stakeWriteContract } = useWriteContract();
  const { isLoading: isStakeConfirming, isSuccess: isStakeConfirmed, error: stakeReceiptError, isError: isStakeReceiptError } = useWaitForTransactionReceipt({ hash: stakeHash })

  const { data: tokenBalance } = useReadContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName: 'balanceOf',
    args: [userAddress]
  });

  const { data: tokenAllowance } = useReadContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName: 'allowance',
    args: [userAddress, blockChain?.contractAddress]
  })

  useEffect(() => {
    getBalance()
  }, [])

  useEffect(() => {
    if (eventReferral)
      fetchReferralDetails()
  }, [eventReferral])

  useEffect(() => {
    if (isStackApproveError) {
      if (typeof stakeApproveError?.name === 'string' && stakeApproveError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else
        toast.error(stakeApproveError?.message)
    }
    else if (isStakeApproveReceiptError) {
      if (typeof stakeApproveReceiptError?.name === 'string' && stakeApproveReceiptError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else
        toast.error(stakeApproveReceiptError?.message)
    }

  }, [isStackApproveError, isStakeApproveReceiptError])

  useEffect(() => {

    if (isStakeError) {
      if (typeof stakeError?.name === 'string' && stakeError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else
        toast.error(stakeError?.message)
    }
    else if (isStakeReceiptError) {
      if (typeof stakeReceiptError?.name === 'string' && stakeReceiptError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else
        toast.error(stakeReceiptError?.message)
    }
  }, [isStakeError, isStakeReceiptError])

  useEffect(() => {

    if (isStakeApproveConfirmed || alreadyHavingTokenAllowance) {
      if (tokenBalance <= 0)
        return toast.error('Participation amount should be positive amount!')
      else if (tokenBalance && (Number(tokenBalance) / 1000000) < amount)
        return toast.info('Low balance! Please buy more USDT')

      const noReferralAddress = `0x0000000000000000000000000000000000000000`
      const referralAddress = (createdBy === fetchingUserId) || (fetchingUserId === get(referralDetails, 'user._id')) ? noReferralAddress : get(referralDetails, 'user.walletAddress', noReferralAddress)
      console.log('referral Ad', referralAddress)
      console.log('payload', [
        amount * 1000000,
        title,
        signedSignature,
        referralAddress
      ])
      if (tokenType === TokenType.ETH)
        stakeWriteContract({
          address: blockChain.contractAddress,
          abi: makaoFixtureAbi,
          functionName: 'engage',
          args: [
            title,
            signedSignature,
            referralAddress
          ],
          value: `0x${BigInt(amount * 1000000000000000000).toString(16)}`
        })
      else
        stakeWriteContract({
          address: blockChain.contractAddress,
          abi: makaoFixtureAbi,
          functionName: 'engage',
          args: [
            amount * 1000000,
            title,
            signedSignature,
            referralAddress
          ]
        });
    }
  }, [isStakeApproveConfirmed, alreadyHavingTokenAllowance])

  //After all blockchain event confirmed
  useEffect(() => {
    if (isStakeConfirmed)
      handlePlay()
  }, [isStakeConfirmed])

  async function handlePlayApprovalStake() {
    if (amount <= 0)
      return toast.error('Please add valid amount.')

    if (ethBalance <= 0)
      return toast.error('Low ETH balance! Your account does not have enough ETH balance to pay gas fees')

    if (tokenType === TokenType.ETH)
      return setAlreadyHavingTokenAllowance(true)

    if (tokenType === TokenType.USDC && tokenAllowance && (Number(tokenAllowance) / 1000000) > amount)
      return setAlreadyHavingTokenAllowance(true)

    if (tokenType === TokenType.USDC && tokenBalance && (Number(tokenBalance) / 1000000) < amount)
      return toast.info('Low balance! Buy more USDT feature coming soon.')

    stakeApproveWriteContract({
      address: tokenAddress,
      abi: tokenAbiArray,
      functionName: 'approve',
      args: [
        blockChain.contractAddress,
        amount * 10000000 * 1000000,
      ]
    });
  }

  async function fetchReferralDetails() {
    try {
      const { data } = await api.get(`event/${eventId}/event-referral/${eventReferral}`)
      setReferralDetails(data)
    } catch (ex) {
      toast.error(ex?.message ?? 'Something went wrong while loading referral details')
    }
  }

  const handlePlay = async () => {
    try {

      setOpenTran(true)
      const playRes = await api.post('play', { amount, challenge: _id, event: eventId, stakeHash })

      //logic to update the data on frontend after play
      const { updatedChallenges, updatedEvent } = playRes?.data ?? {}
      const { playersCount, volume } = updatedEvent ?? {}

      const challenges = event.challenges.map((val) => {
        const foundUpdatedChallenge = find(updatedChallenges, function (o) { return o?._id == val?._id; })
        if (foundUpdatedChallenge?._id)
          return {
            ...val, odd: foundUpdatedChallenge?.odd ?? 0,
            plays: _id === foundUpdatedChallenge?._id ? [
              {
                _id: null,
                myPlay: get(val, 'plays[0].myPlay', 0) + amount
              }
            ] : val.plays,
            challengstakeWriteContracteVolume: _id === foundUpdatedChallenge?._id ? [
              {
                _id: null,
                volume: get(val, 'challengeVolume[0].volume', 0) + amount
              }
            ] : val.challengeVolume
          }

        return val
      })

      const rawEvent = { ...event, playersCount, volume, challenges, userBalance: userBalance ? userBalance - amount : 0 }

      dispatch(updateEventAction(rawEvent))

      handleClose()
      toast.success('The participation has been completed.')
      setAmount(0)
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setOpenTran(false);
      setAlreadyHavingTokenAllowance(false)
    }
  };


  function handleCloseClickAndOthers() {
    setAmount(0)
    handleClose()
  }

  const handleCloseTran = () => setOpenTran(false);

  const formattedNumber = useMemo(() => {
    if (amount) {
      // const totalFees = platformFees + (fees ?? 0)
      const totalFees = fees ?? 0
      const totalVolume = volume ? volume + amount : amount
      const feesVolumePercentage = (totalVolume * totalFees) / 100

      const numerator = totalVolume - feesVolumePercentage;
      const denominator = get(data, 'challengeVolume[0].volume', 0) + amount;

      if (denominator !== 0) {
        const result = numerator / denominator;
        return formatNumber(result);
      }
    }
    else return odd ?? 1
  }, [amount, volume, odd]);

  const changePercentageCalculated = useMemo(() => {
    if (amount !== 0) {
      const profit = (formattedNumber * amount) - amount
      return ((profit / amount) * 100).toFixed(0); // Return the percentage change
    }
    return 0;
  }, [formattedNumber, amount]);

  return (
    <ModalContainer
      open={open}
      handleClose={handleCloseClickAndOthers}
      heading={title ?? ''}>

      <div className="mt_24">
        <Typography>
          <p className="para">
            {logic}
          </p>
        </Typography>
      </div>

      <div className="play_container ">
        <Typography>
          <div className="your_play">
            <div className="flex">
              <label className="label">Your prediction</label>
            </div>
            <div className="input_field_container">
              <div className="input_field">
                <input
                  autoFocus={true}
                  type="number"
                  inputMode="decimal"
                  placeholder="Enter your participate"
                  className="your_play_input"
                  onChange={({ target }) => setAmount(Number(target.value))}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && status)
                      handlePlayApprovalStake()
                  }}
                  min={0}
                  max={userBalance ? userBalance : null}
                />
                <span> {tokenType === TokenType.ETH ? 'ETH' : 'USDT'} </span>
              </div>
              <BadgeOdd odd={formattedNumber} changePercentage={changePercentage} className="flex_shrink_0" />
            </div>
            <div className=" flex justify_end">
              <span className="bottom_Desc">You have {tokenType === TokenType.ETH ? `${ethBalance} ETH` : `${formatNumber(tokenBalance ? Number(tokenBalance) / 1000000 : 0)} USDT`} left</span>
              <span className="expected_odds">Max odds</span>
            </div>
          </div>
          <div className="bottom__desc">
            <div className="potential_win">
              <span className="title">Potential return</span>
              <span className="amount">USDT {formatNumber((formattedNumber * amount) - amount)} ({changePercentageCalculated}%)</span>
            </div>
          </div>
        </Typography>

        {status ?
          <Button
            colorVariant={userBalance && userBalance < amount ? "light_gray" : "green"}
            disabled={amount === 0}
            onClick={handlePlayApprovalStake} >
            <span>{userBalance && userBalance < amount ? 'Buy USDT' : 'Mak'}</span>
          </Button>
          :
          <Button colorVariant="pink" disabled={true}>
            <span> Waiting approval</span>
          </Button>
        }
      </div>


      <PlayLoading
        handleClose={handleCloseTran}
        open={openTran || isStakeApprovePending || isStakePending || isStakeApproveConfirming || isStakeConfirming}
        loadingStates={{
          openTran, isStakeApprovePending, isStakePending, isStakeApproveConfirming, isStakeConfirming
        }}
      />
    </ModalContainer>
  );
};

export default Place_a_bet_popup;
