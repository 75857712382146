import React from "react";
import "./network.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close, DangerPopup } from "../../../assets/images";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChangeNetwork = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}> Change network </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <div className="arrow-up">
            <img src={DangerPopup} alt="Image" style={{ width: "25px" }} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
            style={{ marginTop: "1rem" }}
          >
            You are using the wrong <br /> network
          </Typography>
          <button className="popups__btn"> Click to change </button>
        </Box>
      </Modal>
    </>
  );
};

export default ChangeNetwork;
