import React, { useEffect } from "react";
import "./dash__top.scss";
import { useNavigate } from "react-router-dom";
import PlayersPopup from "../../Popups/players/PlayersPopup";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventsAction, fetchTrendingEventsAction } from "../../../store/actions/eventAction";
import EventCard from "../../eventCard";
import { fetchUserAction } from "../../../store/actions/userAction";
import { useWeb3Auth } from "../../../context/useWeb3auth";
import { HeadingAndViewAll } from "../dash__content/HeadingAndViewAll";

const DashTopCards = () => {
  const { loggedIn } = useWeb3Auth()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let trendingEvents = useSelector((state) => state.event.trendingEvents);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    dispatch(fetchTrendingEventsAction({ page: 1, pageSize: 20, sortAt: 'volume', sortType: 'dsc' }))
    if (loggedIn)
      dispatch(fetchUserAction()) // fetching user details here also.
  }, [loggedIn])

  return (
    <>
      <PlayersPopup handleClose={handleClose} open={open} />
      <div className="dash__content__top">
        <HeadingAndViewAll onClick={() => navigate("/trending-detail")} title={'Trending now'} className={'mt_0'}/>

        <div className="trending_card_container">

          {trendingEvents.slice(0, 20).map((val, ind) => (<div key={ind} style={{ width: 345, minWidth: 345 }}>
            <EventCard data={val} />
          </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DashTopCards;
