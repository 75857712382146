import React from "react";
import "./possibleOutcomes.scss";
import { useForm } from "react-hook-form"
import { ModalContainer } from "../../modalContainer";
import Button from "../../button/Button";


const PossibleOutcomes = ({ handleClose, open, buttonText = 'Make proposal', onSubmitForm, loading }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const onClickClose = () => {
    reset();
    handleClose()
  }
  const onSubmit = (data) => {
    onSubmitForm?.(data);
    reset()
  }

  return (
    <ModalContainer
      open={open}
      handleClose={onClickClose}
      heading={'New prediction'} >

      <div className="possible_outcome_container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input__groups__outcome">
            <label> Name your prediction </label>
            <input type="text"
              placeholder="Draw"
              autoFocus
              {...register("title", { required: true })} />
            {errors.title && <span className="error_email">This field is required</span>}
          </div>
          <div
            className="input__groups__outcome"
            style={{ marginTop: "1rem" }}
          >
            <label> Decision logic </label>
            <textarea
              type="text"
              placeholder="The Combat final result is a draw."
              {...register("logic", { required: false })}
            />
          </div>
          <Button type="submit" disabled={loading} colorVariant="light_gray" className={'mt_16'}>
            <span>{buttonText}</span>
          </Button>
        </form>
      </div>
    </ModalContainer>
  )
};

export default PossibleOutcomes;
