import { find, get } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Copy, Metamask, Down, I, MakaoCurrencyIcon, Up, Social, TorusEvm, Coinbase, OtherWallet } from "../../assets/images";
import { useWeb3Auth } from "../../context/useWeb3auth";
import api from "../../util/api";
import { TokenType, TokenTypeMap, formatNumber, somethingWrong } from "../../util/helper";
import Button from "../button/Button";
import CurrentBalance from "../infoPopups/CurrentBalance/CurrentBalance";
import WalletInfo from "../infoPopups/walletInfo/WalletInfo";
import { Loader } from "../loader";
import BetsHistory from "../Popups/BetsHistory/BetsHistory";
import Claim from "../Popups/claim/Claim";
import ClaimsPopup from "../Popups/claimsPopup/ClaimsPopup";
import HistoryReferrals from "../Popups/historyRefrellals/HistoryReferrals";
import ReviewDecision from "../Popups/reviewDecision/ReviewDecision";
import Stacking from "../Popups/stacking/Stacking";
import "./wallet.scss";
import { useReadContract } from "wagmi";
import { tokenAddress } from "../../config/web3AuthInstance";
import { tokenAbi } from "../../abi/tokenAbi";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { SendToken } from "../Popups/SendToken";
import { useSelector } from "react-redux";

const Deposit = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user?.user)
  const { balance, address, getBalance, loggedIn, getPrivateKey } = useWeb3Auth()

  const { data: tokenBalance, refetch: refetchTokenBalance } = useReadContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName: 'balanceOf',
    args: [address]
  });

  const [open, setOpen] = React.useState(false);
  const [openClaim, setOpenClaim] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenClaim = () => setOpenClaim(true);
  const handleClose = () => {
    setOpen(false);
    setClaimPopup({ title: "", popupOpen: false, data: {} });
  };
  const handleCloseClaim = () => setOpenClaim(false);

  const [claimPopup, setClaimPopup] = useState({ title: "", popupOpen: false, data: {} });
  const [claimableAmount, setClaimableAmount] = useState(0)

  const [openWallet, setOpenWallet] = React.useState(false);
  const handleOpenWallet = () => setOpenWallet(true);
  const handleCloseWallet = () => setOpenWallet(false);

  const [openWalletInfo, setOpenWalletInfo] = React.useState(false);
  const handleOpenWalletInfo = () => setOpenWalletInfo(true);
  const handleCloseWalletInfo = () => setOpenWalletInfo(false);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [showSendToken, setShowSendToken] = useState(false)
  const [parentSelectedToken, setParentSelectedToken] = useState('')
  const [privateKey, setPrivateKey] = useState('')

  const [isOpenReviewNClaimAll, setOpenReviewNClaimAll] = useState(false);
  const handleOpenReviewNClaimAll = () => setOpenReviewNClaimAll(true);
  const handleCloseReviewNClaimAll = () => setOpenReviewNClaimAll(false);
  const [allEventsChallengesList, setAllEventsChallengesList] = useState([]);
  const [allEventsChallengesReviewList, setAllEventsChallengesReviewList] = useState([]);

  useEffect(() => {
    handleFetchBalanceDetails();
    getAllEventsChallenges();
    getBalance()
    handleGetPrivatekey()
  }, []);

  useEffect(() => {
    (async function fetchUnclaimedClaims() {
      try {
        if (loggedIn) {
          const res = await api.get(`/event/claims`);
          if (res?.data) {
            const totalAmount = res?.data?.reduce((acc, item) => acc + item.amount, 0);
            setClaimableAmount(totalAmount)
          }
        }
      } catch (ex) {
        toast.error(ex?.message ?? somethingWrong);
      }
    })();
  }, [loggedIn]);

  async function handleFetchBalanceDetails() {
    try {
      setIsLoading(true);

      const transactionDetails = await api.get("user/wallet");
      setData(transactionDetails?.data);
    } catch (ex) {
      toast.error(ex?.message, somethingWrong);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleGetPrivatekey() {
    const key = await getPrivateKey()
    if (key)
      setPrivateKey(key)
  }

  const getAllEventsChallenges = async () => {
    try {
      setIsLoading(true);

      const allEventsChallenges = await api.get("event/reviewEventsChallenges");
      setAllEventsChallengesList(allEventsChallenges?.data);

      const allEventsChallengesReviewList = allEventsChallenges?.data?.reduce(
        (acc, event) => {
          const userChallengeData = event.userChallengeData.map((element) => {
            element.totalEventAmount = event.totalEventAmount;
            element.eventId = event.eventId;
            element.totalEventPotentialWin = event.totalEventPotentialWin;
            element.eventName = event.eventName;
            element.eventImg = event.eventImg;
            return element;
          });
          delete event.userChallengeData;
          return [...acc, ...userChallengeData];
        },
        []
      );
      setAllEventsChallengesReviewList(allEventsChallengesReviewList);

      // const balanceRes = await api.get('user/wallet')
      // setData(balanceRes?.data)
    } catch (ex) {
      toast.error(ex?.message, somethingWrong);
    } finally {
      setIsLoading(false);
    }
  }
  const claimAllEvents = () => {
    navigate('/reviews-and-claim')
    // handleOpenReviewNClaimAll()
  }

  function handleSendTokenSuccess() {
    refetchTokenBalance()
    setShowSendToken(false)
    setParentSelectedToken('')
    handleFetchBalanceDetails()
  }

  const shortenAddress = (address) => {
    if (!address) return '';
    const start = address.substring(0, 6);
    const end = address.substring(address.length - 6);
    return `${start}...${end}`;
  };

  async function handleCopyAddress() {
    await navigator.clipboard.writeText(address);
    toast.success('Address copied successfully')
  }

  function getRespectiveAuthenticatorLogo(name) {
    const images = {
      google: Social,
      facebook: Social,
      twitter: Social,
      discord: Social,
      email_passwordless: Social,
      web3auth: Social,
      "torus-evm": TorusEvm,
      metamask: Metamask,
      coinbase: Coinbase,
      "walletconnect-v2": OtherWallet
    };

    return images[name] || OtherWallet;
  }

  return (
    <>
      <Loader loading={isLoading} />

      <ReviewDecision
        currentPage="WALLET"
        allEventsChallengesReviewList={allEventsChallengesReviewList}
        allEventsChallengesList={allEventsChallengesList}
        handleOpenClaim={handleOpenClaim}
        handleClose={handleCloseReviewNClaimAll}
        open={isOpenReviewNClaimAll}
      />
      <ClaimsPopup handleClose={handleCloseClaim} open={openClaim} />
      <Stacking handleClose={handleClose} open={open} />
      {claimPopup.type == "App Referral" && (
        <HistoryReferrals
          handleClose={handleClose}
          open={claimPopup?.popupOpen}
        />
      )}
      {/* Your play */}
      {claimPopup.type == "PLAY" && (
        <BetsHistory
          handleClose={handleClose}
          open={claimPopup?.popupOpen}
          data={claimPopup.data}
        />
      )}

      {/* Plays’ profits */}
      {claimPopup.type == "Participates’ returns" && (
        <BetsHistory
          handleClose={handleClose}
          open={claimPopup?.popupOpen}
          data={claimPopup.data}
        />
      )}

      {claimPopup.type == "WIN" && (
        <Claim
          handleClose={handleClose}
          open={claimPopup?.popupOpen}
          data={claimPopup.data}
        />
      )}
      {claimPopup.type == "Event Referral" && (
        <HistoryReferrals
          handleClose={handleClose}
          open={claimPopup?.popupOpen}
        />
      )}
      <CurrentBalance handleClose={handleCloseWallet} open={openWallet} />
      <WalletInfo handleClose={handleCloseWalletInfo} open={openWalletInfo} />

      <div className="wallet__deposit">
        <div className="wallet__content">
          <div className="wallet__left">
            <div className=" winning_reward_container">
              <div className="single_container">
                <span className="title">To Review & Claim</span>
                <div className="box_container">
                  <span>{formatNumber(claimableAmount)}</span>
                  {/* <ReactSVG src={MakaoCurrencyIcon} className="svg_container makao_svg" /> */}
                </div>
                <div className="button_container">
                  <Button
                    onClick={claimAllEvents}
                    colorVariant="dark_cyan">
                    Review & Claim
                  </Button>
                </div>
              </div>
              <div className="single_container send_buttons">
                <Button
                  onClick={() => {
                    setShowSendToken(true)
                    setParentSelectedToken(TokenType.ETH)
                  }}
                  sizeVariant="small"
                  colorVariant="light_gray">
                  Send MATIC
                </Button>
                <Button
                  onClick={() => {
                    setShowSendToken(true)
                    setParentSelectedToken(TokenType.USDC)
                  }}
                  sizeVariant="small"
                  colorVariant="light_gray">
                  Send USDT
                </Button>
              </div>
              {/* 
                 <div className="single_container send_buttons">
                 <span className="title">Rewards</span>
                <div className="box_container">
                  <span>0 </span>
                  <ReactSVG src={MakaoCurrencyIcon} className="svg_container makao_svg" />
                </div>
                <div className="button_container">
                  <Button
                    onClick={() => {
                      // setShowSendToken(true)
                      toast.info('This feature is coming soon.')
                    }}
                    colorVariant="dark_cyan">
                    Claim all
                  </Button>
                </div>
                </div> */}
            </div>

            {/* +<div className="ddeposit__four__butons">
              <Button
                onClick={() => {
                  // handleOpen
                  toast.info('This feature is coming soon.')
                }}
                sizeVariant="small"
                colorVariant="pink">
                Buy/sell USDT
              </Button>
              <Button
                onClick={() => {
                  toast.info('This feature is coming soon.')
                }}
                sizeVariant="small"
                colorVariant="green">
                Send
              </Button>
              <Button
                onClick={() => {
                  toast.info('This feature is coming soon.')
                }}
                sizeVariant="small"
                colorVariant="pink">
                Buy/Sell MAKS
              </Button>
              <Button
                onClick={() => {
                  toast.info('This feature is coming soon.')
                }}
                sizeVariant="small"
                colorVariant="green">
                Stake MAKS
              </Button>
            </div> */}

            {/* <div className="wallet__left__top__box">
              <div className=" dap_balance flex flex_direction_col gap_1rem font_sm">
                <div>
                  <span className="font_medium">DAap balance</span>
                  <br />
                  <span className="numbers"> {balance} MATIC</span>
                </div>
                <div>
                  <span className="font_medium">USDT balance</span>
                  <br />
                  {tokenBalance
                    ? <span className="numbers"> {Number(tokenBalance) / 1000000} USDT</span>
                    : <span className="numbers">0 USDT</span>}
                </div>
                <div>
                  <span className="font_medium"> Address </span>
                  <br />
                  <span className="address">{address}</span>
                </div>
                {privateKey ?
                  <div>
                    <span className="font_medium"> Private key </span>
                    <br />
                    <span className="address">{privateKey}</span>
                  </div>
                  : <></>
                }

                <div>
                  <span className="font_medium"> Fake currency wallet </span>
                  <br />
                  <span className="address">{formatNumber(get(data, "user[0].balance", 0))}USDT</span>
                </div>
              </div>
              <img src={I} alt="" className="i" onClick={handleOpenWallet} />
            </div> */}

          </div>
          <div className="wallet__right">
            <div className="top__boxes__head">
              <span>
                Wallet <img src={I} alt="" onClick={handleOpenWalletInfo} />
              </span>
              {/* <span>Add new</span> */}
            </div>
            <div className="top__box">
              <div className="top__box__left">
                <img src={getRespectiveAuthenticatorLogo(user?.web3Auth?.verifier)} alt="" />
                <span>{shortenAddress(address)}</span>
                <ReactSVG onClick={handleCopyAddress} src={Copy} className="svg_container pointer" />
              </div>
              <div className="top__box__right">
                <span>{Number(tokenBalance) / 1000000} USDT</span>
                <span>{balance} MATIC </span>
              </div>
            </div>

            <div className="bottom__boxes">
              <div className="bottom__boxes__head">
                <span>Transaction history</span>
                <span>Amount</span>
              </div>
              {Array.isArray(data?.transactions) &&
                data.transactions.map((val, ind) => (
                  <div
                    key={ind}
                    className="bottom__boxes__box"
                    onClick={() =>
                      setClaimPopup({
                        type: val?.type ?? "WIN",
                        popupOpen: true,
                        data: val,
                      })
                    }
                  >
                    <div className="top__box__left">
                      <img src={val?.type === "PLAY" || val?.type === "SEND" ? Down : Up} alt="" />
                      <span>
                        {HandleDifferentTransactionString({ data: val, challenges: data.challenges })}
                        {/* {val?.type === "PLAY" ? "Your participate's" : "Success"} */}
                      </span>
                    </div>
                    <div className="top__box__right">
                      <span
                        style={{ color: val?.type === "PLAY" || val?.type === "SEND" ? "#FFCACA" : "" }}
                      >
                        {formatNumber(val?.amount)}
                        {` ${HandleDifferentTokenString({ data: val, challenges: data.challenges })}`}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div >

      {showSendToken &&
        <SendToken
          open={showSendToken}
          handleClose={() => {
            setShowSendToken(false)
            setParentSelectedToken('')
          }}
          handleSuccess={handleSendTokenSuccess}
          tokenBalance={tokenBalance ? Number(tokenBalance) / 1000000 : 0}
          balance={balance}
          parentSelectedToken={parentSelectedToken}
        />
      }
    </>
  );
};

export default Deposit;


function HandleDifferentTransactionString({ data, challenges }) {

  const type = data?.type

  function getChallengeTitle(challengeId) {
    if (Array.isArray(challenges)) {
      const foundChallenge = find(challenges, (o) => o?._id === challengeId)
      if (foundChallenge?.title)
        return foundChallenge?.title
    }
    return ''
  }

  switch (type) {
    case 'PLAY':
      return `Bought #${get(data, 'challenge.title')}`
    case 'REFUND':
    case 'CLAIM':
      return `Claimed #${getChallengeTitle(data?.challenge)}`
    case 'SEND':
      return `Sent to ${get(data, 'sentTo.username')}`

    default:
      return ``
  }
}

function HandleDifferentTokenString({ data, challenges }) {

  const type = data?.type

  function getClaimCurrency(challengeId) {
    if (Array.isArray(challenges)) {
      const foundChallenge = find(challenges, (o) => o?._id === challengeId)
      if (foundChallenge?.title)
        return TokenTypeMap[get(foundChallenge, 'event[0].tokenType')]
    }
    return ''
  }

  switch (type) {
    case 'PLAY':
      return TokenTypeMap[get(data, 'event.tokenType')]
    case 'REFUND':
    case 'CLAIM':
      return `${getClaimCurrency(data?.challenge)}`
    case 'SEND':
      return TokenTypeMap[get(data, 'tokenType')]
    default:
      return ``
  }
}