import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../../config/firebase-config";
import { useLocation, useNavigate } from "react-router-dom";
import { getKeyFromCookie } from "../../util/helper";

export const ToggleContext = createContext();

export function useAuth() {
  return useContext(ToggleContext);
}

export const ContextApi = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [token, setToken] = useState(getKeyFromCookie('token'));

  return (
    <ToggleContext.Provider value={{ token, setToken }}>
      {children}
    </ToggleContext.Provider>
  );
};

export default ContextApi;
