import React, { useEffect, useState } from "react";
import "./dash__content.scss";
import "../../games/games.scss";
import {
  noImage,
} from "../../../assets/images";
import DashTopCards from "../dash__content__top/DashTopCards";
import { useNavigate } from "react-router-dom";
import PlayersPopup from "../../Popups/players/PlayersPopup";
import { fetchCategoriesAction } from "../../../store/actions/utilAction";
import { useDispatch, useSelector } from "react-redux";
import { FriendsEvents } from "./friendsEvents";
import { FriendsLeaderboard } from "./friendsLeaderboard";
import { OrganiserLeaderboard } from "./organiserLeaderboard";
import { Loader } from "../../loader";
import { useWeb3Auth } from "../../../context/useWeb3auth";
import { BiggestPredictions } from "../../PredictionPage/BiggestPredictions";
import { HeadingAndViewAll } from "./HeadingAndViewAll";
import { get } from "lodash";
import TimeUpEvents from "../TimeUpEvents";

const Dash__content = ({ isSidebarOpen }) => {
  const { loggedIn } = useWeb3Auth()
  const [tab, setTab] = useState("players");
  const handleClick = (type) => {
    setTab(type);
  };

  console.log('process.env.NODE_ENV', process.env.NODE_ENV, process.env.REACT_APP_VERCEL_ENV)
  console.log('document.referrer', document.referrer, typeof document?.referrer)

  const dispatch = useDispatch();
  const { categories, status } = useSelector((state) => state.util);
  const tendingLoading = useSelector((state) => state.event.status)

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (Array.isArray(categories) && categories.length === 0)
      dispatch(fetchCategoriesAction())
  }, [])

  return (
    <>
      <Loader loading={tendingLoading === 'loading' || status === 'loading'} />
      <PlayersPopup handleClose={handleClose} open={open} />
      <div className="dash__content">
        <DashTopCards />

        <div className="categories">
          <HeadingAndViewAll onClick={() => navigate(`/events/${get(categories, '[0].title')}/${get(categories, '[0]._id')}`)} title={'Categories'} />
          <div className="categories_grid">
            {categories.map((el, ind) => (
              <div key={ind} className="category_container"
                onClick={() => navigate(`/events/${el?.title}/${el?._id}`)}>
                <div className="cat_image" >
                  <img src={el?.img ?? noImage} alt="" className="img" />
                </div>
                <span> {el?.title} </span>
              </div>
            ))}
          </div>
        </div>

        {loggedIn && <FriendsEvents />}


        {loggedIn && <FriendsLeaderboard />}

        <OrganiserLeaderboard />

        <TimeUpEvents />

        <div className="biggest_predictions_container_dashboard">
          <HeadingAndViewAll onClick={() => navigate('/predictions')} title={'Biggest predictions'} />
          <BiggestPredictions />
        </div>
      </div>
    </>
  )
};

export default Dash__content;
