import React, { useEffect, useState } from "react";
import "./following.scss";
import { Typography } from "@mui/material";
import { noImageProfile } from "../../../assets/images";
import api from "../../../util/api";
import { toast } from "react-toastify";
import { somethingWrong } from "../../../util/helper";
import { useDispatch } from "react-redux";
import { userLoadingAction } from "../../../store/slices/userSlice";
import { Loader } from "../../loader";
import { ModalContainer } from "../../modalContainer";
import { TextBadge } from "../../badge";

const Following = ({ open, setOpen, _id }) => {
  const dispatch = useDispatch()

  const [friends, setFriends] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (_id)
      handleFetchUserFriends()
  }, [_id])

  async function handleFetchUserFriends() {
    try {
      setLoading(true)
      const friendsRes = await api.get('user/friends')
      setFriends(friendsRes?.data)
    } catch (_ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  async function handleRemoveFriend(removingId) {
    try {
      dispatch(userLoadingAction(true))
      await api.post('user/friend', { friendId: removingId, type: 'UNFRIEND' })
      setFriends(prev => prev.filter((val) => val?._id !== removingId))
      toast.info('Friend removed successfully')
    } catch (_ex) {
      toast.error(somethingWrong)
    } finally {
      dispatch(userLoadingAction(false))
    }
  }

  return (
    <ModalContainer
      open={open}
      handleClose={() => setOpen(false)}
      heading="Friends">
      <Loader loading={loading} />
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {friends.map((val, ind) => (<div className="box__one__friends">
          <div key={ind} className="box__one__left">
            <img src={val?.img ?? noImageProfile} alt=""
              onError={(event) => {
                event.target.src = noImageProfile;
              }} />
            <p>{val?.username} </p>
          </div>

          <div className="box__one__right">
            <TextBadge onClick={() => handleRemoveFriend(val?._id)} colorVariantClass="pink" text={'Unfollow'} />
          </div>
        </div>
        ))}
      </Typography>
    </ModalContainer>
  );
};

export default Following;
