import React from "react";
import { Link } from "react-router-dom";
import "./transaction.scss";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { Close, GifTransaction } from "../../../assets/images";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Transaction2 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button onClick={handleOpen}> Transaction processing (loading) </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup complete">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            Claim
          </Typography>
          {/* <div className="spin">
            <CircularProgress />
          </div> */}
          <img src={GifTransaction} alt="" style={{ width: "80px" }} />
          <Typography
            id="modal-modal-description"
            sx={{ mt: 5, color: "#FFFFFF" }}
          >
            We are processing your claim. <br />
            <Link to="" className="popup__text">
              Please wait
            </Link>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Transaction2;
