import React from "react";
import "./button_com.scss";

const Button_Com = ({ children, onClick, className, ...restProps }) => {
  return (
    <button
      className={`custom_button_com ${className}`}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button_Com;
