import React, { useEffect } from "react";
import "../dash__content__top/dash__top.scss";
import { useNavigate } from "react-router-dom";
import PlayersPopup from "../../Popups/players/PlayersPopup";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimeUpEventsAction, } from "../../../store/actions/eventAction";
import EventCard from "../../eventCard";
import { useWeb3Auth } from "../../../context/useWeb3auth";
import { HeadingAndViewAll } from "../dash__content/HeadingAndViewAll";

const TimeUpEvents = () => {
  const { loggedIn } = useWeb3Auth()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let timeUpEvents = useSelector((state) => state.event.timeUpEvents);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    dispatch(fetchTimeUpEventsAction({ page: 1, pageSize: 20, sortAt: 'endTime', sortType: 'asc' }))
  }, [loggedIn])

  return (
    <>
      <PlayersPopup handleClose={handleClose} open={open} />
      <div className="dash__content__top">
        <HeadingAndViewAll onClick={() => navigate("/time-almost-up")} title={'Almost Out of Time!'} className={''} />

        <div className="trending_card_container">

          {Array.isArray(timeUpEvents) && timeUpEvents?.slice(0, 20).map((val, ind) => (<div key={ind} style={{ width: 345, minWidth: 345 }}>
            <EventCard data={val} />
          </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TimeUpEvents;
