import React, { useEffect, useState } from "react";
import "./profile.scss";
import {
    Chart_analysis,
    EyeButton,
    LinkButtonIcon,
    ShieldBlack,
    Verified,
    ProfileEdit,
    noImage,
    noImageProfile,
} from "../../assets/images";
import { Link, useParams } from "react-router-dom";
import Button_Com from "../button_com/Button_Com";
import LinksForUser from "../Popups/linksForUser/LinksForUser";
import MyGamesEventInfo from "../myGamesEventInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchOtherUserAction, fetchUserAction } from "../../store/actions/userAction";
import Following from "../Popups/following/Following";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import api from "../../util/api";
import { updateUserStateAction, userLoadingAction } from "../../store/slices/userSlice";
import Button from "../button/Button";
import { BadgeTrustNote } from "../badge";

const OtherUserProfile = () => {
    const { _id } = useParams()

    const dispatch = useDispatch();
    const { otherUser, status, user } = useSelector((state) => state.user);
    const { username, description, img, sameUser, privacy, instagramLink, userTrustNote } = otherUser ?? {}

    const [showFriends, setShowFriends] = useState(false)

    useEffect(() => {
        if (_id)
            dispatch(fetchOtherUserAction({ _id }))
    }, [_id])

    useEffect(() => {
        //Fetching logged in user details, if page reloads
        if (!user?._id)
            dispatch(fetchUserAction())
    }, [user?._id])

    async function handleAddFriend() {
        try {
            dispatch(userLoadingAction(true))
            const addFriend = await api.post('user/friend', { friendId: _id })
            const friends = Array.isArray(user.friends) ? [...user.friends] : []
            friends.push(_id)
            dispatch(updateUserStateAction({ ...user, friends }))
            toast.info('Friend added successfully')
        } catch (_ex) {
            toast.error(somethingWrong)
        } finally {
            dispatch(userLoadingAction(false))
        }
    }

    return (
        <MyGamesEventInfo
            otherUserData={{ otherUserId: _id }}
            renderLeftProfile={(
                tab,
                openLink,
                tabSat,
                handleOpenLink,
                handleClickSat,
                handleCloseLink,
                MyGamesContainer
            ) => (
                privacy === false ?
                    <div className="private_profile"> User Profile is Private</div>
                    :
                    <>
                        {openLink && <LinksForUser handleClose={handleCloseLink} open={openLink}
                            data={{ instagramLink, username }}
                            onlyUser={true} />
                        }

                        {showFriends &&
                            <Following _id={_id} open={showFriends} setOpen={setShowFriends} />}


                        <div className="profile">
                            <div className="profile__left">
                                <div className="profile__left__content">
                                    <div className="profile__img ">
                                        <div className="image_container">
                                            <img src={img ?? noImageProfile} alt=""
                                                onError={(event) => {
                                                    event.target.src = noImageProfile;
                                                }} />
                                            <img
                                                src={Verified}
                                                alt=""
                                                style={{
                                                    position: "absolute",
                                                    top: "58%",
                                                    right: "4px",
                                                    width: "20px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="leaderboards__tabs">
                                        <div className="box__tabs">
                                            <Link
                                                to=""
                                                className="truncate"
                                            >
                                                {username ? `@${username}` : 'unknown'}
                                            </Link>
                                            <BadgeTrustNote
                                                trustNote={userTrustNote}
                                                noReview={!(userTrustNote || userTrustNote == 0)}
                                                onClick={() => handleClickSat("4.9/5")}
                                            />

                                            <Link
                                                to=""
                                                style={{ flexShrink: 0 }}
                                            >
                                                Rank : 2873
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p
                                            style={{
                                                marginTop: "1rem",
                                                marginBottom: "1rem",
                                                color: "white",
                                                textShadow: "rgba(255, 255, 255, 0.09)",
                                            }}
                                        >
                                            {description}
                                            {/* <span style={{ color: "rgba(255, 255, 255, 0.3)" }}> */}
                                            {" "}
                                            {/* Read more{" "} */}
                                            {/* </span> */}
                                        </p>
                                    </div>
                                    <div className="buttons__two">

                                        <div className=" width_50">

                                            {sameUser ?
                                                <Button disabled={true} className={'drop_shadow padding_x_xs'}>
                                                    <div className=" flex_center truncate">
                                                        <img src={EyeButton} style={{ width: 20, objectFit: 'contain' }} />
                                                        <span>Your's Profile</span>
                                                    </div>
                                                </Button>
                                                : <Button
                                                    onClick={handleAddFriend}
                                                    disabled={
                                                        (Array.isArray(user.friends) && user.friends.includes(_id))
                                                        || status === 'loading'
                                                    }
                                                    className={'drop_shadow'}>
                                                    <div className=" flex_center">
                                                        <img src={EyeButton} style={{ width: 20, objectFit: 'contain' }} />
                                                        <span>{Array.isArray(user.friends) && user.friends.includes(_id) ? 'Already Friend' : 'Add Friend'}</span>
                                                    </div>
                                                </Button>
                                            }
                                        </div>

                                        <div className=" width_50">

                                            {tab === "trends" ?
                                                <Button onClick={() => {
                                                    toast.info('This feature is coming soon.')
                                                }} className={'drop_shadow padding_x_xs'}>
                                                    <div className=" flex_center truncate">
                                                        <img src={Chart_analysis} style={{ width: 20, objectFit: 'contain' }} />
                                                        <span>Stats</span>
                                                    </div>
                                                </Button>
                                                :
                                                <Button onClick={handleOpenLink} className={'drop_shadow'}>
                                                    <div className=" flex_center">
                                                        <img src={LinkButtonIcon} style={{ width: 20, objectFit: 'contain' }} />
                                                        <span>Links</span>
                                                    </div>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile__right">{MyGamesContainer}</div>
                        </div>
                    </>
            )
            }
        />
    );
};

export default OtherUserProfile;
