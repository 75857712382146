import React, { useEffect, useMemo, useRef, useState } from "react";
import "./admin.scss";
import "../manageCategories/categories.scss";
import {
  AdminCategory,
  AdminUser,
  Del,
  Gallery,
  GamePad,
  MakaoCurrencyIcon,
  Search,
  StarAdmin,
  noImage,
} from "../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { TextBadgeCyan } from "../badge";
import { CustomCheckBox } from "../input/CustomCheckBox";
import Button from "../button/Button";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import api from "../../util/api";
import { debounce, get } from "lodash";
import { Loader } from "../loader";
import CancelAndRefundEvent from "../Popups/cancel&refund/CancelAndRefundEvent";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { makaoFixtureAbi } from "../../abi/makaoFixtureAbi";

function UsersOrEventsList({ list,
  checkBoxes,
  showAmount = true,
  managingCategory = false,
  handleAction,
  handleLoadMore,
  handleUpdateCategory,
  onClick
}) {
  const contentContainerRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      if (isScrollAtBottom()) {
        handleLoadMore?.();
      }
    }

    contentContainerRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      contentContainerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to check if the user has scrolled to the bottom of the div
  function isScrollAtBottom() {
    if (!contentContainerRef.current) return false;
    const { scrollHeight, scrollTop, clientHeight } = contentContainerRef.current;
    return scrollHeight - scrollTop === clientHeight;
  }
  const handleImageChange = (e, id, index,) => {
    const file = e.target.files[0];
    if (file) {

      const reqFormData = new FormData();
      reqFormData.set('img', file);
      handleUpdateCategory(id, reqFormData);
    }
  };

  const handleClick = (index) => {
    document.getElementById(`image-${index}`).click();
  };
  return (
    <div >
      {managingCategory === false && <div className="header_tabs_container">
        <div className="header_tabs">
          {checkBoxes?.map((val, ind) => <TextBadgeCyan key={ind} text={val?.title} />)}
        </div>
      </div>
      }
      <div ref={contentContainerRef} style={{ overflowY: 'auto', maxHeight: '70vh', paddingRight: 4 }}>
        {
          list.map((el, ind) => (<div key={ind} className="box__one">

            <div className={`box__one__left ${onClick ? 'pointer' : ''}`} onClick={() => onClick?.(el?._id)}>
              {
                managingCategory === true && <input type="file" id={`image-${ind}`} accept="image/*" onChange={({ target }) => handleUpdateCategory({ file: target?.files[0], _id: el?._id, ind })} style={{ display: 'none' }} />
              }
              <img className={`img ${managingCategory ? 'category_img' : get(checkBoxes, '[0].value') === 'privacy' ? 'event_img' : ''}`} src={el.img ?? noImage} alt="img"
                onError={(event) => {
                  event.target.src = noImage
                }}
                style={{ cursor: managingCategory ? 'pointer' : 'default' }}
                onClick={() => managingCategory && handleClick(ind)} />
              <div className=" text_container ">
                <p> @{el.name} </p>
                {showAmount ?
                  <div className="amount_container">
                    {/* <ReactSVG className="svg_container" src={MakaoCurrencyIcon} alt="makao" /> */}
                    <span>$</span>
                    <span>{el?.amount}</span> </div> : <></>}

              </div>

            </div>

            <div className={`box__one__right ${(managingCategory || el?.status) && 'single_child'}`}>
              {managingCategory ?
                <div onClick={() => handleAction?.({ _id: el?._id, updatingObj: { status: !el?.status }, ind })} className=" pointer">
                  < ReactSVG className="svg_container" src={Del} alt="" /></div>
                : el?.status ?
                  <div className=" flex justify_end">
                    <TextBadgeCyan text={el?.eventStatus === 'REFUND' ? 'Refunded' : el?.eventStatus === 'COMPLETE' ? 'Completed' : ''} />
                  </div>
                  :
                  <>
                    {
                      checkBoxes?.map((val, checkboxInd) => <span key={checkboxInd} className="checkbox_container">
                        <CustomCheckBox checked={el[val?.value]} onClick={({ target }) => handleAction?.({ _id: el?._id, updatingObj: { [val?.value]: target?.checked }, ind })} />
                      </span>)
                    }
                  </>}
            </div>
          </div>))
        }
      </div>
    </div>)
}

const AdminstatorPage = () => {
  const pageRef = useRef()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([])
  const [specialAccounts, setSpecialAccounts] = useState([])
  const [events, setEvents] = useState([])
  const [categories, setCategories] = useState([])
  const [showCancelAndRefundConfirmation, setShowCancelAndRefundConfirmation] = useState(false)
  const [cancelingEventData, setCancelingEventData] = useState({})
  const [selectedFile, setSelectedFile] = useState()
  const [categoryTitle, setCategoryTitle] = useState('')
  const [noMoreData, setNoMoreData] = useState(false)

  const { data: cancelAndRefundHash, isPending: isCancelAndRefundPending, error: cancelAndRefundError, isError: isCancelAndRefundError, writeContract: cancelAndRefundWriteContract } = useWriteContract();
  const { isLoading: isCancelAndRefundConfirming, isSuccess: isCancelAndRefundConfirmed, error: cancelAndRefundReceiptError, isError: isCancelAndRefundReceiptError } = useWaitForTransactionReceipt({ hash: cancelAndRefundHash })

  useEffect(() => {
    if (isCancelAndRefundError) {
      if (typeof cancelAndRefundError?.name === 'string' && cancelAndRefundError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else
        toast.error(cancelAndRefundError?.message)
    }
    else if (isCancelAndRefundReceiptError) {
      if (typeof cancelAndRefundReceiptError?.name === 'string' && cancelAndRefundReceiptError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else
        toast.error(cancelAndRefundReceiptError?.message)
    }

  }, [isCancelAndRefundError, isCancelAndRefundReceiptError])

  useEffect(() => {
    if (isCancelAndRefundConfirmed)
      handleCancelAndRefund()
  }, [isCancelAndRefundConfirmed])


  useEffect(() => {
    if (pathname === '/administrator')
      navigate('/administrator/manage-users')

    if (pathname === '/administrator/manage-users') {
      handleFetchUsersForAdmin({})
    }

    if (pathname.includes('/manage-accounts')) {
      handleFetchSpecialAccountsForAdmin({})
    }

    if (pathname.includes('/manage-events'))
      handleFetchEventsForAdmin({})

    if (pathname.includes('/manage-categories'))
      handleFetchCategoriesForAdmin({})

    //resting page and data
    setEvents([])
    setUsers([])
    setSpecialAccounts([])
    setCategories([])
    setSearch('')
    pageRef.current = 1

  }, [pathname])

  async function handleFetchUsersForAdmin({ text, page = 1 }) {
    try {
      setLoading(true)
      const users = await api.get(`user/admin/users?text=${text ? text : ''}&page=${page}`)
      if (Array.isArray(users?.data)) {
        setUsers(prev => prev.concat(users?.data?.map(({ username, plays, admin = {}, ...rest }) => { return { name: username, amount: get(plays, '[0].totalAmount', 0), ...admin, ...rest } })))
      }
      pageRef.current = users?.page
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }


  async function handleFetchSpecialAccountsForAdmin({ text, page = 1 }) {
    try {
      setLoading(true)
      const users = await api.get(`user/admin/users?text=${text ? text : ''}&page=${page}`)
      if (Array.isArray(users?.data)) {
        setSpecialAccounts(prev => prev.concat(users?.data?.map(({ username, plays, admin = {}, ...rest }) => { return { name: username, amount: get(plays, '[0].totalAmount', 0), ...admin, ...rest } })))
      }
      pageRef.current = users?.page
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }


  async function handleFetchEventsForAdmin({ text, page = 1 }) {
    try {
      setLoading(true)
      const events = await api.get(`event/admin/events?page=${page}&text=${text ? text : ''}`)
      if (Array.isArray(events?.data)) {
        setEvents(prev => prev.concat(events?.data?.map(({ privacy, status, ...rest }) => { return { privacy: privacy === 'PRIVATE' || privacy === 'SECRET' ? true : false, status: status === 'DEFAULT' ? false : true, eventStatus: status, ...rest } })))
      }
      pageRef.current = events?.page
      if (Array.isArray(events?.data) && events?.data.length < 20)
        setNoMoreData(true)
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  const debounceTimeout = 800
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      if (pathname?.includes('/manage-users')) {
        handleFetchUsersForAdmin({ text: value, page: 1 })
        setUsers([])
      }
      else if (pathname?.includes('/manage-accounts')) {
        handleFetchSpecialAccountsForAdmin({ text: value, page: 1 })
        setSpecialAccounts([])
      }
      else if (pathname?.includes('/manage-events')) {
        setEvents([])
        handleFetchEventsForAdmin({ text: value, page: 1 })
      }
      else if (pathname?.includes('/manage-categories')) {
        handleFetchCategoriesForAdmin({ text: value, page: 1 })
        setCategories([])
      }

      pageRef.current = 1
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, pathname]);

  const handleChange = ({ target }) => {
    setSearch(target.value);
    debounceFetcher(target.value);
  };

  async function handleManageUserAction({ _id, updatingObj = {}, ind, type }) {
    try {
      setLoading(true)
      const updatedUser = await api.patch(`user/admin/user`, { userId: _id, ...updatingObj })
      const rawUsers = type === 'user' ? [...users] : [...specialAccounts]
      const user = { ...rawUsers[ind], ...updatedUser?.data?.admin }
      rawUsers[ind] = user
      if (type === 'user')
        setUsers(rawUsers)
      else setSpecialAccounts(rawUsers)

    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  function handleManageEventActionConfirmation({ _id, updatingObj = {}, ind }) {
    setCancelingEventData({ _id, updatingObj, ind, event: events[ind] })
    setShowCancelAndRefundConfirmation(true)
  }
  async function handleManageEventAction() {
    try {
      const { _id, updatingObj = {}, ind } = cancelingEventData ?? {}

      //refunding process
      if (updatingObj?.hasOwnProperty('status')) {
        setShowCancelAndRefundConfirmation(false)
        return handleBlockChainCancelAndRefund()
      }

      setLoading(true)
      let update = {}
      console.log('update', _id, updatingObj, typeof updatingObj?.privacy, updatingObj?.privacy === false)
      if (updatingObj?.privacy)
        update.privacy = 'PRIVATE'
      else if (updatingObj?.privacy === false)
        update.privacy = 'PUBLIC'

      // if (updatingObj?.status)
      //   update.status = 'REFUND'
      if (update?.privacy) {
        await api.patch(`event/admin/event`, { eventId: _id, ...update })
        const rawEvents = [...events]
        const event = rawEvents[ind]
        event.privacy = update?.privacy === 'PRIVATE' ? true : false
        rawEvents[ind] = event
        setEvents(rawEvents)
      }
      setShowCancelAndRefundConfirmation(false)
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  async function handleCancelAndRefund() {
    try {
      const { _id, ind } = cancelingEventData ?? {}
      setLoading(true)
      await api.post(`event/${_id}/decision/refund`)
      const rawEvents = [...events]
      const event = events[ind]
      event.status = true
      event.eventStatus = 'REFUND'
      rawEvents[ind] = event
      setEvents(rawEvents)
      setShowCancelAndRefundConfirmation(false)
      toast.success('Cancel and refund process successful')
    } catch (ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  async function handleBlockChainCancelAndRefund() {
    cancelAndRefundWriteContract({
      address: cancelingEventData?.event?.blockChain.contractAddress,
      abi: makaoFixtureAbi,
      functionName: 'cancel',
    });
  }


  async function handleManageCategoryAction({ _id, ind }) {
    try {
      setLoading(true)
      await api.delete(`category/admin/delete-category/${_id}`)
      const rawCategories = [...categories]
      delete rawCategories[ind]
      setCategories(rawCategories)
      toast.success('Category deleted successfully')
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile(null);
    }
  };

  async function handleAddCategory() {

    try {
      setLoading(true)
      const reqFormData = new FormData();
      reqFormData.set('title', categoryTitle)

      if (selectedFile) {
        reqFormData.set('img', selectedFile);
      }
      const category = await api.post(`category/admin/create-category`, reqFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      const { title, ...rest } = category?.data ?? {}
      const rawCategories = [...categories]
      rawCategories.unshift({ name: title, ...rest })

      setCategories(rawCategories)
      toast.success('Category Added successfully')

      setSelectedFile()
      setCategoryTitle('')
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }
  async function handleUpdateCategory({ _id, file, ind }) {
    try {
      setLoading(true)
      const reqFormData = new FormData();
      reqFormData.set('img', file);
      const category = await api.patch(`category/admin/update-category/${_id}`, reqFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      const { img } = category?.data ?? {}
      const rawCategories = [...categories]
      rawCategories[ind] = { ...rawCategories[ind], img }

      setCategories(rawCategories)
      toast.success('Category Added successfully')

      setSelectedFile()
      setCategoryTitle('')
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }
  async function handleFetchCategoriesForAdmin({ text, page = 1 }) {
    try {
      setLoading(true)
      const response = await api.get(`category/admin/categories?status=true&page=${page}&text=${text ? text : ''}`);
      setCategories(prev => prev.concat(response?.data?.map(({ title, events, ...rest }) => { return { name: title, amount: get(events, '[0].totalVolume', 0), ...rest } })))
      pageRef.current = response?.page
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  function loadMoreContent() {
    console.log('Loading more content...');
    const page = pageRef?.current ? pageRef.current + 1 : 1
    if (pathname?.includes('/manage-events'))
      handleFetchEventsForAdmin({ text: search, page })
    if (pathname?.includes('/manage-categories'))
      handleFetchCategoriesForAdmin({ text: search, page })
    if (pathname?.includes('/manage-users'))
      handleFetchUsersForAdmin({ text: search, page })
    if (pathname?.includes('/manage-accounts'))
      handleFetchSpecialAccountsForAdmin({ text: search, page })
    // Simulated function to load more content
    // Replace this with your actual function to load more content
  }

  return (
    <>
      <div className="admin_page">
        <Loader loading={loading || isCancelAndRefundConfirming || isCancelAndRefundPending} />
        <div className="admin__left">
          <div className="content">
            <div className="label">
              <div className=" flex gap_1rem mb_4">
                <label> Platform fees </label>
                <TextBadgeCyan text={'Coming soon'} />
              </div>
              <p> Optional </p>
            </div>
            <input type="text" placeholder="0-20%" autoFocus={true} />
            <div className="action_buttons">
              <div onClick={() => navigate('/administrator/manage-users')} className={`button_container pointer ${pathname?.includes('/manage-user') ? 'active' : ''}`}>
                <ReactSVG className="svg_container" src={AdminUser} alt="" />
                <p>Manage Users</p>
              </div>
              <div onClick={() => navigate('/administrator/manage-events')} className={`button_container pointer ${pathname?.includes('/manage-events') ? 'active' : ''}`}>
                <ReactSVG className="svg_container" src={GamePad} alt="" />
                <p>Manage Events</p>
              </div>
              <div onClick={() => navigate('/administrator/manage-categories')} className={`button_container pointer ${pathname?.includes('/manage-categories') ? 'active' : ''}`}>
                <ReactSVG className="svg_container small_height" src={AdminCategory} alt="" />
                <p>Manage categories</p>
              </div>
              <div onClick={() => navigate('/administrator/manage-accounts')} className={`button_container pointer ${pathname?.includes('/manage-accounts') ? 'active' : ''}`}>
                <ReactSVG className="svg_container" src={StarAdmin} alt="" />
                <p>Special accounts</p>
              </div>
            </div>
          </div>
        </div>

        <div className="admin__right">
          <div className="center__content">
            <img src={Search} alt="img" />
            <input
              type="text"
              placeholder={`Search ${pathname?.includes('/manage-users') || pathname?.includes('/manage-accounts') ? 'users' : pathname?.includes('/manage-events') ? 'events' : 'categories'}`}
              value={search}
              onChange={handleChange}
            />
          </div>
          <div className=" admin_user_container">
            {pathname?.includes('/manage-users') &&
              <UsersOrEventsList list={users}
                handleAction={(obj) => handleManageUserAction({ ...obj, type: 'user' })}
                checkBoxes={[{ title: 'Participant only', value: 'playerOnly' }, { title: 'Blacklisted', value: 'blackListed' }]}
                handleLoadMore={loadMoreContent}
                onClick={(_id) => navigate(`/profile/${_id}`)} />
            }

            {pathname?.includes('/manage-accounts') &&
              <UsersOrEventsList
                list={specialAccounts}
                handleAction={(obj) => handleManageUserAction({ ...obj, type: 'special' })}
                checkBoxes={[{ title: 'VIP (50%off)', value: 'vip' }, { title: 'LP (0% fee)', value: 'lp' }]}
                handleLoadMore={loadMoreContent}
                onClick={(_id) => navigate(`/profile/${_id}`)} />
            }

            {pathname?.includes('/manage-events') &&
              <UsersOrEventsList
                list={events}
                showAmount={false}
                handleAction={handleManageEventActionConfirmation}
                checkBoxes={[{ title: 'Private', value: 'privacy' }, { title: 'Cancel&Ref', value: 'status' }]}
                handleLoadMore={loadMoreContent}
                onClick={(_id) => navigate(`/event/${_id}`)} />
            }


            {pathname?.includes('/manage-categories') &&
              <>
                <div className="add_category_container">
                  <div className="input_container">
                    <label htmlFor="fileInput">
                      <div className="file_input_container">
                        <img src={Gallery} alt="" className="img_gal" />
                        <input
                          type="file"
                          id="fileInput"
                          className="file_input"
                          placeholder="Image"
                          onChange={handleFileChange}
                          accept="image/*"
                        />
                      </div>
                    </label>
                    <input
                      className="category_input"
                      placeholder="Category name"
                      name="categoryName"
                      onChange={e => setCategoryTitle(e.target.value)}
                      value={categoryTitle}
                    />
                  </div>
                  {selectedFile ? <span className="selected_file_name">{selectedFile?.name}</span > : <></>}

                  {/* {errors.selectedFile && (
                    <span className="error_email">{errors.selectedFile}</span>
                  )} */}

                  <div className=" mt_16">
                    <Button onClick={handleAddCategory} disabled={!categoryTitle} className={'light_green h_41 w_half'}>Create Category</Button>
                  </div>
                </div>
                <UsersOrEventsList
                  list={categories}
                  managingCategory={true}
                  handleAction={handleManageCategoryAction}
                  handleLoadMore={loadMoreContent}
                  handleUpdateCategory={handleUpdateCategory}
                />
              </>
            }
          </div>

          <CancelAndRefundEvent
            data={cancelingEventData?.event}
            open={showCancelAndRefundConfirmation}
            handleClose={() => setShowCancelAndRefundConfirmation(false)}
            handleConfirm={handleManageEventAction}
            loading={loading}
            heading={cancelingEventData?.updatingObj?.hasOwnProperty('status') ?
              `Cancel & Refund event`
              : `Make this event ${get(cancelingEventData, 'updatingObj.privacy') ? 'private' : 'public'}`}
            subHeading={
              cancelingEventData?.updatingObj?.hasOwnProperty('status') ? `Are you sure you want to cancel and refund the following event :`
                : `Are you sure you want to make this event ${get(cancelingEventData, 'updatingObj.privacy') ? 'private' : 'public'} :`
            } />

          {/* {pathname?.includes('/manage-categories') && (
            <>
              {filteredDataManageCategories.map((el) => (
                <>
                  <div className="box__one" style={el.style}>
                    <div className="box__one__left">
                      <img
                        src={el.img}
                        alt=""
                        style={{ width: "40px", borderRadius: "10px" }}
                      />
                      <p> {el.name} </p>
                    </div>
                    <div className="box__one__right_category">
                      <img src={el.imgDel} alt="" />
                    </div>
                  </div>
                </>
              ))}
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default AdminstatorPage;
