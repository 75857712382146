/* global BigInt */

import { Select, Space } from "antd";
import Button from "../../button/Button";
import { ModalContainer } from "../../modalContainer";
import './index.scss'
import { useEffect, useMemo, useRef, useState } from "react";
import api from "../../../util/api";
import { toast } from "react-toastify";
import { debounce, find, get } from "lodash";
import { MUserWhite, noImage } from "../../../assets/images";
import { tokenAddress } from "../../../config/web3AuthInstance";
import { tokenAbi } from "../../../abi/tokenAbi";
import { useSendTransaction, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { Loader } from '../../loader'
import { TokenTypeMap, shortCryptoAddress, somethingWrong } from "../../../util/helper";
import { parseEther } from 'viem'

export function SendToken({ open, handleClose, parentSelectedToken, tokenBalance, balance, handleSuccess }) {
    const fetchRef = useRef(0);

    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState();
    const [selectedUser, setSelectedUser] = useState()
    const [selectedTokenType, setSelectedTokenType] = useState('USDC')
    const [amount, setAmount] = useState()

    const [fetching, setFetching] = useState(false);
    const [confirm, setConfirm] = useState(false)
    const [loading, setLoading] = useState(false)

    const { data: stakeHash, isPending: isStakePending, error: stakeError, isError: isStakeError, writeContract: stakeWriteContract } = useWriteContract();
    const { isLoading: isStakeConfirming, isSuccess: isStakeConfirmed, error: stakeReceiptError, isError: isStakeReceiptError } = useWaitForTransactionReceipt({ hash: stakeHash })

    const { data: maticStakeHash, isPending: isMaticStakePending, error: maticStakeError, isError: isMaticStakeError, sendTransaction } = useSendTransaction();

    const { isLoading: isMaticStakeConfirming, isSuccess: isMaticStakeConfirmed, error: maticStakeReceiptError, isError: isMaticStakeReceiptError } = useWaitForTransactionReceipt({ hash: maticStakeHash })

    useEffect(() => {
        if (parentSelectedToken)
            setSelectedTokenType(parentSelectedToken)
    }, [parentSelectedToken])

    useEffect(() => {
        if (isStakeError) {
            if (typeof stakeError?.name === 'string' && stakeError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(stakeError?.message)
        }
        else if (isStakeReceiptError) {
            if (typeof stakeReceiptError?.name === 'string' && stakeReceiptError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(stakeReceiptError?.message)
        }
    }, [isStakeError, isStakeReceiptError])

    useEffect(() => {
        if (isMaticStakeError) {
            if (typeof maticStakeError?.name === 'string' && maticStakeError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(maticStakeError?.message)
        }
        else if (isMaticStakeError) {
            if (typeof maticStakeReceiptError?.name === 'string' && maticStakeReceiptError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(maticStakeReceiptError?.message)
        }
    }, [isMaticStakeError, isMaticStakeError])

    //After all blockchain event confirmed
    useEffect(() => {
        if (isStakeConfirmed || isMaticStakeConfirmed) {
            saveTransaction()
        }
    }, [isStakeConfirmed, isMaticStakeConfirmed])


    const handleSelectChange = (data) => {
        setSelected(data)
        const found = find(options, (o) => o?._id === data?.value)
        setSelectedUser(found)
    };

    async function saveTransaction() {
        try {
            setLoading(true)
            api.post('/transaction', { sentTo: selectedUser?._id, amount, type: 'SEND', tokenType: selectedTokenType })
            toast.success('Token has been sent successfully')
            handleSuccess()
        } catch (ex) {
            toast.error(ex?.message ?? somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    const debounceTimeout = 800
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            api.get(`user/search?text=${value}`)
                .then((res) => {
                    if (fetchId !== fetchRef.current) {
                        return;
                    }
                    if (Array.isArray(res?.data)) {
                        console.log('res', res?.data?.length)
                        if (res?.data?.length)
                            setOptions(res?.data);
                        else if (/^0x[a-fA-F0-9]{40}$/.test(value))
                            setOptions([{
                                "_id": value,
                                "walletAddress": value,
                                "username": value
                            }])
                    }
                    setFetching(false);
                }).catch((ex) => {
                    toast.error('Something went wrong while searching for users! Please try again')
                })
        };
        return debounce(loadOptions, debounceTimeout);
    }, [
        // fetchOptions, 
        debounceTimeout]);

    function handleSendToken() {
        if (selectedTokenType === 'USDC')
            stakeWriteContract({
                address: tokenAddress,
                abi: tokenAbi,
                functionName: 'transfer',
                args: [
                    selectedUser?.walletAddress,
                    amount * 1000000,
                ]
            })
        else
            sendTransaction({
                to: selectedUser?.walletAddress,
                value: amount ? parseEther(String(amount)) : 0,
            })
    }

    const selectedUserImg = useMemo(() => {
        if (selected?.value) {
            const user = find(options, (o) => o?._id === selected?.value)
            return user?.img
        }
        return '';
    }, [selected]);

    return (
        <ModalContainer
            open={open}
            handleClose={handleClose}
            heading={confirm ? `Confirm transaction` : `Sending tokens`}>
            <Loader loading={isStakeConfirming || isStakePending || isMaticStakeConfirming || isMaticStakePending || loading} />
            {confirm ?
                <div className="send_token_container relative">
                    <div className="receiver_container">
                        <span>Receiver</span>
                        <div className="receiver">
                            <div className="username_img">
                                <img src={selectedUserImg ?? noImage}
                                    className="user_img"
                                    onError={(event) => {
                                        event.target.src = noImage;
                                    }} />
                                <span>{selectedUser?.username}</span>
                            </div>
                            <div className="receiver_address">
                                {shortCryptoAddress(selectedUser?.walletAddress)}
                            </div>
                        </div>

                    </div>
                    <div className="sending_amount">
                        {amount} {selectedTokenType === 'USDC' ? 'USDT' : 'MATIC'}
                    </div>
                    <div className="flex_between">
                        <Button onClick={() => setConfirm(false)} colorVariant="pink">
                            <span>Cancel</span>
                        </Button>
                        <Button onClick={() => handleSendToken()} colorVariant="green">
                            <span>Confirm</span>
                        </Button>
                    </div>
                </div>
                :
                <div className="send_token_container">
                    <div className="">
                        <div className="select_container_search">
                            <Select
                                id="send_token_user_search"
                                value={selected}
                                showSearch
                                labelInValue
                                filterOption={false}
                                options={options}
                                className="send_token_user_search"
                                onSearch={debounceFetcher}
                                fieldNames={{ label: 'username', value: '_id' }}
                                onChange={handleSelectChange}
                                placeholder='Type username or add address'
                                optionRender={(option) => {
                                    return (
                                        <Space>
                                            <span role="img" aria-label={option.label}>
                                                <img className="custom_option_img" src={get(option, 'data.img') || noImage}
                                                    onError={(event) => {
                                                        event.target.src = noImage;
                                                    }} />
                                            </span>
                                            {option.label}
                                        </Space>
                                    )
                                }}
                            />
                            {selectedUserImg ?
                                <img src={selectedUserImg ?? noImage}
                                    className="custom-suffix custom-suffix-img"
                                    onError={(event) => {
                                        event.target.src = noImage;
                                    }} /> :
                                <img src={MUserWhite} className="custom-suffix" />
                            }
                        </div>
                        <span className="selected_address">{selectedUser?.walletAddress}</span>
                    </div>
                    <div>
                        <div className=" input_and_select_container">
                            <input
                                placeholder="Amount to send"
                                type="number"
                                inputMode="decimal"
                                onChange={(e) => setAmount(e.target?.value)}
                                value={amount}
                            />

                            <div className="select-container">
                                <Select
                                    value={selectedTokenType}
                                    onChange={(value) => setSelectedTokenType(value)}
                                    options={[{ label: 'USDT', value: 'USDC' }, { label: 'MATIC', value: 'ETH' }]}
                                    className="antd__select"
                                    dropdownStyle={{
                                        maxHeight: '60vh',
                                        overflow: 'auto',
                                        backgroundColor: '#0D0D0E',
                                        border: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'rgba(255, 248, 248, 0.5)',
                                        zIndex: 2000
                                    }} />
                            </div>
                        </div>
                        <div className="info_text">You have {selectedTokenType === 'USDC' ? tokenBalance : balance} {TokenTypeMap[selectedTokenType]} left</div>
                    </div>
                    <Button disabled={!(selectedUser?._id && amount)}
                        onClick={() => {
                            // if (selectedTokenType === 'USDC')
                            setConfirm(true);
                            // else toast.error('Only USDT is allowed. Send other token feature coming soon.')
                        }}
                        colorVariant="cyan">
                        <span>Send</span>
                    </Button>
                </div>
            }
        </ModalContainer>)
}