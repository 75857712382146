import "./stapper.scss";
import React, { memo, useEffect, useState } from "react";
import { useWriteContract, useReadContract, useWaitForTransactionReceipt } from 'wagmi';
import Button from "../button/Button";
import { Link, useLocation } from "react-router-dom";
import { Chevron, Gallery, I } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesAction } from '../../store/actions/utilAction'
import { validateInputDateTimeFormat, isSecondDateAfterFirst, somethingWrong, TokenType, PlatformFee, delayForMS } from '../../util/helper'
import PossibleOutcomes from "../Popups/possibleOutcomes/PossibleOutcomes";
import moment from 'moment';
import { toast } from 'react-toastify';
import EditTimeline from "../Popups/Pop up Timeline/EditTimeline";
import SecreteEvent from "../infoPopups/secrentEvent/SecreteEvent";
import PrivateEvents from "../infoPopups/privateEvents/PrivateEvents";
import PublicEvents from "../infoPopups/publicEvents/PublicEvents";
import api from "../../util/api";
import { get } from "lodash";
import { eventContractInstanceAbi } from "../../abi/eventContractInstanceAbi";
import { keccak256, randomBytes } from 'ethers';
import { factoryContractAddress, tokenAddress, tokenEthAddress } from "../../config/web3AuthInstance";
import { useWeb3Auth } from "../../context/useWeb3auth";
import { Loader } from "../loader";
import { TRUE } from "sass";

const abiArray = eventContractInstanceAbi;


const StepOne = memo(() => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const cloneEventId = searchParams?.get('clone')

  const user = useSelector(state => state.user.user)
  const { balance: ethBalance } = useWeb3Auth()

  const { data: contractTransactionHash, isPending: isContractTransactionPending, error: contractTransactionError, isError: isContractTransactionError, writeContract: contractTransactionWriteContract } = useWriteContract();
  const { isLoading: isContractTransactionConfirming, isSuccess: isContractTransactionConfirmed, error: contractTransactionReceiptError, isError: isContractTransactionReceiptError } = useWaitForTransactionReceipt({ hash: contractTransactionHash })



  const [salt, setSalt] = useState()

  const { data: upcomingContractAddress
  } = useReadContract({
    abi: abiArray,
    address: factoryContractAddress,
    functionName: 'predictInstance',
    args: [salt]
  })

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedIn } = useWeb3Auth()
  const { categories } = useSelector((state) => state.util);
  const [formData, setFormData] = useState({
    name: "",
    description: "",

    privacy: "PUBLIC",

    endTime: null,
    decisionTime: null,

    proposal: true,

    fees: "",
    category: "",

    challenges: [],

    tokenType: "USDC", //ETH
    endTimeCreate: null,
    decisionTimeCreate: null

  });


  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({ endTime: "" });
  const [openOutcome, setOpenOutcome] = useState(false);
  const [challengeOptions, setChallengeOptions] = useState([])
  const [selectedChallenges, setSelectedChallenges] = useState([])
  const [showSelectTimeline, setShowSelectTimeline] = useState(false)
  const [editTimelineUsingFor, setEditTimelineUsingFor] = useState('')
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPrivate, setOpenPrivate] = React.useState(false);
  const handleOpenPrivate = () => setOpenPrivate(true);
  const handleClosePrivate = () => setOpenPrivate(false);

  const [openPublic, setOpenPublic] = React.useState(false);
  const handleOpenPublic = () => setOpenPublic(true);
  const handleClosePublic = () => setOpenPublic(false);


  useEffect(() => {
    if (Array.isArray(categories) && categories.length === 0)
      dispatch(fetchCategoriesAction())
  }, [])


  useEffect(() => {
    if (cloneEventId)
      fetchEventDetails()
  }, [cloneEventId])

  useEffect(() => {
    const salt = keccak256(
      `0x${randomBytes(256).reduce(
        (acc, val) => acc + val.toString(16).padStart(2, '0'),
        ''
      )}`
    ); // Generate a unique salt
    setSalt(salt)

  }, [])

  useEffect(() => {
    if (isContractTransactionError) {
      if (typeof contractTransactionError?.name === 'string' && contractTransactionError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else toast.error(contractTransactionError?.message)
    }
    else if (isContractTransactionReceiptError) {
      if (typeof contractTransactionReceiptError?.name === 'string' && contractTransactionReceiptError?.name?.includes('TransactionExecutionError'))
        toast.error('Please make sure your wallet has enough balance')
      else toast.error(contractTransactionReceiptError?.message)
    }

  }, [isContractTransactionError, isContractTransactionReceiptError])

  //After all blockchain event confirmed
  useEffect(() => {
    if (isContractTransactionConfirmed)
      handleClickFinalStep()
  }, [isContractTransactionConfirmed])

  async function fetchEventDetails() {
    try {
      setLoading(true)
      const response = await api.get(`event/${cloneEventId}`)
      const { name, description, privacy, fees, category, tokenType } = response?.data ?? {}
      setFormData({ name, description, privacy, fees, category, tokenType })
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors({ ...errors, [name]: "" });
    if (name === 'name') {
      if (value?.length <= 110)
        setFormData({
          ...formData,
          [name]: value,
        })
    }
    else
      setFormData({
        ...formData,
        [name]: value,
      })
  };

  const handleFormDataChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleFormDataChangeObject = (array) => {
    const updatedFormData = { ...formData };
    const updatedErrors = { ...errors };

    array.forEach(({ name, value }) => {
      updatedErrors[name] = ""; // Reset error for the field
      updatedFormData[name] = value; // Update form data
    });

    setErrors(updatedErrors);
    setFormData(updatedFormData);
  };

  const onSubmitChallenge = (values) => {
    const raw = [...challengeOptions]
    raw.push(values)
    setChallengeOptions(raw)
    handleSelectChange(null, [...formData?.challenges ?? [], values])
    setOpenOutcome(false)
  }

  const handleSelectChange = (_value, selected) => {
    setSelectedChallenges(selected.map((val) => val?.logic))

    setErrors({ ...errors, challenges: "" });
    setFormData({
      ...formData,
      challenges: selected
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(e.target.files[0]);
      setErrors({ ...errors, 'selectedFile': "" });
    } else {
      setSelectedFile(null);
    }
  };

  function handleFeesUpdate(increment) {
    if (increment) {
      const updatedFees = Number(get(formData, 'fees', 0)) + .5
      setFormData(prev => {
        return {
          ...prev,
          fees: updatedFees <= 5 ? updatedFees : 5
        }
      });
    } else {
      const updatedFees = Number(get(formData, 'fees', 0)) - .5
      setFormData(prev => {
        return {
          ...prev,
          fees: updatedFees >= 0 ? updatedFees : 0
        }
      });
    }
  }


  // validation
  const validateForm = () => {

    const errors = {};

    if (!formData.name) {
      errors.name = "Event name is required";
    }
    // if (!formData.description) {
    //   errors.description = "Description is required";
    // }
    // if (!selectedFile) {
    //   errors.selectedFile = "Event image is required";
    // }

    // if (!formData.startTime) {
    //   errors.startTime = "participate start time is required";
    // } else if (formData.startTime) {
    //   const isValidFormat = validateInputDateTimeFormat(formData.startTime);
    //   if (isValidFormat) {
    //     if (moment().isAfter(moment(formData.startTime, "DD/MM/YYYY HH:mm", true)))
    //       errors.startTime = "Please add a future date time";
    //   }
    //   else if (!isValidFormat)
    //     errors.startTime = "Please add a valid date time";
    // }

    if (!formData.endTime) {
      errors.endTime = "participate end time is required";
    } else if (formData.endTime) {
      const isValidFormat = validateInputDateTimeFormat(formData.endTime);
      if (isValidFormat) {
        if (!isSecondDateAfterFirst(moment(), formData?.endTime))
          errors.endTime = "End time should be future time";
      }
      else
        errors.endTime = "Please add a valid date time";
    }

    if (!formData.decisionTime) {
      errors.decisionTime = "Decision time is required";
    } else if (formData.decisionTime) {
      const isValidFormat = validateInputDateTimeFormat(formData.decisionTime);
      if (isValidFormat) {
        if (!isSecondDateAfterFirst(formData?.endTime, formData?.decisionTime))
          errors.decisionTime = "Decision time should be greater than end date";
      } else
        errors.decisionTime = "Please add a valid date time";
    }


    // if (!formData.category) {
    //   errors.category = "Category is required";
    // }

    // if (!(Array.isArray(formData.challenges) && formData.challenges[0]?.title)) {
    //   errors.challenges = "Challenges is required";
    // }

    if (formData.fees) {
      const feesNumber = Number(formData.fees)
      if (feesNumber < 0 || feesNumber > 5)
        errors.fees = "Fees should be between 0.0% to 5%"
    }

    return errors;
  };

  async function handleCreateEventContractTransaction() {
    const endTimeCreate = moment().diff(formData?.endTimeCreate, 'seconds');
    const decisionTimeCreate = moment().diff(formData?.decisionTimeCreate, 'seconds');
    const array = []

    if (formData?.endTime) {
      array.push({ name: "endTime", value: moment(formData?.endTime).add(endTimeCreate, 'seconds') }, { name: "endTimeCreate", value: moment() })
    }
    if (formData?.decisionTime) {
      array.push({ name: "decisionTime", value: moment(formData?.decisionTime).add(decisionTimeCreate, 'seconds') }, { name: "decisionTimeCreate", value: moment() })
    }

    handleFormDataChangeObject(array)
    await delayForMS()
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      if (!loggedIn)
        return toast.error('Please login first to create event')
      if (user?.admin?.playerOnly) {
        return toast.error(`You've been blocked by the admin from creating event.`)
      }
      if (ethBalance <= 0)
        return toast.error('Low MATIC balance! Your account does not have enough MATIC balance to pay gas fees')


      toast.info('Minting event contract on blockchain. Please wait!')

      // Prepare parameters for createInstance function
      const { endTime, decisionTime, fees } = formData
      const engagementDeadline = endTime.unix(); // Example: Engagement deadline 1 hour from now
      const resolutionDeadline = decisionTime.unix(); // Example: Resolution deadline 2 hours from now
      const creatorFee = fees || (fees > 0) ? fees * 100 : 0

      // Call the writeContractAsync function with contract address, ABI, function name, and arguments
      contractTransactionWriteContract({
        address: factoryContractAddress,
        abi: abiArray,
        functionName: 'createInstance',
        args: [
          salt,
          formData?.tokenType === TokenType.ETH ? tokenEthAddress : tokenAddress,
          engagementDeadline,
          resolutionDeadline,
          creatorFee
        ]
      });

    } else {
      setErrors(validationErrors);
      toast.info('Please fill form properly')
      return;
    }
  }

  const handleClickFinalStep = async () => {

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoading(true)

        const reqFormData = new FormData();
        for (const [key, value] of Object.entries(formData)) {
          if (key === 'challenges')
            reqFormData.set(key, JSON.stringify(value))
          else if (key === 'endTime' || key === 'decisionTime')
            reqFormData.set(key, moment(value).format())
          else if (key === 'fees') {
            if (value)
              reqFormData.set(key, value);
          } else if (key === 'category') {
            if (value)
              reqFormData.set(key, value)
          } else
            reqFormData.set(key, value);
        }

        //assigning startTime
        reqFormData.set('startTime', moment().format());

        const blockChain = {}
        if (contractTransactionHash || upcomingContractAddress) {
          if (contractTransactionHash)
            blockChain.contractTransactionHash = contractTransactionHash
          if (upcomingContractAddress)
            blockChain.contractAddress = upcomingContractAddress

          reqFormData.set('blockChain', JSON.stringify(blockChain))
        }

        if (selectedFile) {
          reqFormData.set('img', selectedFile);
        }

        const event = await api.post(`event`, reqFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        toast.success("Event created successfully.");
        navigate(`/event/${event?.data?._id}/play`, { state: event?.data })

      } catch (error) {
        toast.error(somethingWrong);
      } finally {
        setLoading(false)
      }
    } else {
      setErrors(validationErrors);
      toast.info('Please fill form properly')
      return;
    }
  };

  const publicTab = formData?.privacy

  return (
    <>
      <Loader loading={loading || isContractTransactionPending || isContractTransactionConfirming} />
      <form className="stapper__form" >

        <div className="input__groups">
          <label className="step_one_label"> Name your event </label>
          <span className="optional_absolute">{formData?.name?.length}/110 Char</span>
          <input
            type="text"
            placeholder="Golf event against Micheal Jordan"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            autoFocus
          />
          {errors.name && (
            <span className="error_email">{errors.name}</span>
          )}
        </div>

        <div className="input__groups">
          <label className="step_one_label"> Description </label>
          <span className="optional_absolute">Optional</span>
          <textarea
            type="text"
            placeholder="This is my description..."
            cols={9}
            rows={7}
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
          {errors.description && (
            <span className="error_email">{errors.description}</span>
          )}
        </div>

        <div className="input__groups" >
          {/* <label className="step_one_label"> Timeline creation </label> */}

          <div className="choose_timeline_button"
            onClick={() => setShowSelectTimeline(true)}>
            {(formData?.endTime || formData?.decisionTime)
              ?
              <div className=" w_full flex justify_around text_black font_medium">
                <div onClick={() => setEditTimelineUsingFor('endTime')} className="bg_cyan badge pointer">{formData?.endTime ? moment(formData?.endTime).format('DD/MM/YY HH:mm') : 'DD/MM/YY HH:MM'}</div>
                <div onClick={() => setEditTimelineUsingFor('decisionTime')} className="bg_pink badge pointer">{formData?.decisionTime ? moment(formData?.decisionTime).format('DD/MM/YY HH:mm') : 'DD/MM/YY HH:MM'}</div>
              </div>
              :
              <div>Choose my timeline</div>}
          </div>

          {errors.endTime && (
            <span className="error_email">{errors.endTime}</span>
          )}
          {errors.decisionTime && (
            <span className="error_email">{errors.decisionTime}</span>
          )}
        </div>

        <div className="public__private">
          <div className="box__tabs">
            <Link
              to=""
              onClick={() => handleFormDataChange('privacy', "SECRET")}
              className={publicTab === "SECRET" ? "active" : ""}
              style={{ position: "relative" }}
            >
              <img
                src={I}
                alt=""
                style={{ position: "absolute", right: "6px" }}
                onClick={handleOpen}
              />
              Secret
            </Link>
            <Link
              to=""
              onClick={() => handleFormDataChange('privacy', "PRIVATE")}
              className={publicTab === "PRIVATE" ? "active" : ""}
              style={{ position: "relative" }}
            >
              <img
                src={I}
                alt=""
                style={{ position: "absolute", right: "3px" }}
                onClick={handleOpenPrivate}
              />
              Private
            </Link>
            <Link
              to=""
              onClick={() => handleFormDataChange('privacy', "PUBLIC")}
              className={publicTab === "PUBLIC" ? "active" : ""}
              style={{ position: "relative" }}
            >
              <img
                src={I}
                alt=""
                style={{ position: "absolute", right: "6px" }}
                onClick={handleOpenPublic}
              />
              Public
            </Link>
          </div>
        </div>

        <div className="file_input">
          <span className="optional">Optional</span>
          <img src={Gallery} alt="" className="img_gal" />
          <label htmlFor="fileInput">
            {selectedFile ? <p>{selectedFile?.name}</p> : <p> Add image </p>}
          </label>
          <input
            type="file"
            id="fileInput"
            placeholder="Image"
            onChange={handleFileChange}
            accept="image/*"
          />
          {errors.selectedFile && (
            <span className="error_email">{errors.selectedFile}</span>
          )}
        </div>

        <div className="input__groups">
          <label className="step_one_label_optional"> Optional </label>
          <div className="fees_input_container">
            <div className=" counter_arrow_container">
              <img onClick={() => handleFeesUpdate(true)} src={Chevron} alt="" className="counter_arrow_img rotate_up" />
              <img onClick={() => handleFeesUpdate(false)} src={Chevron} alt="" className="counter_arrow_img" />
            </div>
            <input
              className="fees_input"
              type="number"
              placeholder="Fees : 0.0% to 5%"
              name="fees"
              min={0}
              max={5}
              value={formData.fees}
              onChange={(e) => {
                const { value } = e.target
                if (!isNaN(value) && value >= 0 && value <= 5) {
                  handleInputChange(e)
                }
              }}
            />
            <hr className="hr_separator" />
            <div className="total_fees_container">
              {Number(get(formData, 'fees', 0)) + PlatformFee} % fee
            </div>
          </div>
          <div className="flex justify_between gap_1rem">
            {errors.fees ? (
              <span className="error_email">{errors.fees}</span>
            ) : <div></div>}
            <div className="fees_info ">
              Total fees paid by participants
              <br />
              (includes Makao fees)
            </div>
          </div>
        </div>

        <div className="input_group_name">
          <label className="step_one_label"> Category </label>
          <span className="optional_absolute">Optional</span>
          <select
            name="category"
            value={formData?.category}
            onChange={handleInputChange}

          >
            <option value="" >Select option</option>
            {Array.isArray(categories) && categories?.map((val, ind) => (<option key={ind} value={val?._id}>{val?.title}</option>))}
          </select>
          {errors?.category && (
            <span className="error_email">{errors?.category}</span>
          )}
        </div>

        <div className=" token_select_container">
          <div
            onClick={() => handleFormDataChange('tokenType', "USDC")}
            className={`pointer token_box ${formData?.tokenType === 'USDC' ? 'active' : ''}`}>
            USDT
          </div>
          <div
            onClick={() => handleFormDataChange('tokenType', "ETH")}
            className={`pointer token_box ${formData?.tokenType === 'ETH' ? 'active' : ''}`}>
            MATIC
          </div>
        </div>

        <Button
          disabled={loading || isContractTransactionPending || isContractTransactionConfirming}
          className="create__event"
          onClick={handleCreateEventContractTransaction}
          type="button"
          colorVariant="green"
        >
          Create event
        </Button>
      </form>


      <PossibleOutcomes
        handleClose={() => setOpenOutcome(false)}
        open={openOutcome}
        buttonText="Add"
        onSubmitForm={onSubmitChallenge}
      />


      <EditTimeline
        open={showSelectTimeline}
        handleClose={() => setShowSelectTimeline(false)}
        handleDateChange={handleFormDataChange}
        data={formData}
        editTimelineUsingFor={editTimelineUsingFor}
        handleFormDataChangeObject={handleFormDataChangeObject}
        endTimeData={formData?.endTime}
      />


      <SecreteEvent handleClose={handleClose} open={open} />
      <PrivateEvents handleClose={handleClosePrivate} open={openPrivate} />
      <PublicEvents handleClose={handleClosePublic} open={openPublic} />
    </>
  );
});

export default StepOne;
