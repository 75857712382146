import React, { useEffect, useState } from "react";
import BlockListUser from "../blockListUser/BlockListUser";
import { Loader } from "../loader";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserAction } from "../../store/actions/userAction";

const BlackList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const blacklistedUsers = useSelector(state=> state.user.user?.blacklistedUsers) ?? [] ;
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 500);
    dispatch(fetchUserAction()) // fetching user details here also.
  }, []);

  return (
    <>
      <Loader loading={isLoading} />

      {blacklistedUsers.map((blacklistUser) => (
        <BlockListUser blacklistUser={blacklistUser} />
      ))}
    </>
  );
};

export default BlackList;
