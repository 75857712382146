import React, { useState, useEffect } from "react";
import "./editeventLinks.scss";
import { Box, Modal, Typography } from "@mui/material";
import { Close, Del, EventAdd } from "../../../assets/images";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { somethingWrong } from "../../../util/helper";
import Button from "../../button/Button";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const LinkComponent = ({ setValue }) => {
    const [formData, setFormData] = useState({
        name: '',
        link: '',
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
        setValue(prev => ({ ...prev, [name]: value }))
    }
    return (
        <div style={{ marginTop: 'auto', padding: 4 }}>
            <div className="input_group">
                <label> Link Name </label>
                <input
                    type="text"
                    name='name'
                    placeholder="Link Name"
                    value={formData?.name}
                    onChange={handleChange}
                    autoFocus
                />
                <label> Link Address </label>
                <input
                    type="text"
                    placeholder="www.link.com"
                    name="link"
                    value={formData?.link}
                    onChange={handleChange}
                />
            </div>
        </div>
    )
}
const EditEventLinks = ({ handleClose, open, data, handleAddLinks, handleDeleteLinks }) => {
    const [links, setLinks] = useState([])
    const [linklist, setLinkList] = useState([])
    const [value, setValue] = useState({
        name: '',
        link: '',
    })
    const isDisabled = !value.name || !value.link;
    const handleSave = async () => {
        const urlRegex = /^https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(?::\d{1,5})?(?:\/[^\s]*)?$/;
        try {
            if (links.length) {
                if (urlRegex.test(value.link)) {
                    handleAddLinks(value)
                    setLinks([])
                    setValue({
                        name: '',
                        link: '',
                    })
                    toast.success('Link added successfully.')
                } else {
                    toast.error('Invalid link.')
                }
            } else {
                toast.error('Please fill the fields.')
            }
        } catch (ex) {
            toast.error(ex?.message ?? somethingWrong)
        }
    }
    const handleDelete = (indexToDelete) => {
        setLinkList(prevLinkList => {
            const updatedLinkList = [...prevLinkList];
            updatedLinkList.splice(indexToDelete, 1);
            return updatedLinkList;
        });
    };
    useEffect(() => {

    }, [linklist])
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="popup">
                    <div className="image-container">
                        <img src={Close} alt="Image" onClick={handleClose} />
                    </div>
                    <Typography
                        id="modal-modal-title"
                        className="popup_heading"
                        variant="h6"
                        component="h2"
                    >
                        Edit links
                    </Typography>
                    <div className={` mt_16 link_modal_container`}>
                        {
                            data?.links?.length === 0 && <div style={{ color: 'white' }}>
                                No link added yet !
                            </div>
                        }
                        {
                            data?.links?.map((item, index) => (
                                <div className="link_container">
                                    <div className="link_action_container">
                                        <p className="link_action_container_name">Link {index + 1}</p>
                                        <div style={{ cursor: 'pointer' }} onClick={() => handleDeleteLinks(item?._id)}>
                                            <ReactSVG src={Del} className="svg_container" />
                                        </div>

                                    </div>
                                    <div className="link_name_container">
                                        {item.name}
                                    </div>
                                    <div></div>
                                    <div className="link_container">
                                        {item.link}
                                    </div>
                                </div>
                            ))
                        }
                        {
                            links?.map((item, index) => (
                                <>{item}</>
                            ))
                        }
                    </div>

                    <div className="create_container" onClick={() => links.length !== 1 && setLinks(prev => [...prev, <LinkComponent key={prev.length} values={value} setValue={setValue} />])}>
                        <div className='image_container' >
                            <ReactSVG src={EventAdd} className="svg_container" />
                        </div>
                        <p style={{ color: 'white', padding: '4px 0px 4px 0px' }}>
                            Create a new Link
                        </p>
                    </div>
                    <Button onClick={handleSave} disabled={isDisabled} colorVariant='cyan'>Save</Button>
                </Box>
            </Modal>
        </>
    );
};

export default EditEventLinks;
