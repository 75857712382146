import React, { useEffect, useMemo, useState } from "react";
import "../profile/profile.scss";
import PlayersPopup from "../Popups/players/PlayersPopup";
import PotentialWin from "../infoPopups/PotentialWIn/PotentialWin";
import { useDispatch, useSelector } from "react-redux";
import MyGamesContainer from "./myGamesContainer";
import { fetchUserEventsAction, fetchUserOrganisedEvent } from "../../store/actions/eventAction";
import { fetchUserAction } from "../../store/actions/userAction";
import { ReactSVG } from "react-svg";
import { Graph, Heartbeat } from "../../assets/images";
import { BadgeOdd } from "../badge";
import { get } from "lodash";
import { formatNumber } from "../../util/helper";
import api from "../../util/api";
import { useWeb3Auth } from "../../context/useWeb3auth";
import { useReadContract } from "wagmi";
import { tokenAddress } from "../../config/web3AuthInstance";
import { tokenAbi } from "../../abi/tokenAbi";

const MyGamesEventInfo = ({ renderLeftProfile, otherUserData, className }) => {
  const dispatch = useDispatch();
  const { address } = useWeb3Auth()

  const {
    user: {
      organised: userOrganisedEvents,
      current: userCurrentEvents,
      history: userHistoryEvents,
    },
    status,
  } = useSelector((state) => state.event);
  const userId = useSelector((state) => state.user?.user?._id);

  const [tabHistory, setTabHistory] = useState("History");
  const handleClickHistory = (type) => {
    setTabHistory(type);
  };

  const [tabSat, setTabSat] = useState("4.9/5");
  const handleClickSat = (type) => {
    setTabSat(type);
  };

  const [tab, setTab] = useState("history");
  const handleClick = (type) => {
    setTab(type);
  };

  const [claimList, setClaimsList] = useState([])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPotential, setOpenPotential] = React.useState(false);
  const handleOpenPotemtial = () => setOpenPotential(true);
  const handleClosePotemtial = () => setOpenPotential(false);

  const [openLink, setOpenLink] = React.useState(false);
  const handleOpenLink = () => setOpenLink(true);
  const handleCloseLink = () => setOpenLink(false);

  const { data: tokenBalance } = useReadContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName: 'balanceOf',
    args: [address]
  });

  useEffect(() => {
    dispatch(fetchUserOrganisedEvent({ type: 'ORGANISED', pageSize: 100, otherUserId: otherUserData?.otherUserId ?? '' }))
    dispatch(fetchUserEventsAction({ type: 'CURRENT', pageSize: 100, otherUserId: otherUserData?.otherUserId ?? '' }))
    dispatch(fetchUserEventsAction({ type: 'HISTORY', pageSize: 100, otherUserId: otherUserData?.otherUserId ?? '' }))
  }, [])

  useEffect(() => {
    if (!userId) {
      dispatch(fetchUserAction())
    } else if (userId) {
      fetchClaimsAmount()
    }
  }, [userId])

  async function fetchClaimsAmount() {
    try {
      const res = await api.get(`/event/claims`);
      setClaimsList(res)
    } catch (ex) {
      console.log(ex?.message)
    }
  }

  const positionsInfoDetails = useMemo(() => {
    let amount = 0, odd = 0, potentialWin = 0, changePercentage = 0, playsCount = 0
    if (Array.isArray(userCurrentEvents)) {
      for (let event of userCurrentEvents) {
        if (Array.isArray(event?.plays) && event?.plays.length) {
          for (let play of event?.plays) {
            const challengeOdd = get(play, 'challenge[0].odd', 0)
            const playAmount = get(play, 'totalAmount', 0)
            amount += playAmount
            odd += challengeOdd
            changePercentage += get(play, 'challenge[0].changePercentage', 0)
            playsCount += 1
            potentialWin += (challengeOdd * playAmount) - playAmount
          }
        }
      }
    }
    return {
      amount, potentialWin, odd: potentialWin / amount, changePercentage: changePercentage / playsCount
    }
  }, [userCurrentEvents]);

  const walletInfoDetails = useMemo(() => {
    let amount = 0, claimableAmount = 0
    if (Array.isArray(claimList)) {
      for (let claim of claimList) {
        amount += get(claim, 'amount', 0)
        claimableAmount += get(claim, 'amount', 0)
      }
    }

    if (tokenBalance)
      amount += Number(tokenBalance) / 1000000

    return {
      amount,
      claimableAmount
    }
  }, [claimList, tokenBalance]);

  return (
    <>
      <PlayersPopup handleClose={handleClose} open={open} />
      <PotentialWin
        handleClose={handleClosePotemtial}
        open={openPotential}
      />
      {typeof renderLeftProfile === "function" ? (
        renderLeftProfile(
          tab,
          openLink,
          tabSat,
          handleOpenLink,
          handleClickSat,
          handleCloseLink,
          <MyGamesContainer
            className={className}
            tab={tab}
            handleClick={handleClick}
            handleOpen={handleOpenPotemtial}
            handleOpenLink={handleOpenLink}
            userCurrentEvents={userCurrentEvents}
            userHistoryEvents={userHistoryEvents}
            userOrganisedEvents={userOrganisedEvents}
            otherUserData={otherUserData}
          />
        )
      ) : (
        <>
          <div className="events_header_info_container">
            <div className="wallet_box header_info_box_common">
              <div className="title">
                <ReactSVG src={Heartbeat} className="svg_container" />
                <span>Positions</span></div>
              <div className="currency">${formatNumber(walletInfoDetails?.amount)}</div>
              <div className="status">+${walletInfoDetails?.claimableAmount ?? 0} ({walletInfoDetails?.claimableAmount ? (walletInfoDetails?.claimableAmount / walletInfoDetails?.amount) / 100 : '0%'})</div>
            </div>
            <div className="positions_box header_info_box_common">
              <div className="title">
                <span>Potential return</span>
                <ReactSVG src={Graph} className="svg_container" />
              </div>
              <div className="currency">
                <span>${formatNumber(positionsInfoDetails?.amount)}</span>
                <BadgeOdd odd={positionsInfoDetails?.odd ? formatNumber(positionsInfoDetails?.odd) : 0} changePercentage={positionsInfoDetails?.changePercentage} className="w_fit" />
              </div>
              <div className="status">Potential return: ${formatNumber(positionsInfoDetails?.potentialWin)}</div>
            </div>
          </div>
          <MyGamesContainer
            className={className}
            tab={tab}
            handleClick={handleClick}
            handleOpen={handleOpenPotemtial}
            handleOpenLink={handleOpenLink}
            userCurrentEvents={userCurrentEvents}
            userHistoryEvents={userHistoryEvents}
            userOrganisedEvents={userOrganisedEvents}
          />
        </>
      )}
    </>
  );
};

export default MyGamesEventInfo;
