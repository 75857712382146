import React from "react";
import { Typography } from "@mui/material";
import "./timeLine.scss";
import moment from "moment";
import { ModalContainer } from "../../modalContainer";
import { TimelineCounterButton } from "../../stapper/TimelineCounterButton";

const PopupTimeline = ({ handleClose, open, data }) => {
  const { endTime, decisionTime, status, decisionTakenTime } = data ?? {}

  return (
    <ModalContainer
      open={open}
      handleClose={handleClose}
      heading={`Timeline`}>

      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className=" mt_32">
          <TimelineCounterButton
            status={status}
            endTime={endTime}
            decisionTime={decisionTime}
            decisionTakenTime={decisionTakenTime}
            className={`h_41`}
          />
          {
            status === 'DEFAULT' ?
              <div className={`font_xm font_semibold mt_2 ${moment().isAfter(endTime) ?
                'text_cyan' : moment().diff(endTime, 'minute') >= -60 ?
                  'text_pink' : 'text_neon_green'}`}>{moment().isBefore(endTime) ? 'Remaining participation time' : moment().isBefore(decisionTime) ? 'Decision deadline' : ''}</div>
              : <></>
          }
        </div>

        <div className="mt_32">
          <div className="flex_between mb_8 font_semibold">
            <div className=" font_sm text_neon_green">Participation deadline</div>
            <div className="font_xm bg_dark_green badge text_white pointer">{endTime ? moment(endTime).format('DD/MM/YY HH:mm') : 'DD/MM/YY HH:MM'}</div>
          </div>
          <div className="text_white font_xxs text_left">After this time, participants can't participate anymore. The odds are fixed and won't change because no further participate is allowed.</div>
        </div>

        <div className="mt_16">
          <div className="flex_between mb_8 font_semibold">
            <div className=" font_xm text_cyan">Decision deadline</div>
            <div className="font_xm bg_light_gray badge text_white pointer">{decisionTime ? moment(decisionTime).format('DD/MM/YY HH:mm') : 'DD/MM/YY HH:MM'}</div>
          </div>
          <div className="text_white font_xxs text_left">If the organizer doesn't settle the event, all participants are automatically refunded after the deadline.</div>
        </div>
      </Typography>

    </ModalContainer>
  );
};

export default PopupTimeline;
