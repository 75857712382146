import React from "react";
import "./screens.scss";
import { Link } from "react-router-dom";

const Screens = () => {
  return (
    <>
      <div className="screens">
        <Link to="/connect-wallet-or-create">
          <button> Connect wallet or Create wallet </button>
        </Link>
        <Link to="/dashboard">
          <button> Discover/connect wallet </button>
        </Link>
        <Link to="/e-sports-events">
          <button> See all and categories button(E-sports event) </button>
        </Link>
        <Link to="/scan-QR">
          <button> Scan QR code </button>
        </Link>
        <Link to="/more-for-this-event">
          <button> More for this event </button>
        </Link>
        <Link to="/event/eventid">
          <button> Event page </button>
        </Link>
        <Link to="/finished-event">
          <button>Finished Event(As seen by everyone but the organize)</button>
        </Link>
        <Link to="/organiser-see">
          <button>How the Organisers see the page during the event</button>
        </Link>
        <Link to="/how-organiser-see">
          <button>
            How the organiser sees the page at the end of the event
          </button>
        </Link>
        <Link to="/create-event">
          <button> Create event 1/2 </button>
        </Link>
        <Link to="/events">
          <button> Current participation , Participation history, Organised event </button>
        </Link>
        <Link to="/profile">
          <button>
            Profile (pic, follow, trust note, )/ organised participates, Profile (pic,
            follow, trust note, ), My Profile (pic, follow, trust note, )
          </button>
        </Link>
        <Link to="/edit-profile">
          <button> Edit profile </button>
        </Link>
        <Link to="/profile-stats">
          <button> My profile stats </button>
        </Link>
        <Link to="/notification">
          <button> Notification </button>
        </Link>
        <Link to="/notification-settings">
          <button> Notification settings </button>
        </Link>
        <Link to="/leaderboards">
          <button> Leaderboard </button>
        </Link>
        <Link to="/deposit">
          <button>
            Wallet page (deposit, withdrawal, staking, dividends (Have to be
            claimed everyweek)
          </button>
        </Link>
        <Link to="/adminstrator">
          <button> Platform administrator page /Manage users </button>
        </Link>
        {/* <Link to="/current-games">
          <button> Administrator page / manage current games </button>
        </Link> */}
        {/* <Link to="/special-users">
          <button> Administrator page /spécial users </button>
        </Link> */}
        {/* <Link to="/categories">
          <button> Administrator page /Manage categories </button>
        </Link> */}
        <Link to="/block-list">
          <button> Manage blacklist users </button>
        </Link>
      </div>
    </>
  );
};

export default Screens;
