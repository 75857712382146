import './tabs.scss'

export function TabsComponent({ setTab, currentTab, sizeVariant = "", className = '', tabsList = [{ name: 'Volume', value: 'volume' }, { name: 'Odds', value: 'odd' }] }) {
    return (
        <div className={` custom_tabs_container ${sizeVariant} ${className}`}>
            {
                tabsList?.map((val, ind) => (
                    <div onClick={() => setTab(val?.value)} className={` tab pointer ${currentTab === val?.value ? 'active' : ''}`} key={ind} > {val?.name}</div>
                ))
            }
        </div>
    )
}