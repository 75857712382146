import React, { useLayoutEffect } from "react";
import ReviewModel from "./reviewModel";

const ReviewModelContainer = ({
  eventListForSplice,
  eventReviewList,
  reviewList,
  currentIndex,
  currentPage,
  feedback,
  showLink,
  link,
  onSubmitReview,
  setFeedback,
  setShowLink,
  setLink,
  handleFileChange,
  onNext,
}) => {
  const currentItem = reviewList ? reviewList[currentIndex] : null;
  const currentEventItem = eventReviewList ? eventReviewList[currentIndex] : null;
  const deleteEventIndexList = [];

  useLayoutEffect(()=>{

    return ()=>{

      if ((eventReviewList && Array.isArray(eventReviewList)) ) {
        for (let index = 0; index < eventListForSplice.length; index++) {
          const element = eventListForSplice[index];
    
          const sameEventChallenges = eventReviewList.filter((event)=>event.eventId===element.eventId)
          const remainingReviewChallenges = sameEventChallenges.filter(challenge=>!('reviewedChallenge' in challenge))
    
          if (remainingReviewChallenges.length === 0){
            const indexToDelete = eventListForSplice.findIndex(elem=>elem.eventId===element.eventId)
            indexToDelete !== -1 && deleteEventIndexList.push(indexToDelete)
    
          }
        }
      }
      
      Array.isArray(eventListForSplice) && deleteEventIndexList.map((i,ind)=>eventListForSplice.splice(i-ind,1))
    }
  })

  return (
    <>
      {currentItem && (
        <ReviewModel
          currentReviewData={currentItem}
          feedback={feedback}
          link={link}
          showLink={showLink}
          currentPage={currentPage}
          onSubmitReview={onSubmitReview}
          setShowLink={setShowLink}
          setFeedback={setFeedback}
          setLink={setLink}
          handleFileChange={handleFileChange}
          onNext={onNext}
        />
      )}
      {currentEventItem &&
        <ReviewModel
          currentReviewData={currentEventItem}
          feedback={feedback}
          link={link}
          showLink={showLink}
          currentPage={currentPage}
          onSubmitReview={onSubmitReview}
          setShowLink={setShowLink}
          setFeedback={setFeedback}
          setLink={setLink}
          handleFileChange={handleFileChange}
          onNext={onNext}
        />
      }
    </>
  );
};

export default ReviewModelContainer;
