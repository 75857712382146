import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchOrganisersLeaderboardAction } from "../../../store/actions/eventAction";
import { Dollar, noImage, noImageProfile } from "../../../assets/images";
import { formatNumber } from "../../../util/helper";
import { useWeb3Auth } from "../../../context/useWeb3auth";
import { HeadingAndViewAll } from "./HeadingAndViewAll";

export function OrganiserLeaderboard() {
    const { loggedIn } = useWeb3Auth()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { organisers, status } = useSelector((state) => state.event.organisersLeaderboard);


    useEffect(() => {
        dispatch(fetchOrganisersLeaderboardAction({ page: 1, pageSize: 20 }))
    }, [loggedIn])

    return <div className="leaderboards" style={{ paddingBottom: "0" }}>
        <HeadingAndViewAll title={'Organisers Leaderboard'} onClick={() => navigate("/leaderboards")} />

        <div className="leaderboards__content">
            <div className="main__grid">
                {organisers.map((val, ind) => (
                    <div onClick={() => navigate(`/profile/${val?._id}`)} className="box__down cursor__pointer" key={ind}>
                        <div className="left">
                            <span className="leader">{ind + 1}<span className="leader_place">{`${ind === 0 ? 'st' : ind === 1 ? 'nd' : ind === 2 ? 'rd' : 'th'}`}</span></span>
                            {/* <img src={val.imgPosition} alt="img" /> */}
                            <span>
                                <img src={val?.img ?? noImageProfile} alt="img"
                                    onError={(event) => {
                                        event.target.src = noImageProfile;
                                    }} />
                                <p> {val.username} </p>
                            </span>
                        </div>
                        <div className="right" >
                            <img src={Dollar} alt="" />
                            <div>{formatNumber(val?.totalVolume)}</div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    </div>
}