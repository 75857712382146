import React from "react";
import "./blockUser.scss";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { unblacklistUserAction } from "../../store/actions/userAction";

const BlockListUser = ({ blacklistUser }) => {

  const dispatch = useDispatch();

  const handleUnblockUser = () => {
    dispatch(unblacklistUserAction({ blacklistedUserId: blacklistUser._id }))
    toast.info(`${blacklistUser.username} is unblacklisted successfully.`)
  }

  return (
    <div className="block_container">
      <div className="box__one">
        <div className="box__one__left">
          <img src={blacklistUser.img} alt="" />
          <p> {blacklistUser.username}</p>
        </div>
        <div className="box__one__right" onClick={handleUnblockUser}>
          <span className="span_one">
            <p> UBL </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BlockListUser;
