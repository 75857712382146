import { createSlice } from '@reduxjs/toolkit'
import { fetchCategoriesAction } from '../actions/utilAction'

const initialState = {
  categories: [],
  status: 'idle',
}

export const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategoriesAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategoriesAction.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
})

export const { } = utilSlice.actions

export default utilSlice.reducer