import { Box, Modal } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { toast } from "react-toastify";
import { Close } from "../../../assets/images";
import api from "../../../util/api";
import { EventType, somethingWrong } from "../../../util/helper";
import { Loader } from "../../loader";
import { ClaimAllWinnings } from "./ClaimAllWinnings";
import "./review.scss";
import ReviewAndClaim from "./ReviewAndClaim";
import ReviewModelContainer from "./reviewModelContainer";
import { useWeb3Auth } from "../../../context/useWeb3auth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ReviewDecision = ({
  currentPage = "EVENT",
  eventId,
  allEventsChallengesList,
  allEventsChallengesReviewList,
  handleClose,
  open,
  setIsReviewGiven,
  blockChain = {},
  handleClaimSuccess,
  eventStatus
}, ref) => {
  const { loggedIn } = useWeb3Auth()
  const [show, setShow] = useState("LIST"); //REVIEW //CLAIM
  const [link, setLink] = useState("");
  const [showLink, setShowLink] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [challengesList, setChallengesList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [claimsList, setClaimsList] = useState([]);

  useEffect(() => {
    (async function () {
      if (currentPage === "EVENT" && eventId && loggedIn) {
        const { data: challengeData } = await api.get(
          `event/reviewEventChallenges/${eventId}`
        );
        setChallengesList(challengeData);
      }
    })();
  }, [eventId, currentPage, loggedIn]);

  useEffect(() => {
    (async function fetchUnclaimedClaims() {
      try {
        if (loggedIn) {
          setLoading(true);
          const res = await api.get(`/event/claims/${eventId ? eventId : ""}`);
          if (res?.data) setClaimsList(res?.data);
        }
      } catch (ex) {
        toast.error(ex?.message ?? somethingWrong);
      } finally {
        setLoading(false);
      }
    })();
  }, [eventId, loggedIn]);

  useEffect(() => {
    if (eventStatus && eventStatus === EventType.refund)
      setShow('CLAIM')
    else 
      setShow('LIST')
  }, [eventStatus])

  useImperativeHandle(ref, () => ({
    challengesList,
    claimsList
  }), [challengesList, claimsList]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const onSubmitReview = async ({ userReview, challengeId, event_id }) => {
    try {
      setLoading(true)
      console.log(`review for current_eventId ${eventId === event_id ? '\x1b[1;92;2;42m' : '\x1b[1;91;2;41m'}${eventId}\x1b[0m , review_event_id \x1b[1;92;2;42m${event_id}\x1b[0m and challengeId ${challengeId}`)
      const reqFormData = new FormData();
      reqFormData.set("eventId", event_id);
      reqFormData.set("challengeId", challengeId);
      reqFormData.set("review", userReview);
      if (feedback) reqFormData.set("feedback", feedback);
      if (link) reqFormData.set("link", link);
      if (selectedFile) {
        reqFormData.set("img", selectedFile);
      }

      const { data } = await api.post("review", reqFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Review submitted successfully", userReview);

      typeof setIsReviewGiven === "function" && setIsReviewGiven(true);
      setSelectedFile(null);
      setFeedback("");
      setLink("");
      setShowLink(false);
    } catch (err) {
      toast.error(err?.message ?? somethingWrong);
    } finally {
      Array.isArray(allEventsChallengesReviewList) && allEventsChallengesReviewList.every(reviewChallenge => ('reviewedChallenge' in reviewChallenge)) && setShow('CLAIM');
      setLoading(false)
    }
  };

  function handleClaimAllSuccess() {
    handleClaimSuccess?.()
    setShow(eventStatus === EventType.refund ? "CLAIM" : "LIST");
    handleClose(false);
    setClaimsList([]) // this will not let show claimList in ClaimAllWinnings popup once claims are successfully claimed.
  }

  const allChallengesReviewed = (challengesList) => challengesList.every(challenge => ('reviewedChallenge' in challenge))
  const sortChallengesReviewed = (challengesList) => challengesList.sort((a, b) => 'reviewedChallenge' in a ? -1 : 1)

  const resetIndex = (challengesList) => {
    sortChallengesReviewed(challengesList);
    for (let index = 0; index < challengesList.length; index++) {
      const element = challengesList[index];
      if (!("reviewedChallenge" in element)) {
        return index;
      }
    }
  };

  const returnCurrentIndex = (prevIndex, challengesList) =>
    prevIndex === challengesList.length - 1
      ? allChallengesReviewed(challengesList)
        ? setShow("CLAIM")
        : !handleClose(false) && resetIndex(challengesList)
      : prevIndex + 1;

  const onNext = () => {
    setCurrentIndex((prevIndex) => {
      const returnedIndex =
        returnCurrentIndex(
          prevIndex,
          allEventsChallengesReviewList &&
            allEventsChallengesReviewList?.length > 0
            ? allEventsChallengesReviewList
            : challengesList
        ) ?? 0;
      return returnedIndex;
    });
  };

  const removeReviewChallengeFromEventReviewList = (eventReviewList) => {
    const deleteChallengesIndexList = [];
    if (eventReviewList && Array.isArray(eventReviewList)) {
      for (let index = 0; index < eventReviewList.length; index++) {
        const element = eventReviewList[index];
        if ("reviewedChallenge" in element) {
          deleteChallengesIndexList.push(index);
        }
      }
    }
    Array.isArray(eventReviewList) && deleteChallengesIndexList.map((i, ind) => eventReviewList.splice(i - ind, 1))
  }

  function handleCloseReviewDecision(e) {
    e.stopPropagation()
    if (show === "REVIEW")
      onNext()
    else if (show === "CLAIM") {
      setShow(eventStatus === EventType.refund ? "CLAIM" : "LIST");
      handleClose();
    }
    else
      handleClose();
  }

  currentIndex === 0 && removeReviewChallengeFromEventReviewList(allEventsChallengesReviewList || challengesList)

  return (
    <>
      <Modal
        disableEscapeKeyDown
        open={open}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        className="review"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup review_claims">
          <Loader loading={loading} />
          <div className="image-container">
            <img
              src={Close}
              alt="close"
              onClick={handleCloseReviewDecision}
            />
          </div>

          {show === "LIST" && (
            <ReviewAndClaim
              currentPage={currentPage}
              setShow={setShow}
              challengesList={challengesList}
              eventList={allEventsChallengesList}
            />
          )}

          {show === "REVIEW" && (
            <ReviewModelContainer
              eventReviewList={allEventsChallengesReviewList}
              eventListForSplice={allEventsChallengesList}
              reviewList={challengesList}
              currentIndex={currentIndex}
              feedback={feedback}
              link={link}
              showLink={showLink}
              currentPage={currentPage}
              onSubmitReview={onSubmitReview}
              setShowLink={setShowLink}
              setFeedback={setFeedback}
              setLink={setLink}
              handleFileChange={handleFileChange}
              onNext={onNext}
            />
          )}

          {show === "CLAIM" && (
            <ClaimAllWinnings
              claimsList={claimsList}
              handleSuccess={handleClaimAllSuccess}
              eventId={eventId}
              blockChain={blockChain}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(ReviewDecision);
