import React, { useEffect, useState } from "react";
import "./editEvent.scss";
import { Chevron, Gallery, I, LinkButtonIcon, LockSvg } from "../../assets/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../button/Button";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesAction } from "../../store/actions/utilAction";
import api from "../../util/api";
import { PlatformFee, somethingWrong } from "../../util/helper";
import { get } from "lodash";
import EditEventLinks from "../Popups/editEventLinks";
import { ReactSVG } from "react-svg";
import moment from "moment";


const EditEvent = () => {
  const { _id } = useParams()
  const navigate = useNavigate();
  const [showEditLinks, setShowEditLinks] = useState(false)

  const dispatch = useDispatch();
  const { categories, status } = useSelector((state) => state.util);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
  });
  const [errors, setErrors] = useState({});
  const [event, setEvent] = useState({})
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors({ ...errors, [name]: "" });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addEventsLink = async (value) => {
    try {
      setLoading(true)
      const event = await api.post(`event/${_id}/links`, value)
      setEvent(prev => ({ ...prev, ...event?.data }))
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  const deleteEventLink = async (value) => {
    try {
      setLoading(true)
      const event = await api.delete(`event/${_id}/links/${value}`)
      setEvent(prev => ({ ...prev, ...event?.data }))
      toast.success('Link deleted successfully');
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }
  const handleFormDataChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  useEffect(() => {
    if (Array.isArray(categories) && categories.length === 0)
      dispatch(fetchCategoriesAction())
  }, [])

  useEffect(() => {
    if (_id)
      handleFetchEvent()
  }, [_id])

  useEffect(() => {
    if (event?._id) {
      const { name, description, img, category, privacy, fees } = event
      setFormData((prev) => { return { ...prev, name, description, category, privacy, fees: fees ?? 0 } })
    }
  }, [event])

  async function handleFetchEvent() {
    try {
      setLoading(true)
      const event = await api.get(`event/${_id}`)
      setEvent(event?.data)
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (Array.isArray(categories) && categories.length && event?.category)
      setFormData(prev => {
        return {
          ...prev,
          ['category']: event?.category,
        }
      })
  }, [categories, event?.category])

  const handleClickFinalStep = async () => {
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length === 0) {
        setLoading(true)
        const { name, description, category, fees, privacy } = formData
        const reqFormData = new FormData();
        if (name !== event?.description)
          reqFormData.set('name', name);
        if (formData?.description !== event?.description)
          reqFormData.set('description', formData?.description);
        if (category !== event?.category)
          reqFormData.set('category', formData?.category);
        if (fees !== event?.fees)
          reqFormData.set('fees', formData?.fees)
        if (privacy !== event?.privacy)
          reqFormData.set('privacy', formData?.privacy)

        if (selectedFile) {
          reqFormData.set('img', selectedFile);
        }

        if (selectedFile || (formData?.description !== event?.description) || (category !== event?.category) || (fees !== event?.fees) || (privacy !== event?.privacy) || (name !== event?.name)) {
          const updatedEvent = await api.patch(`event/${_id}/formData`, reqFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          navigate(`/event/${_id}/play`,)
          toast.success('Event updated successfully')
        } else
          toast.info('No information updated!')
      } else {
        setErrors(validationErrors);
        toast.info('Please fill form properly')
        return;
      }
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    } finally {
      setLoading(false)
    }
  };


  const validateForm = () => {
    const errors = {};
    if (!formData?.name) {
      errors.name = "Event name is required";
    }
    // if (!formData?.description) {
    //   errors.description = "Description is required";
    // }
    // if (!selectedFile) {
    //   errors.selectedFile = "Event image is required";
    // }
    return errors;
  };

  // const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(e.target.files[0]);
      setErrors({ ...errors, 'selectedFile': "" });
    } else {
      setSelectedFile(null);
    }
  };

  function handleFeesUpdate(increment) {
    if (increment) {
      const updatedFees = Number(get(formData, 'fees', 0)) + .5
      setFormData(prev => {
        return {
          ...prev,
          fees: updatedFees <= 5 ? updatedFees : 5
        }
      });
    } else {
      const updatedFees = Number(get(formData, 'fees', 0)) - .5
      setFormData(prev => {
        return {
          ...prev,
          fees: updatedFees >= 0 ? updatedFees : 0
        }
      });
    }
  }


  const serverImageUrl = event?.img ? event?.img?.split('/images/')[1] : ''
  const publicTab = formData?.privacy
  const isAfterParticipationDeadline = moment().isAfter(event?.endTime)

  return (
    <>

      <div className="form__event_1-2">
        <div className="link_container">
          <Button onClick={() => setShowEditLinks(true)}
            colorVariant="outline_cyan"
            iconSvgVariant="stroke_current_color">
            <div className=" flex_center">
              <ReactSVG src={LinkButtonIcon} className="svg_container" />
              <span>Edit Links</span>
            </div>
          </Button>
        </div>

        <div className="input_group_name">
          <label className="label_with_icon"> Name your event
            {isAfterParticipationDeadline && <ReactSVG src={LockSvg} className="svg_container" />}
          </label>
          <input
            type="text"
            placeholder="Golf event against Micheal Jordan"
            name="name"
            value={formData?.name}
            onChange={handleInputChange}
            disabled={true}
          />
          {errors?.name && (
            <span className="error_email">{errors?.name}</span>
          )}
        </div>
        <div className="input_group_desc">
          <div className="flex_between">
            <label className="label_with_icon"> Description
              {isAfterParticipationDeadline && <ReactSVG src={LockSvg} className="svg_container" />}
            </label>
            <span className="step_one_label_optional"> Optional </span>
          </div>
          <textarea
            type="text"
            placeholder="This is my description..."
            cols={9}
            rows={7}
            name="description"
            value={formData?.description}
            onChange={handleInputChange}
            disabled={isAfterParticipationDeadline}
          />
          {errors?.description && (
            <span className="error_email">{errors?.description}</span>
          )}
        </div>

        <div className="public__private">
          <div className="box__tabs">
            <Link
              to=""
              onClick={() => event?.updatedByAdmin ? toast.error('This event is updated by admin, You can not perform this action') : handleFormDataChange('privacy', "SECRET")}
              className={publicTab === "SECRET" ? "active" : ""}
              style={{ position: "relative" }}
            >
              Secret
            </Link>
            <Link
              to=""
              onClick={() => event?.updatedByAdmin ? toast.error('This event is updated by admin, You can not perform this action') : handleFormDataChange('privacy', "PRIVATE")}
              className={publicTab === "PRIVATE" ? "active" : ""}
              style={{ position: "relative" }}
            >
              Private
            </Link>
            <Link
              to=""
              onClick={() => event?.updatedByAdmin ? toast.error('This event is updated by admin, You can not perform this action') : handleFormDataChange('privacy', "PUBLIC")}
              className={publicTab === "PUBLIC" ? "active" : ""}
              style={{ position: "relative" }}
            >
              Public
            </Link>
          </div>
        </div>

        <div className="file_input">
          <span className="optional">Optional</span>
          <img src={Gallery} alt="" className="img_gal" />
          <label htmlFor="fileInput" className=" truncate">
            {selectedFile || serverImageUrl ? <p>{selectedFile?.name ?? serverImageUrl}</p> : <p> Add image </p>}
          </label>
          <input
            type="file"
            id="fileInput"
            placeholder="Akmkas"
            onChange={handleFileChange}
            accept="image/*"
          />
          {errors?.selectedFile && (
            <span className="error_email">{errors?.selectedFile}</span>
          )}
        </div>

        <div className="input__groups">

          <div className="optional_absolute"> Optional </div>
          <div className="fees_input_container">
            <div className=" counter_arrow_container">
              {isAfterParticipationDeadline ?
                <label><ReactSVG src={LockSvg} className="svg_container" /></label>
                :
                <>
                  <img onClick={() => {
                    if (formData?.fees >= event?.fees)
                      toast.error('While editing a event, Increasing fees is not allowed')
                    else
                      handleFeesUpdate(true)
                  }}
                    src={Chevron}
                    alt=""
                    className="counter_arrow_img rotate_up" />
                  <img onClick={() => handleFeesUpdate(false)} src={Chevron} alt="" className="counter_arrow_img" />
                </>
              }
            </div>
            <input
              className="fees_input"
              type="number"
              placeholder="Fees : 0.0% to 5%"
              name="fees"
              min={0}
              max={5}
              value={formData.fees}
              onChange={(e) => {
                const { value } = e.target
                if (!isNaN(value) && value >= 0 && value <= 5) {
                  if (value > event?.fees)
                    toast.error('While editing a event, Increasing fees is not allowed')
                  else
                    handleInputChange(e)
                }
              }}
              disabled={isAfterParticipationDeadline}
            />
            <hr className="hr_separator" />
            <div className="total_fees_container">
              {Number(get(formData, 'fees', 0)) + PlatformFee} % fee
            </div>
          </div>
          <div className="flex justify_between gap_1rem">
            {errors.fees ? (
              <span className="error_email">{errors.fees}</span>
            ) : <div></div>}
            <div className="fees_info ">
              Total fees paid by participants
              <br />
              (includes Makao fees)
            </div>
          </div>
        </div>

        <div className="input_group_name">
          <label className="step_one_label"> Category </label>
          <span className="optional_absolute">Optional</span>
          <select
            name="category"
            value={formData?.category}
            onChange={handleInputChange}

          >
            <option value="" >Select option</option>
            {Array.isArray(categories) && categories?.map((val, ind) => (<option key={ind} value={val?._id}>{val?.title}</option>))}
          </select>
          {errors?.category && (
            <span className="error_email">{errors?.category}</span>
          )}
        </div>

        <Button colorVariant="green" onClick={handleClickFinalStep}>
          Save & Publish
        </Button>
      </div>
      <EditEventLinks
        handleClose={() => setShowEditLinks(false)}
        open={showEditLinks}
        data={event}
        setData={setEvent}
        handleAddLinks={addEventsLink}
        handleDeleteLinks={deleteEventLink}
      />
    </>
  );
};

export default EditEvent;
