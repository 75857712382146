import { Typography } from "@mui/material";
import { Challenge } from "./challenge";
import Button from "../../button/Button";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ClaimType, somethingWrong } from "../../../util/helper";
import api from "../../../util/api";
import { Loader } from "../../loader";
import { get } from "lodash";
import { useWeb3Auth } from "../../../context/useWeb3auth";
import { makaoFixtureAbi } from "../../../abi/makaoFixtureAbi";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";

export function ClaimAllWinnings({ claimsList = [], handleSuccess, eventId, blockChain }) {
    const { address } = useWeb3Auth()
    const [loading, setLoading] = useState(false)

    const { data: collectHash, isPending: isCollectPending, error: collectError, isError: isCollectError, writeContract: collectWriteContract } = useWriteContract();
    const { isLoading: isCollectConfirming, isSuccess: isCollectConfirmed, error: collectReceiptError, isError: isCollectReceiptError } = useWaitForTransactionReceipt({ hash: collectHash })

    useEffect(() => {
        if (isCollectError) {
            if (typeof collectError?.name === 'string' && collectError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(collectError?.message)
        }
        else if (isCollectReceiptError) {
            if (typeof collectReceiptError?.name === 'string' && collectReceiptError?.name?.includes('TransactionExecutionError'))
                toast.error('Please make sure your wallet has enough balance')
            else
                toast.error(collectReceiptError?.message)
        }

    }, [isCollectError, isCollectReceiptError])

    //After all blockchain event confirmed
    useEffect(() => {
        if (isCollectConfirmed)
            handleDoClaims()
    }, [isCollectConfirmed])

    async function handleBlockChainDistributeWin() {
        collectWriteContract({
            address: blockChain.contractAddress,
            abi: makaoFixtureAbi,
            functionName: 'collect',
            args: [address]
        });
    }
    async function handleDoClaimsClick() {
        if (eventId && blockChain?.contractAddress)
            handleBlockChainDistributeWin()
        else
            handleDoClaims()
    }

    async function handleDoClaims() {
        try {
            setLoading(true)
            await api.post(`/event/claims`, { eventId })
            toast.success('Returns claimed successfully')
            handleSuccess()
        } catch (ex) {
            toast.error(ex?.message ?? somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div>
                <Loader loading={loading || isCollectPending || isCollectConfirming} />
                <Typography
                    id="modal-modal-title"
                    className="popup_heading"
                    variant="h6"
                    component="h2"
                    style={{ marginTop: "1rem" }}
                >
                    Claim all
                </Typography>
                {claimsList.length > 0 ?
                    <div className="claims_container">
                        {
                            claimsList.map((val, ind) => (
                                <Challenge
                                    key={ind}
                                    amount={get(val, 'amount', 0)}
                                    title={get(val, 'challenge[0].title', '')}
                                    refundTag={val?.type === ClaimType?.refund}
                                    challengeVolume={get(val, 'challenge[0].userPlay[0].total')}
                                />))
                        }

                    </div>
                    :
                    <div >
                        Nothing to Claim
                    </div>
                }
                <div className="action_container">
                    <Button colorVariant="light_gray" onClick={handleDoClaimsClick} disabled={claimsList.length === 0 ? true : false} >Claim All</Button>
                </div>

            </div>

        </>
    )
}