import React, { useState } from "react";

import "./header.scss";
import { Bars, Heart_blue, Makao, EventPlus, Search, User, } from "../../../assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConnectWallet from "../../connectWallet/ConnectWallet";
import { HeaderSearchBar } from "./searchbar";
import useWindowDimensions from "../../../context/useWindowDimmension";
import { useWeb3Auth } from "../../../context/useWeb3auth";
import { ReactSVG } from "react-svg";
import Button from "../../button/Button";

const Header = ({ handleClickBars }) => {
  const { loggedIn } = useWeb3Auth();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions()

  const navigate = useNavigate();

  const [openWallet, setOpenWallet] = useState(false);

  const handleOpenWallet = () => setOpenWallet(true);
  const handleCloseWallet = () => setOpenWallet(false);

  return (
    <>
      <ConnectWallet handleClose={handleCloseWallet} open={openWallet} />
      <div className="header">
        <div className="header__left">
          <Link to="/dashboard">
            <img src={Makao} alt="img" />
          </Link>
        </div>
        <div className="header__right">
          <div className={`center__content ${pathname !== "/dashboard" ? 'hide_search_bar' : ''}`} >
                <ReactSVG className="svg_container" src={Search} alt="img" />
                <HeaderSearchBar />
            {/* <img src={Search} alt="img" /> */}
          </div>
          {/* <div className="center__content">
            <img src={Search} alt="img" />
            <Popover
              content={<a onClick={() => setShowSearched(false)}>Close</a>}
              title="Title"
              trigger="click"
              open={showSearched}
              onOpenChange={(bool) => setShowSearched(bool)}
            >
              <input type="text" placeholder="Search Fame Event" onChange={() => setShowSearched(true)} />
            </Popover>
          </div> */}
          <div className="right__content">
            {loggedIn ? (
              <>
                <img
                  alt="add-icon"
                  onClick={() => navigate(`/create-event`)}
                  src={EventPlus}
                  className="pointer"
                />
                <img
                  src={User}
                  alt="img"
                  onClick={() => navigate("/profile")}
                  className="pointer"
                />
                <Link to="/notification" style={{ marginTop: "6px" }}
                  className="pointer"
                >
                  <img src={Heart_blue} alt="img" />
                </Link>
                {width && width <= 540 &&
                  < img src={Bars} alt="img" onClick={handleClickBars} className="pointer" />
                }
              </>
            ) : (
              <div style={{marginLeft: 'auto'}}>
                <Button
                  colorVariant="border_outline_cyan"
                  onClick={() => {
                    navigate("/signup");
                    window?.location?.reload();
                  }}>
                  <span>Login</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div >
    </>
  );
};

export default Header;
