import React from "react";
import "./place.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close } from "../../../assets/images";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Place_a_bet_insufficient = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button onClick={handleOpen}> place a participate insufficient funds </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            Make a participate
          </Typography>

          <Typography>
            <h3 className="heading">Prediction A : Steve wins</h3>
          </Typography>
          <Typography>
            <p className="para">
              To win Steve needs to be declared victorious at the end of his
              combat.
            </p>
          </Typography>

          <Typography>
            <div className="your_play">
              <label className="label"> Your participate </label>
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Insufficient funds"
                  className="your_play_input"
                />
                <span> USDT </span>
              </div>
              <span className="bottom_Desc">insufficient funds</span>
            </div>
            <div className="bottom__desc">
              <div className="one_line">
                <span> Service fee (If you success) </span>
                <span> 1% </span>
              </div>
              <div className="two">
                <span> Organiser’s fee (If you success) </span>
                <span> 5% </span>
              </div>
            </div>
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <button className="popups__btn_st"> Buy USDT </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Place_a_bet_insufficient;
