import { ReactSVG } from "react-svg";
import { ChevronDownPink, ChevronUpCyan, DownArrowPink, MakaoCurrencyIcon, Money, Shield, ShieldClose, ThreeDotsIcon, UpArrow, UsersOutlineCyan } from "../../assets/images";
import { calculateTimeRemaining, formatNumber, getAverageTrustNote } from "../../util/helper";
import "./badge.scss";
import { useEffect, useMemo, useState } from "react";

export function TextBadge({ text = 1, colorVariantClass = '', sizeVariantClass = '', onClick }) {
    return <div onClick={onClick} className={`text_badge ${colorVariantClass} ${sizeVariantClass} ${onClick ? 'pointer' : ''}`}>{text}</div>
}

export function TextBadgeLightGray({ text = 1 }) {
    return <div className="text_badge_light_gray ">{text}</div>
}

export function TextBadgeCyan({ text }) {
    return <div className="text_badge_cyan ">{text}</div>
}

export function BadgeOdd({ odd, changePercentage, className = '' }) {
    return <div className={`custom_badge_odd ${className} ${changePercentage < 0 ? 'outline_pink' : 0}`}>X {odd}
        <ArrowsComponent changePercentage={changePercentage} />
    </div>
}

export function BadgePlayerCount({ count, className = '', onClick }) {
    return <div onClick={onClick} className={"player_count_badge " + className}>
        <ReactSVG className="svg_container" src={UsersOutlineCyan} alt="" />
        <span>{count}</span>
    </div>
}

export function BadgeMakaoMoney({ amount, colorVariantClass = 'green', className = '', onClick }) {
    return <div onClick={onClick} className={`makao_money_badge ${colorVariantClass} ${className}`}>
        {/* <ReactSVG className="svg_container" src={MakaoCurrencyIcon} alt="" /> */}
        <span>$</span>
        <span>{amount}</span>
    </div>
}

export function BadgeOrganizerFee({ fee, className = '', onClick }) {
    return <div onClick={onClick} className={"organizer_fee_badge " + className}>
        <ReactSVG className="svg_container" src={Money} alt="" />
        <span>{fee}</span>
    </div>
}

export function BadgeThreeDot({ onClick }) {
    return <div onClick={onClick} className={"three_dot_badge "}>
        <ReactSVG className="svg_container" src={ThreeDotsIcon} alt="" />
    </div>
}

export function BadgeTrustNote({ trustNote, noReview, onClick, sizeVariant = '' }) {
    const img = trustNote >= 4 ? Shield : ShieldClose

    return <div onClick={onClick} className={`trust_note_badge ${sizeVariant} ${trustNote >= 4 ? 'trusted' : 'not_trusted'} `}>
        {noReview ?
            <span>No Review</span>
            : <><ReactSVG className="svg_container" src={img} alt="" />
                <span>{formatNumber(trustNote)}/5</span></>
        }
    </div>
}

export function Badge60MinutesCountDown({ date }) {
    const [timeRemaining, setTimeRemaining] = useState({});

    useEffect(() => {
        let timer = null
        if (date) {
            timer = setInterval(() => {
                const calculatedTime = calculateTimeRemaining(date)
                if (calculatedTime.days === 0 && calculatedTime.hours === 0 && calculatedTime.minutes === 0 && calculatedTime.seconds === 0) {
                }
                setTimeRemaining(calculatedTime);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [date]); // Run the effect only once on component mount


    return <div className="minutes60_countdown_badge">
        <span>{timeRemaining.minutes} </span>:
        <span>{timeRemaining.seconds}</span>
    </div>
}

export function ArrowsComponent({ changePercentage }) {
    let arrowsCount = 0;
    let arrowImage = ChevronUpCyan; // Default arrow image

    // Determine the number of arrows and the arrow image based on the percentage change
    if (changePercentage > 0) {
        if (changePercentage < 30) {
            arrowsCount = 1;
            arrowImage = UpArrow
        } else if (changePercentage >= 30 && changePercentage <= 100) {
            arrowsCount = 2;
        } else {
            arrowsCount = 3;
        }
    } else if (changePercentage < 0) {
        arrowImage = ChevronDownPink; // Change arrow image for negative percentage change
        if (changePercentage > -30) {
            arrowsCount = 1;
            arrowImage = DownArrowPink
        } else if (changePercentage >= -100) {
            arrowsCount = 2;
        } else {
            arrowsCount = 3;
        }
    }

    // Create an array to store the JSX elements for arrows
    const arrows = Array.from({ length: arrowsCount }).map((_, index) => (
        <img key={index} src={arrowImage} alt={arrowImage === ChevronDownPink ? 'down-pink' : 'up-pink'} />
    ));

    return <div className="flex_center_col">{arrows}</div>;
};