import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close, Right } from "../../../assets/images";
import { formatNumber } from "../../../util/helper";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Claim = ({ handleClose, open, data }) => {
  const { amount, createdAt } = data ?? {}

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  return (
    <>
      {/* <Button> Transaction history claims pop up </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <div className="arrow-up">
            <img src={Right} alt="Image" style={{ width: "25px" }} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            Win
            {/* Claim */}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "#FFFFFF" }}
          >
            <Link style={{ color: "white" }}>Info</Link> <br /> You win {formatNumber(amount ?? 0)} USDT
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="date__amt__head">
              <span>Date</span>
              <span>Amount</span>
            </div>
            <div className="date__amt">
              <span>{moment(createdAt).format('DD/MM/YYYY HH:mm')} </span>
              <span style={{ color: "#BAEAC8" }}>{formatNumber(amount ?? 0)} USDT</span>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Claim;
