import React, { useEffect, useMemo, useRef, useState } from "react";
import "./readMore.scss";
import { Typography } from "@mui/material";
import { Link, MsgDots, noImageProfile } from "../../../assets/images";
import api from "../../../util/api";
import { toast } from "react-toastify";
import { somethingWrong } from "../../../util/helper";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateEventWithObjAction } from "../../../store/slices/eventSlice";
import { Loader } from "../../loader";
import { ModalContainer } from "../../modalContainer";
import { ReactSVG } from "react-svg";
import Button from "../../button/Button";

const ReadMore = ({ handleClose, open, eventId, description, links, commentsCount }) => {
  const textareaRef = useRef(null);

  const dispatch = useDispatch();
  const userFriends = useSelector((state) => state.user?.user?.friends);

  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [disableFetchComment, setDisableFetchComment] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize] = useState(30)
  const [comments, setComments] = useState([])


  useEffect(() => {
    if (open && eventId) {
      fetchComments()
    }
  }, [eventId, open])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  async function fetchComments() {
    try {
      if (eventId) {
        setLoading(true)
        const res = await api.get(`event/${eventId}/comment?page=${page}&pageSize=${pageSize}`)
        // const friendsComment = await api.get(`event/${eventId}/comment/friends?page=${1}&pageSize=${500}`)

        if (page === res.page && Array.isArray(res?.data)) {
          setComments(comments.concat(res?.data))
          setPage(res?.page ? res?.page + 1 : 1)
          if (res?.data.length < pageSize) {
            setDisableFetchComment(true)
            if (page > 1)
              toast.info('No more messages')
          }
        }
      }
    } catch (ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  const handleCreateNewComment = async () => {
    try {
      setLoading(true)
      await api.post(`event/${eventId}/comment`, { text })
      setComments(comments.concat({
        comment: {
          text: text,
          createdAt: moment()
        },
        user: {
          username: 'You'
        }
      }))
      setText('')
      toast.success('Comment added successfully')
      if (eventId) {
        dispatch(updateEventWithObjAction({ commentsCount: commentsCount + 1 }))
      }
    } catch (ex) {
      toast.error(somethingWrong)
    } finally {
      setLoading(false)
    }
  }

  const handleFetchMoreComments = () => {
    if (!disableFetchComment)
      fetchComments()
  }

  const { friendComments, usersComments } = useMemo(() => {

    if (userFriends && Array.isArray(userFriends) && userFriends.length) {
      // Filter comments made by friends
      const friendComments = comments.filter(val => userFriends.includes(val?.comment?.createdBy));

      // Filter comments made by non-friends
      const usersComments = comments.filter(val => !userFriends.includes(val?.comment?.createdBy));

      return {
        friendComments,
        usersComments,
      };
    } else
      return {
        friendComments: [],
        usersComments: comments,
      };
  }, [comments, userFriends]);

  return (
    <ModalContainer
      open={open}
      handleClose={handleClose}
      heading="Read more">
      <div className="read_more_modal">
        <Loader loading={loading} />
        <Typography
          id="modal-modal-title-read-more"
          variant="h6"
          component="h2"
          className="head__title__read_more"
        >
          {description}
        </Typography>

        {
          links && Array.isArray(links) ?
            <div className="links_container">
              {links?.map((val, ind) => (<div onClick={() => window.open(val?.link, '_blank')} className="link_and_icon" key={ind}><ReactSVG src={Link} className="svg_container" /><span className="truncate">{val?.name}</span></div>))}
            </div>
            : <></>
        }

        {
          eventId ?
            <>
              <div className="comments mt_16">
                {
                  friendComments.map((val, ind) => (
                    <div key={ind} className="comment_container">
                      <div className=" flex justify_center gap">
                        <img src={val?.user?.img ?? noImageProfile} alt=""
                          onError={(event) => {
                            event.target.src = noImageProfile;
                          }} />
                        <p>
                          <span style={{ color: "#CAE9FF" }}>{val?.user?.username}</span> {val?.comment?.text}
                        </p>
                      </div>
                      <span
                        style={{
                          position: "absolute",
                          color: "white",
                          fontSize: "12px",
                          top: "34px",
                          left: "10px",
                          fontWeight: "500",
                          marginTop: "10px"
                        }}
                      >
                        {moment(val?.comment?.createdAt).fromNow()}
                      </span>

                    </div>
                  ))
                }
                {
                  usersComments.map((val, ind) => (
                    <div key={ind} className="comment_container">
                      <div className=" flex justify_center gap">
                        <img src={val?.user?.img ?? noImageProfile} alt=""
                          onError={(event) => {
                            event.target.src = noImageProfile;
                          }} />
                        <p>
                          <span >{val?.user?.username}</span> {val?.comment?.text}
                        </p>
                      </div>
                      <span
                        style={{
                          position: "absolute",
                          color: "white",
                          fontSize: "12px",
                          top: "34px",
                          left: "10px",
                          fontWeight: "500",
                          marginTop: "10px"
                        }}
                      >
                        {moment(val?.comment?.createdAt).fromNow()}
                      </span>
                    </div>
                  ))
                }
              </div>
              {usersComments && usersComments?.length ?
                <img onClick={handleFetchMoreComments} src={MsgDots} alt="" className={`msgdots ${disableFetchComment && 'disabled'}`} />
                : <></>
              }

              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="input__groups__outcome">
                  <textarea
                    ref={textareaRef}
                    autoFocus
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    placeholder="Write something..." />
                </div>
                {page !== 1 && <Button onClick={handleCreateNewComment} disabled={loading || text === ''} colorVariant="cyan" className={'mt_16'}><span>Send</span></Button>}

              </Typography>
            </>
            : <></>
        }
      </div>
    </ModalContainer>

  );
};

export default ReadMore;
