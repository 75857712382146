import React from "react";
import { Box, Modal, Typography } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TrustNotes = ({ show, setShow }) => {

  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="popup">
        <Typography
          id="modal-modal-title"
          className="infoPopup_heading"
          variant="h6"
          component="h2"
          style={{ fontSize: "24px" }}
        >
          Trust note
        </Typography>
        <Typography
          id="modal-modal-title-desc"
          variant="h6"
          component="h2"
          style={{ fontSize: "16px", fontWeight: "400" }}
        >
          This note only involves event organizers. It allows other user to
          know if it is safe to participate this event.
        </Typography>
      </Box>
    </Modal>
  );
};

export default TrustNotes;
