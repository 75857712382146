import React, { useEffect, useRef, useState } from "react";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import "./editProfile.scss";
import {
  BackIcon,
  Copy,
  I,
  LinkButtonIcon,
  ProfileEdit,
  Verified,
  noImageProfile,
} from "../../assets/images";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import PrivateAccount from "../infoPopups/privateAccount/PrivateAccount";
import PublicAccount from "../infoPopups/PublicAccount/PublicAccount";
import LinksForUser from "../Popups/linksForUser/LinksForUser";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction, updateUserImgAction } from "../../store/actions/userAction";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import { Loader } from "../loader";
import { useWeb3Auth } from "../../context/useWeb3auth";
import { BadgeTrustNote } from "../badge";
import { ReactSVG } from "react-svg";
import { TabsComponent } from "../TabsComponent";
import { isValidPhoneNumber } from "react-phone-number-input";
import EditEventLinks from "../Popups/editEventLinks";
import api from "../../util/api";
import { updateUserStateWithObjAction } from "../../store/slices/userSlice";

const Profile = () => {
  const fileInputRef = useRef(null);
  const { address, getPrivateKey } = useWeb3Auth()

  const { user, status, } = useSelector((state) => state.user);
  const { username, description, email, privacy, img, instagramLink, userTrustNote, updatedAt, mobile } = user ?? {}

  const dispatch = useDispatch();
  const [showEditLinks, setShowEditLinks] = useState(false)
  const [privateKey, setPrivateKey] = useState('')

  const [tabSat, setTabSat] = useState("4.9/5");
  const [image, setImage] = useState("");
  const handleClickSat = (type) => {
    setTabSat(type);
  };

  const [publicTab, setPublicTab] = useState("private");
  const handleClickPublic = (type) => {
    setPublicTab(type);
  };

  const [openPrivate, setOpenPrivate] = React.useState(false);
  const handleOpenPrivate = () => setOpenPrivate(true);
  const handleClosePrivate = () => setOpenPrivate(false);

  const [openPublic, setOpenPublic] = React.useState(false);
  const handleOpenPublic = () => setOpenPublic(true);
  const handleClosePublic = () => setOpenPublic(false);

  const [openLink, setOpenLink] = React.useState(false);
  const handleOpenLink = () => setOpenLink(true);
  const handleCloseLink = () => setOpenLink(false);


  const [formData, setFormData] = useState({
    username: "",
    email: "",
    description: "",
    mobile: ''
  });

  const [errors, setErrors] = useState({ full_name: "" });


  useEffect(() => {
    setFormData({ username, email, description, mobile })
    setPublicTab(privacy ? 'public' : 'private')
  }, [user, updatedAt])

  useEffect(() => {
    handleGetPrivatekey()
  }, [])

  async function handleGetPrivatekey() {
    const key = await getPrivateKey()
    if (key)
      setPrivateKey(key)
  }


  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };

      const reqFormData = new FormData();
      reqFormData.set('img', file);
      dispatch(updateUserImgAction(reqFormData)).then((res) => {
        if (!res?.payload?.error) {
          toast.success('User image updated successfully')
        } else {
          toast.error(somethingWrong)
        }
      })

    }
  };

  function handleChangeMobileNumber(number) {
    setFormData(prev => {
      return {
        ...prev,
        mobile: number,
      }
    })
    setErrors({ ...errors, 'mobile': "" });
  }

  // validation
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.username) {
      errors.username = "Username is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }

    // if (!formData.description) {
    //   errors.description = "Description is required";
    // }

    // if (formData?.mobile)
    //   if (!isValidPhoneNumber(formData?.mobile))
    //     errors.mobile = "Please enter a valid number";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // validation
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const { mobile, ...rest } = formData
      const payload = { ...rest, walletAddress: address, privacy: publicTab === 'private' ? false : true }

      // if (mobile && isValidPhoneNumber(mobile)) {
      //   const phoneNumber = parsePhoneNumber(mobile)
      //   payload.mobile = mobile
      //   payload.mobileCode = phoneNumber?.countryCallingCode
      // }


      dispatch(updateUserAction(payload))
        .then((res) => {
          console.log('res', res)
          if (!res?.error?.message) {
            toast.success('User Info updated successfully')
          } else {
            toast.error(res?.error?.message ?? somethingWrong)
          }
        })

    } else {
      setErrors(validationErrors);
      return;
    }
  };

  const addUserLink = async (value) => {
    try {
      const user = await api.post(`user/links`, value)
      console.log('user :', user);
      dispatch(updateUserStateWithObjAction(user?.data))
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    }
  }

  const deleteUserLink = async (value) => {
    try {
      // setLoading(true)
      const user = await api.delete(`user/links/${value}`)
      dispatch(updateUserStateWithObjAction(user?.data))
      toast.success('Link deleted successfully');
    } catch (ex) {
      toast.error(ex?.message ?? somethingWrong)
    }
  }

  async function handleCopyPrivateKey() {
    await navigator.clipboard.writeText(privateKey);
    toast.success('Private key copied successfully')
  }

  return (
    <>
      <Loader loading={status === 'loading'} />
      <PrivateAccount handleClose={handleClosePrivate} open={openPrivate} />
      <PublicAccount handleClose={handleClosePublic} open={openPublic} />
      <LinksForUser handleClose={handleCloseLink} open={openLink} isEditable={true}
        data={{ instagramLink, username }} onlyUser={true}
      />
      <div className="profile__edit">
        <div className="profile__left__edit">
          <Link to="/profile">
            <img src={BackIcon} alt="" className="backicon" />
          </Link>
          <div className="profile__left__content">
            <div className="profile__img">
              <div className=" image_container" >
                <img src={img ?? image} alt="" className="img"
                  onError={(event) => {
                    event.target.src = noImageProfile;
                  }} />
                <img
                  src={Verified}
                  alt=""
                  style={{
                    position: "absolute",
                    top: "58%",
                    right: "4px",
                    width: "20px",
                  }}
                />
                <div className="image-upload">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                  <img
                    src={ProfileEdit}
                    alt=""
                    style={{
                      width: "22px",
                      cursor: "pointer",
                      position: "absolute",
                      right: "0px",
                      top: "-5px",
                    }}
                    onClick={handleIconClick}
                  />
                </div>
              </div>
            </div>
            <div className="leaderboards__tabs_edit">
              <div className="box__tabs">
                <Link
                  to=""
                  onClick={() => handleClickSat("@Satoshi")}
                  className={`truncate ${tabSat === "@Satoshi" ? "active" : ""}`}
                >
                  @{username}
                </Link>
                <BadgeTrustNote
                  onClick={() => handleClickSat("4.9/5")}
                  trustNote={userTrustNote} />
                <Link
                  to=""
                  onClick={() => handleClickSat("Rank : 2873")}
                  className={tabSat === "Rank : 2873" ? "active" : ""}
                  style={{
                    flexShrink: 0
                  }}
                >
                  Rank : 2873
                </Link>
              </div>
            </div>
            <div className="buttons__two">
              <Button onClick={() => setShowEditLinks(true)}
                colorVariant="outline_cyan"
                iconSvgVariant="stroke_current_color">
                <div className=" flex_center">
                  <ReactSVG src={LinkButtonIcon} className="svg_container" />
                  <span>Edit Links</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="profile__right__edit">
          <div className="edit__form">
            <div className="userName">
              <label> Username </label>
              <input
                type="text"
                placeholder="@fairenof"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
              {errors.username && (
                <span className="error_email">{errors.username}</span>
              )}
            </div>
            <div className="email">
              <div className="labels">
                <label> Email </label>
                <label style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                  Private
                </label>
              </div>
              <input
                type="email"
                placeholder="marcnahas@hotmail.fr"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <span className="error_email">{errors.email}</span>
              )}
            </div>

            {/* <div className="phone_container mt_16">
              <div className="labels">
                <label>Phone number</label>
                <label style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                  Optional
                </label>
              </div>
              <div className="phone_input_container ">
                <PhoneInput
                  international
                  defaultCountry="IN"
                  value={formData?.mobile}
                  onChange={handleChangeMobileNumber}
                  error={errors?.mobile}
                />
              </div>
              {errors.mobile && (
                <span className="error_email">{errors.mobile}</span>
              )}
            </div> */}

            <div className="edit__description">
              <label> Description </label>
              <textarea
                placeholder="This is my description..."
                className="edit_desc_textarea"
                defaultValue=""
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
              {errors.description && (
                <span className="error_email">{errors.description}</span>
              )}
            </div>

            <div className="public__private">
              <TabsComponent
                currentTab={publicTab}
                setTab={(tab) => handleClickPublic(tab)}
                tabsList={[
                  { name: 'Private', value: 'private' },
                  { name: 'Public', value: 'public' }]} />
            </div>

            {privateKey ?
              <div onClick={handleCopyPrivateKey} className="copy_button">
                <ReactSVG src={Copy} className=" svg_container" />
                <span className="">Copy my private key (Web3auth)</span>
              </div>
              : <></>
            }


            <Button colorVariant="green" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <EditEventLinks
        handleClose={() => setShowEditLinks(false)}
        open={showEditLinks}
        handleAddLinks={addUserLink}
        handleDeleteLinks={deleteUserLink}
        data={user}
      />
    </>
  );
};

export default Profile;
