import { useNavigate } from "react-router-dom";
import "./dash__content.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchFriendsEventsAction, fetchFriendsTrendingEventsAction } from "../../../store/actions/eventAction";
import { useEffect } from "react";
import EventCard from "../../eventCard";
import { HeadingAndViewAll } from "./HeadingAndViewAll";

export function FriendsEvents() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const trendingFriendEvents = useSelector((state) => state.event.trendingFriendEvents);


    useEffect(() => {
        // if (Array.isArray(trendingFriendEvents) && trendingFriendEvents.length === 0)
        dispatch(fetchFriendsTrendingEventsAction({ page: 1, pageSize: 20 }))
    }, [])


    return <div className="dash__content__top">
        {
            Array.isArray(trendingFriendEvents) && trendingFriendEvents.length ?
                <>
                    <HeadingAndViewAll onClick={() => navigate("/friends-play-detail")} title={'Your friends participated'} />
                    <div className="dash__content__top__boxes">
                        {trendingFriendEvents.slice(0, 20).map((val, ind) => (<div key={ind} style={{ width: 345, minWidth: 345 }}>
                            <EventCard data={val} type={'FRIENDS'} />
                        </div>
                        ))}
                    </div>
                </> :
                <></>
        }
    </div>
}