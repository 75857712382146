import { get } from 'lodash';
import moment from 'moment';

export const somethingWrong = 'Something went wrong! Please try again.'

export const TokenType = { 'USDC': 'USDC', 'ETH': 'ETH' }

export const TokenTypeMap = { 'USDC': 'USDT', 'ETH': 'MATIC' }

export const UserRoles = { admin: 'ADMIN', user: 'USER' }

export const ClaimType = { refund: 'REFUND', claim: 'CLAIM' }

export const EventType = { refund: 'REFUND', complete: 'COMPLETE', default: 'DEFAULT' }

export const PlatformFee = 0

export const validateStrongPassword = (_, value) => {
    // Define your strong password criteria using regular expressions
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check if the password meets the criteria
    if (
        value &&
        value.length >= 6 &&
        regexUpperCase.test(value) &&
        regexLowerCase.test(value) &&
        regexSpecialChar.test(value)
    ) {
        return Promise.resolve();
    }

    return Promise.reject('Password must be at least 6 characters long and include an uppercase letter, a lowercase letter, and a special character.');
};

export function validateInputDateTimeFormat(date) {
    // Define the regular expression for the desired format
    // const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4} (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
    // const validFormat = regex.test(string);
    // if (validFormat) {
    //     const parsedDate = moment(string, "DD/MM/YYYY HH:mm", true); // Set true to strict mode
    //     return parsedDate.isValid();
    // }
    if (date && date.isValid())
        return true
    return false
}

export const isSecondDateAfterFirst = (dateString1, dateString2) => {
    const firstDate = moment(dateString1, "DD/MM/YYYY HH:mm", true);
    const secondDate = moment(dateString2, "DD/MM/YYYY HH:mm", true);

    // Check if the second date is after the first date
    return secondDate.isAfter(firstDate);
};

export const calculateTimeRemaining = (targetDate) => {
    const now = moment();
    const difference = moment(targetDate).diff(now);

    if (difference > 0) {
        const duration = moment.duration(difference);

        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return { days, hours, minutes, seconds };
    } else {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
};

export function formatNumber(number) {
    if (number < 1000) {
        // For numbers less than 1000, format to 2 decimal places
        return number % 1 === 0 ? number.toString() : number.toFixed(2);
    } else {
        const formattedNumber = new Intl.NumberFormat('en-US', {
            notation: 'compact',
            compactDisplay: 'short',
        }).format(number);
        if (formatNumber)
            return formattedNumber
    }
    return number
}

export function queryString(obj) {
    if (typeof obj === 'object') {
        let query = '';
        for (const key of Object.keys(obj)) {
            query += `${key}=${obj[key]}&`;
        }
        return query;
    }
    return '';
}

export function delayForMS(delay = 1000) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, delay);
    });
}

// Get key from cookies
export function getKeyFromCookie(key) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === key) {
            return value;
        }
    }
    return null;
}


export function removeCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function getAverageTrustNote(averageReview) {
    let trustReview;

    //for now array means its for event and coming at average
    if (Array.isArray(averageReview)) {
        trustReview = averageReview.length > 0 ? get(averageReview, '[0]', 0) * 5  // average would always be between 0 to 1.
            : 0;
    } else {
        trustReview = averageReview || 0; // we need default average 2.5 then here take default averageReview would be 0.5 ( multipling by 5 )
    }

    return trustReview
}

export function shortCryptoAddress(address, startLength = 8, endLength = 6) {
    // Validate that the address is a string and has the correct length
    if (typeof address !== 'string' || address.length < startLength + endLength + 2)
        throw new Error('Invalid address format');
    // Return the formatted address
    return `${address.slice(0, startLength)}...${address.slice(-endLength)}`;
}