import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../util/api";
import { queryString, somethingWrong } from "../../util/helper";
import { toast } from "react-toastify";
import EventCard from "../eventCard";
import { Loader } from "../loader";

const CategoryEvents = () => {
  const navigate = useNavigate();

  const { category, _id } = useParams()
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryEvents, setCategoryEvents] = useState([])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    LoadCategoryEvents()
  }, [])

  async function LoadCategoryEvents() {
    try {
      setIsLoading(true)
      const response = await api.get(`event?${queryString({ page: 1, pageSize: 100, categoryId: _id })}`)
      setCategoryEvents(response?.data)
    } catch (_ex) {
      toast.error(somethingWrong)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Loader loading={isLoading} />
      <h1 className="heading__tenis"> {category + ' Events' ?? "Unknown Category"} </h1>
      <div className="trending_images">
        {categoryEvents?.map((val, ind) => (
          <div key={ind} style={{ width: '100%', minWidth: "100%" }}>
            <EventCard data={val} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryEvents;
