import { getPublicCompressed } from "@toruslabs/eccrypto";
import { IProvider, WALLET_ADAPTERS } from "@web3auth/base";
import { WagmiProvider, createConfig, http, useAccount, useConnect, useDisconnect } from "wagmi";

import * as React from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { web3AuthInstance } from "../config/web3AuthInstance"

import { getWalletProvider } from "../config/walletProvider";
import api from "../util/api";
import { toast } from "react-toastify";
import { somethingWrong } from "../util/helper";
import { get } from "lodash";

let cryptoSignupRetryCount = 0
export const Web3AuthContext = createContext({
  web3Auth: null,
  provider: null,
  isLoading: false,
  connected: false,
  loggedIn: false,
  user: null,
  address: null,
  balance: null,
  chainId: null,
  playgroundConsole: "",
  login: async () => { },
  logout: async () => { },
  getUserInfo: async () => null,
  getAddress: async () => "",
  getBalance: async () => "",
  getSignature: async () => "",
  getSignTypeData: async () => "",
  sendTransaction: async () => "",
  getPrivateKey: async () => "",
  getChainId: async () => "",
  deployContract: async () => { },
  readContract: async () => "",
  writeContract: async () => "",
  verifyServerSide: async () => { },
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}


export const Web3AuthProvider = ({ children }) => {
  const pathname = window?.location?.pathname;

  const { connect, connectors } = useConnect();

  const [web3Auth, setWeb3Auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState(null);
  const [balance, setBalance] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [playgroundConsole, setPlaygroundConsole] = useState("");
  const [chainId, setChainId] = useState(null);
  const [connected, setConnected] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false)

  const uiConsole = (...args) => {
    setPlaygroundConsole(`${JSON.stringify(args || {}, null, 2)}\n\n\n\n${playgroundConsole}`);
    console.log(...args);
  };

  const setWalletProvider = useCallback(async (web3authProvider) => {
    const walletProvider = getWalletProvider(web3authProvider, uiConsole);
    setProvider(walletProvider);
    setAddress(await walletProvider.getAddress());
    setBalance(await walletProvider.getBalance());
    setChainId(await walletProvider.getChainId());
  }, []);

  useEffect(() => {
    init();
  }, [setWalletProvider]);

  async function init() {
    try {
      setIsLoading(true);

      await web3AuthInstance.initModal({
        modalConfig: {
          [WALLET_ADAPTERS.OPENLOGIN]: {
            loginMethods: {
              email_passwordless: {
                showOnModal: true
              },
              google: {
                showOnModal: true
              },
              facebook: {
                showOnModal: true,
              },
              twitch: {
                showOnModal: false
              },
              reddit: {
                showOnModal: false
              },
              apple: {
                showOnModal: false
              },
              line: {
                showOnModal: false
              },
              github: {
                showOnModal: false
              },
              kakao: {
                showOnModal: false
              },
              linkedin: {
                showOnModal: false
              },
              weibo: {
                showOnModal: false
              },
              wechat: {
                showOnModal: false
              },
              farcaster: {
                showOnModal: false
              }
            },
          },
        },
      });
      if (web3AuthInstance.status === "connected") {
        console.log("web3 web3AuthInstance.provider", web3AuthInstance.provider)
        setWalletProvider(web3AuthInstance.provider);
        setUser(await web3AuthInstance.getUserInfo());
        setConnected(true);
      }
      setWeb3Auth(web3AuthInstance);
    } catch (error) {
      uiConsole(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (connected && provider && address)
      if (pathname === '/signup')
        handleSignin()
  }, [connected, provider, address])

  useEffect(() => {
    if (web3Auth)
      checkIfAlreadyLoggedIn()
  }, [web3Auth])

  async function checkIfAlreadyLoggedIn() {
    if (pathname !== '/signup') {
      const elementToMove = document.getElementById('w3a-parent-container');
      if (elementToMove)
        elementToMove.style.display = 'none';
      const isLoggedIn = await login()


      if (isLoggedIn)
        setLoggedIn(true)
    }
  }

  const login = async () => {
    try {
      if (!web3Auth) {
        uiConsole("web3auth not initialized yet login");
      }

      //Connecting to web3auth
      if (web3AuthInstance)
        await web3AuthInstance.connect();
      else if (web3Auth)
        await web3Auth.connect();

      if (Array.isArray(connectors) && connectors[0]?.uid) {
        console.log('initialize')
        await connect({ connector: connectors[0] })
        console.log('after connect')
      }


      if (web3Auth?.status === "connected") {
        setWalletProvider(web3Auth.provider);
        setUser(await web3Auth.getUserInfo());
        setConnected(true);
        setWeb3Auth(web3Auth)
        return true
      }
    } catch (error) {
      if (error?.code === 4001) {
        await logout()
        window.location.reload();
      } else if (error?.code === 5115 || (error?.message?.toLowerCase()?.includes("popup") && error?.message?.toLowerCase()?.includes("blocked")))
        toast.error(`Popup was blocked. Please allow popup to continue.`, { toastId: 'popupBlocked', autoClose: false })
      else
        toast.error(error?.message ?? somethingWrong)
    }
  };

  const logout = async () => {
    try {
      uiConsole("Logging out");
      if (!web3Auth) {
        uiConsole("web3auth not initialized yet logout");
        return;
      }
      await web3Auth.logout();
      setProvider(null);
      setConnected(false);
      setLoggedIn(false)

      // localStorage.clear()
      var Cookies = document.cookie.split(";");
      // set 1 Jan, 1970 expiry for every cookies
      for (var i = 0; i < Cookies.length; i++)
        document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
    } catch (ex) {
      console.log('ex while doing logout', ex)
    }
  };

  const handleSignin = async () => {
    try {
      if (web3Auth?.status === "connected") {
        const user = await web3Auth?.authenticateUser()
        const headers = {
          authorization: "Bearer " + user.idToken,
        }
        let privateKey = await getPrivateKey();
        console.log('user', user, provider, privateKey)
        //for social logins public key and for wallet login address
        if (privateKey) {
          let privateKey = await getPrivateKey();
          if (privateKey && typeof privateKey === 'string') {
            let secp256k1 = getPublicCompressed(Buffer.from(privateKey.padStart(64, "0"), "hex")).toString("hex");
            headers.appPubKey = secp256k1
            document.cookie = `secp256k1=${secp256k1}`;
          }
        } else {
          headers.address = address
          document.cookie = `address=${address}`;
        }


        await api.post(`user/cryptoSignup`, {
          walletAddress: address
        }, {
          headers,
          timeout: 30000,
        })

        if (user.idToken) {
          document.cookie = `token=${user.idToken}`;
        }

        setLoggedIn(true)

        toast.success('Authentication successful')
        // navigate('/dashboard');
        // window.location.href = '/dashboard';
      }
    } catch (ex) {
      if (ex?.code === 4001) {
        await logout()
        window.location.reload();
      } else if (ex?.message && ex?.message.includes(`\"exp\" claim timestamp check failed`) && cryptoSignupRetryCount < 2) {
        //For this particullar error, retrying again for 2 times, more than than loging out.
        cryptoSignupRetryCount += 1
        return handleSignin()
      } else if (ex?.message && ex?.message.includes(`\"exp\" claim timestamp check failed`))
        return logout()


      //If email existed account, then needs to logout the web3auth
      if (ex?.message && ex?.message.includes('Account already existed with this email'))
        logout()

      toast.error(ex?.message ?? somethingWrong)
    }
  }

  const getUserInfo = async () => {
    if (!web3Auth) {
      uiConsole("web3auth not initialized yet user info");
      return;
    }
    const userInfo = await web3Auth.getUserInfo();
    setUser(userInfo);
    uiConsole(userInfo);
    return userInfo;
  };

  const getAddress = async () => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return "";
    }
    const updatedAddress = await provider.getAddress();
    setAddress(updatedAddress);
    uiConsole(updatedAddress);
    return address;
  };

  const getBalance = async () => {
    if (!web3Auth) {
      uiConsole("web3auth not initialized yet get balance");
      return "";
    }
    const updatedBalance = await provider?.getBalance();

    setBalance(updatedBalance);
    uiConsole(updatedBalance);
    return balance;
  };

  const getSignature = async (message) => {
    if (!web3Auth) {
      uiConsole("web3auth not initialized yet get signature");
      return "";
    }
    const signature = await provider?.getSignature(message);
    uiConsole(signature);
    return signature;
  };

  const getSignTypeData = async (typedMessageData) => {
    if (!web3Auth) {
      uiConsole("web3auth not initialized yet sign type data");
      return "";
    }
    const signature = await provider?.getSignTypeData(typedMessageData);
    uiConsole(signature);
    return signature;
  };

  const sendTransaction = async (amount, destination) => {
    if (!web3Auth) {
      uiConsole("web3auth not initialized yet send transaction");
      return "";
    }
    const receipt = await provider?.sendTransaction(amount, destination);
    uiConsole(receipt);
    return receipt;
  };

  const getPrivateKey = async () => {
    try {
      if (!web3Auth) {
        uiConsole("web3auth not initialized yet");
        return "";
      }
      const privateKey = await provider?.getPrivateKey();
      return privateKey;
    } catch (ex) {
      uiConsole("Error while getting private key");
      return ''
    }
  };

  const getChainId = async () => {
    if (!web3Auth) {
      uiConsole("web3auth not initialized yet chain id");
      return "";
    }

    await provider?.getChainId();
  };

  const deployContract = async (abi, bytecode, initValue) => {
    if (!web3Auth) {
      uiConsole("web3auth not initialized yet deploy contract");
      return;
    }
    const receipt = await provider?.deployContract(abi, bytecode, initValue);
    return receipt;
  };

  const readContract = async (contractAddress, contractABI) => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return;
    }
    const message = await provider.readContract(contractAddress, contractABI);
    uiConsole(message);
  };

  const writeContract = async (contractAddress, contractABI, updatedValue) => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return;
    }
    const receipt = await provider.writeContract(contractAddress, contractABI, updatedValue);
    uiConsole(receipt);

    if (receipt) {
      setTimeout(async () => {
        await readContract(contractAddress, contractABI);
      }, 2000);
    }
  };

  const parseToken = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64 || ""));
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const verifyServerSide = async (idTokenInFrontend) => {
    try {

      uiConsole("Failed");
    } catch (e) {
      uiConsole(e);
    }
  };

  const contextProvider = {
    web3Auth,
    provider,
    user,
    isLoading,
    address,
    balance,
    chainId,
    playgroundConsole,
    connected,
    loggedIn,
    login,
    logout,
    getUserInfo,
    getAddress,
    getBalance,
    getSignature,
    getSignTypeData,
    sendTransaction,
    getPrivateKey,
    getChainId,
    deployContract,
    readContract,
    writeContract,
    verifyServerSide,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
