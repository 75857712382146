import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { calculateTimeRemaining } from '../../util/helper'

export function CountDown({ date }) {
    const [timeRemaining, setTimeRemaining] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        let timer = null
        if (date) {
            timer = setInterval(() => {
                const calculatedTime = calculateTimeRemaining(date)
                if (calculatedTime.days === 0 && calculatedTime.hours === 0 && calculatedTime.minutes === 0 && calculatedTime.seconds === 0) {
                    if (window.location.pathname.includes('/play'))
                        navigate(window.location.pathname.replace(/\/play$/, '') + '/decision')
                }
                setTimeRemaining(calculatedTime);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [date]); // Run the effect only once on component mount


    return <span style={{ fontWeight: "600" }}>
        <span>{timeRemaining.days}D </span>
        <span>{timeRemaining.hours}H </span>
        <span>{timeRemaining.minutes}M </span>
        <span>{timeRemaining.seconds}S</span>
    </span>
}