import { ReactSVG } from "react-svg";
import { MakaoCurrencyIcon, noImage } from "../../../../assets/images";
import { BadgeMakaoMoney, BadgeOdd, TextBadgeLightGray } from "../../../badge";
import { formatNumber } from "../../../../util/helper";
import './challenge.scss';

export function Challenge({
    asEvent,
    onClick,
    img,
    positionNumber = 1,
    amount = 0,
    changePercentage = 0,
    odd = 0,
    challengeVolume,
    refundTag,
    badgeType = 'PLAY-WIN', //PLAY-LOSS // ODD
    title = "Use table-auto to allow the table to automatically size columns to fit the contents of the cell." }) {

    return (
        <div onClick={onClick} className={`${asEvent ? 'event_container' : 'challenge_container'} ${onClick ? 'pointer' : ''}`} >
            <div className="text_left flex font_medium items_center icon_title_container">
                {asEvent ?
                    <div className="event_image"><img src={img ?? noImage} alt="event_img"
                        onError={(event) => {
                            event.target.src = noImage;
                        }} />
                    </div> :
                    <TextBadgeLightGray text={positionNumber} />}
                <div className="title_container truncate">
                    <div className="truncate title">{title}</div>
                    <div className=" currency_container">
                        {challengeVolume ? <>
                            {/* <ReactSVG className="svg_container currency_icon" src={MakaoCurrencyIcon} alt="img" /> */}
                            <span className="currency">$</span>
                            <div className="currency">{challengeVolume}</div> </> : <></>}
                        {refundTag ? <div className="refund_badge">Refunded</div> : <></>}
                    </div>
                </div>
            </div>
            <div className="badge_container">
                {
                    badgeType === 'PLAY-WIN' || badgeType === 'PLAY-LOSS' ?
                        <BadgeMakaoMoney colorVariantClass={badgeType === 'PLAY-LOSS' ? 'pink' : 'green'}
                            amount={formatNumber(amount)} />
                        : <></>
                }
                {
                    badgeType === 'ODD' ?
                        <BadgeOdd odd={odd} changePercentage={changePercentage} />
                        : <></>
                }

            </div>
        </div>)
}