import React, { useEffect } from "react";
import "./sidebar.scss";
import {
  HeartNotiSide,
  Home,
  Logout,
  EventPlusIcon,
  Plus,
  Star,
  UserSidebar,
  UserRed,
  Wallet,
  HeartBlueSidebar,
  MyEventsSideBar,
  UsersOutlinePink
} from "../../../assets/images";
import {
  NavLink,
} from "react-router-dom";
import { Switch } from "@mui/material";

import { useWeb3Auth } from "../../../context/useWeb3auth";
import { useDispatch, useSelector } from "react-redux";
import { UserRoles } from "../../../util/helper";
import { fetchUserAction } from "../../../store/actions/userAction";

const label = { inputProps: { "aria-label": "Color switch demo" } };


const Sidebar = ({ open, handleClickBars }) => {
  const { logout, loggedIn } = useWeb3Auth()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.user)

  useEffect(() => {
    if (loggedIn)
      dispatch(fetchUserAction())
  }, [loggedIn])

  const tabItems = [
    {
      name: "Home",
      url: "/dashboard",
      icon: <Home />,
      iconActive: <Home />,
    },
    {
      name: "My profile",
      url: "/profile",
      icon: <UserSidebar />,
      iconActive: <UserSidebar />,
    },
    {
      name: "Wallet",
      url: "/deposit",
      icon: <Wallet />,
      iconActive: <Wallet />,
    },
    {
      name: "Leaderboard",
      url: "/leaderboards",
      icon: <Star />,
      iconActive: <Star />,
    },
    {
      name: "Create event",
      url: "/create-event",
      icon: <EventPlusIcon />,
      iconActive: <Plus />,
    },
    {
      name: "My events",
      url: "/events",
      icon: <MyEventsSideBar />,
      iconActive: <MyEventsSideBar />,
    },
    {
      name: "Notifications",
      url: "/notification",
      icon: <HeartBlueSidebar />,
      iconActive: <HeartBlueSidebar />,
    },
    {
      name: "Blacklisted",
      url: "/black-listed",
      icon: <UsersOutlinePink />,
      iconActive: <UsersOutlinePink />,
    },
    // {
    //   name: "Admin",
    //   url: "/administrator/manage-users",
    //   icon: <UserSidebar />,
    //   iconActive: <UserSidebar />,
    // },
    // {
    //   name: "Screens",
    //   url: "/screens",
    //   icon: <HeartNotiSide />,
    //   iconActive: <HeartNotiSide />,
    // },
  ];


  const handleTabClick = (tabName) => {
    if (window.innerWidth < 540) {
      handleClickBars();
    }
  };

  async function handleLogout() {
    try {
      await logout()

      // console.log('web3auth.connected', web3auth.connected, web3auth.logout)

      // setToken('');
      // localStorage.removeItem("token");
      // removeCookie('token')
      // removeCookie('secp256k1')
      // removeCookie('address')
      // navigate("/signup");

      // if (web3auth.connected) {
      //   const logout = await web3auth.logout();
      //   console.log('lgou', logout)
      // }

      // window.location.reload();
    } catch (ex) {
      console.log("error", ex)
    }
  }

  return (
    <>
      <div className={`sidebar__content ${open ? "sidebar__content" : ""} scrollbar_invisible`}>
        <ul>
          {tabItems.map((tab, index) => (
            <NavLink key={index} to={tab.url} >
              <li key={index} onClick={() => handleTabClick(tab.name)}>
                <span style={{ paddingTop: "4px", width: "25px" }}>
                  {tab.icon}
                </span>
                <span>{tab.name}</span>
              </li>
            </NavLink>
          ))}
          {
            user?.role === UserRoles.admin ?
              <NavLink to={'/administrator/manage-users'} >
                <li onClick={() => handleTabClick('Admin')}>
                  <span style={{ paddingTop: "4px", width: "25px" }}>
                    <UserSidebar />
                  </span>
                  <span>{'Admin'}</span>
                </li>
              </NavLink>
              : <></>
          }
          {loggedIn && (
            <a>
              <li
                onClick={handleLogout}
                className=" pointer"
              >
                <span>
                  <Logout style={{ width: "25px" }} />
                </span>
                <span >
                  Logout
                </span>
              </li>
            </a>
          )}

          <div className=" switch_container">
            <div className=" flex_center_col button_container">
              <div>Switch to light mode</div>
              <div>
                <Switch {...label} defaultChecked />
              </div>
            </div>
            <div className=" flex_center_col button_container">
              <div>Switch to fake tokens</div>
              <div>
                <Switch {...label} defaultChecked />
              </div>
            </div>
            <div className=" flex_center_col span_2 button_container">
              <div>Switch to help mode</div>
              <div>
                <Switch {...label} defaultChecked />
              </div>
            </div>
          </div>
        </ul>
      </div >
    </>
  );
};

export default Sidebar;
