import { Typography } from "@mui/material";
import { DollarDark, I, noImage } from "../../../assets/images";
import { formatNumber } from "../../../util/helper";
import Button from "../../button/Button";
import { get } from "lodash";
import "./reviewAndClaim.scss";
import { useState } from "react";
import Proposal from "../../infoPopups/proposal/Proposal";
import MaxYield from "../../infoPopups/maxYield/MaxYield";
import MyPlay from "../../infoPopups/myplay/MyPlay";
import { BadgeMakaoMoney } from "../../badge";

export default function ReviewAndClaim({
  currentPage = "EVENT",
  setShow,
  challengesList = [],
  eventList = [],
}) {
  const renderCondition = (currentPage === "EVENT" && challengesList.length !== 0) || (currentPage === "WALLET" && eventList.length !== 0);

  const [openProposal, setOpenProposal] = useState({
    challenges: false,
    myPlay: false,
    myWins: false,
  });
  const handleToggleChallenge = () => setOpenProposal((p) => ({ ...p, challenges: !p.challenges }));
  const handleTogglePlays = () => setOpenProposal((p) => ({ ...p, myPlay: !p.myPlay }));
  const handleToggleWins = () => setOpenProposal((p) => ({ ...p, myWins: !p.myWins }));
  return (
    <div>
      <Proposal
        handleClose={handleToggleChallenge}
        open={openProposal.challenges}
      />
      <MyPlay handleClose={handleTogglePlays} open={openProposal.myPlay} />
      <MaxYield handleClose={handleToggleWins} open={openProposal.myWins} />
      <Typography
        id="modal-modal-title"
        className="review_header"
        variant="h6"
        component="h2"
      >
        Review & Claim {currentPage === "WALLET" && "all"}
      </Typography>

      <p className="review_info">
        This will review and claim
        {currentPage === "WALLET"
          ? "all terminated event rewards."
          : " terminated predictions in this event."}
      </p>
      {renderCondition ? (
        <div className="table_container" style={{ overflowX: 'hidden' }}>
          <table className="table_fixed" >
            <thead>
              <tr className="table_row table_header review_table_head">
                <th className="text_left padding_top_bottom">
                  <p className="relative w_fit">
                    {currentPage === "WALLET" ? "Events" : "Predictions"}
                  </p>
                </th>
                <th className="w_7rem badge_container_last text_right">
                  <p className="relative w_fit ml-auto">
                    To claim
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPage === "EVENT" &&
                challengesList.map((challenge, ind) => (
                  <tr key={challenge.title ?? ind} className="table_row">
                    <td className="text_left flex font_medium items_center padding_top_bottom title_container col-gap-9px">
                      <div className="challenge_serial ">{++ind}</div>
                      <div className="truncate">{challenge.title}</div>
                    </td>
                    <td className="w_7rem">
                      <div className="ml-auto w_fit">
                        {challenge?.playStatus === "LOSS" ?
                          <BadgeMakaoMoney colorVariantClass="pink" amount={0} />
                          : <BadgeMakaoMoney amount={challenge?.claimableAmount && challenge?.claimableAmount > 0 ? formatNumber(challenge.claimableAmount) : 0} />}
                      </div>
                    </td>
                  </tr>
                ))}
              {currentPage === "WALLET" &&
                eventList.map((event, ind) => (
                  <tr key={event.eventName ?? ind} className="table_row">
                    <td className="text_left flex font_medium items_center padding_top_bottom col-gap-9px">
                      <div className="event_image">
                        <img
                          src={get(event, "eventImg") ?? noImage}
                          alt="event_img"
                          onError={(event) => {
                            event.target.src = noImage;
                          }}
                        />
                      </div>
                      <div className="truncate">{event.eventName}</div>
                    </td>

                    <td className="w_7rem ">
                      <div className="ml-auto w_fit">
                        {event?.eventAverageStatus === "LOSS" ?
                          <BadgeMakaoMoney colorVariantClass="pink" amount={formatNumber(event.eventAveragePotentialWin)} />
                          : <BadgeMakaoMoney amount={formatNumber(event.eventAveragePotentialWin)} />}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>All events are already reviewed.</div>
      )}
      <div className="action_container">
        {renderCondition ? (
          <Button onClick={() => setShow("REVIEW")} colorVariant="light_gray">Review all</Button>
        ) : (
          <Button onClick={() => setShow("CLAIM")} colorVariant="light_gray">Proceed To Claim</Button>
        )}
      </div>
    </div>
  );
}
