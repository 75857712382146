import React, { useState } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Signup from "../components/signup/Signup";
import Dash__content from "../components/dashboard/dash__content/Dash__content";
import Create__Event from "../components/create_event/Create__Event";
import Profile from "../components/profile/Profile";
import AllLeaderBoards from "../components/allLeaderBoards/AllLeaderBoards";
import Events from "../components/games/Games";
import Notification from "../components/notification/Notification";
import BlackList from "../components/blackListed/BlackList";
import Header from "../components/dashboard/header/Header";
import Sidebar from "../components/dashboard/sidebar/Sidebar";
import CategoryEvents from "../components/categoryEvents";
import Event__Page from "../components/Event__page/Event__Page";
import AdminstatorPage from "../components/adminesterPage/AdminstatorPage";
import Deposit from "../components/wallet/Deposit";
import AllPopups from "../components/AllPop_ups/AllPopups";
import Screens from "../components/AllScreens/Screens";
import EditProfile from "../components/profile/EditProfile";
import ProfileStats from "../components/profileStats/ProfileStats";
import NotificationSettings from "../components/notification/NotificationSettings";
import Stapper from "../components/stapper/Stapper";
import MoreforThisEvent from "../components/moreForThisEvent/MoreforThisEvent";
import ScanQRCode from "../components/scanQR/ScanQRCode";
import ConnectWallet from "../components/connectWallet/ConnectWallet";
import TrendingViewAll from "../components/trendingNowViewAll/TrendingViewAll";
import FriendsBetLeader from "../components/trendingNowViewAll/FriendsBetLeader";
import { EventPlayTime } from "../components/eventPlayTime";
import { EventDecision } from "../components/eventDecision";
import OtherUserProfile from "../components/profile/OtherUserProfile";
import EditEvent from "../components/EditEvent";
import ProtectedComponent from "./ProtectedComponent";
import { BiggestPredictionsAll } from "../components/PredictionPage";
import TimeAlmostUpAll from "../components/TimeAlmostUpAll";
import { ChallengesReviewAndClaim } from "../components/challengesReviewAndClaim";


const HomeRoutes = () => {
  const [open, setOpen] = useState(true);

  const handle = () => {
    setOpen((preVal) => !preVal);
  };

  const location = useLocation();

  return (
    <>
      {
        location.pathname !== "/signup" && <Header handleClickBars={handle} />
      }
      <div className="layout">
        {location.pathname !== "/signup" &&
          <div className={open ? "sidebar" : "sidebar__mbl"}>
            <Sidebar handleClickBars={handle} open={open} setOpen={setOpen} />
          </div>
        }
        <div className={open ? `dash ${location.pathname === "/signup" && "dash_100"}` : "dash__mbl"}>
          {location.pathname === "/screens" && (
            <div className="popupBtn">
              <Link to="/popups">All Pop-ups</Link>
            </div>
          )}
          <Routes>
            {/* <Route path="/signup/home" element={<Dash__content />} /> */}
            <Route path="/" element={<Navigate to={"/dashboard"} />} />
            <Route path="/signup/*" element={<Signup />} />
            <Route path="/dashboard" element={<Dash__content />} />
            <Route path="/trending-detail" element={<TrendingViewAll />} />
            <Route path="/time-almost-up" element={<TimeAlmostUpAll />} />

            <Route path="/predictions" element={<BiggestPredictionsAll />} />

            <Route path="/create-event" element={<Create__Event />} />
            <Route path="/event/:_id" element={<Event__Page />} />
            <Route path="/event/:_id/edit" element={<EditEvent />} />
            <Route path="/events/:category/:_id" element={<CategoryEvents />} />

            {/*new route for play time*/}
            {/*new route for event decision*/}
            <Route path="/event/:_id/play" element={<ProtectedComponent><EventPlayTime /></ProtectedComponent>} />
            <Route path="/event/:_id/decision" element={<ProtectedComponent><EventDecision /></ProtectedComponent>} />

            <Route path="/events" element={<ProtectedComponent> <Events /></ProtectedComponent>} />

            <Route path="/profile" element={<ProtectedComponent><Profile /></ProtectedComponent>} />
            <Route path="/profile/:_id" element={<ProtectedComponent><OtherUserProfile /> </ProtectedComponent>} />
            <Route path="/edit-profile" element={<ProtectedComponent><EditProfile /></ProtectedComponent>} />

            <Route path="/deposit" element={<ProtectedComponent><Deposit /></ProtectedComponent>} />
            <Route path="/reviews-and-claim" element={<ProtectedComponent><ChallengesReviewAndClaim /></ProtectedComponent>} />

            <Route path="/leaderboards" element={<ProtectedComponent><AllLeaderBoards /></ProtectedComponent>} />

            <Route path="/black-listed" element={<ProtectedComponent><BlackList /></ProtectedComponent>} />

            <Route path="/notification" element={<ProtectedComponent><Notification /></ProtectedComponent>} />

            <Route path="/administrator/*" element={<AdminstatorPage />} />
            <Route path="/popups" element={<AllPopups />} />
            <Route path="/screens" element={<Screens />} />
            <Route path="/profile-stats" element={<ProfileStats />} />
            <Route path="/stapper" element={<Stapper />} />
            <Route path="/more-for-this-event" element={<MoreforThisEvent />} />
            <Route
              path="/notification-settings"
              element={<NotificationSettings />}
            />
            <Route path="/scan-QR" element={<ScanQRCode />} />
            <Route
              path="/connect-wallet-or-create"
              element={<ConnectWallet />}
            />
            <Route path="/friends-play-detail" element={<FriendsBetLeader />} />
        
          </Routes>
        </div>
      </div>
    </>
  );
};

export default HomeRoutes;
