import React from "react";
import "./connect.scss";
import { Link } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import {
  Circle_meta,
  Close,
  Metamask,
  rainbow_meta,
} from "../../assets/images";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ConnectWallet = ({ handleClose, open }) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  return (
    <>
      {/* <Button onClick={handleOpen}>Connect Wallet or Create Wallet </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="wallet__popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="modal__title"
          >
            Connect wallet
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="input__box">
              <div className="input__groups__wallet">
                <label>
                  <img src={Metamask} alt="" />
                </label>
                <label> MetaMask </label>
              </div>
              <div className="input__groups__wallet">
                <label>
                  <img src={Circle_meta} alt="" />
                </label>
                <label> WalletConnect </label>
              </div>
              <div className="input__groups__wallet">
                <label>
                  <img src={rainbow_meta} alt="" />
                </label>
                <label> WalletConnect </label>
              </div>
            </div>
            <p style={{ color: "white", padding: "1rem 0" }}> Or </p>
            <button className="popups__btn"> Create a wallet </button>
            <Typography className="already">
              If you already created your Metamask wallet,
              <Link to="">please download and login on Metamask</Link> to access
              your account here.
            </Typography>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ConnectWallet;
