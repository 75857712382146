import React, { useEffect } from "react";
import "./trending.scss";
import { useNavigate } from "react-router-dom";
import PlayersPopup from "../Popups/players/PlayersPopup";
import { Loader } from "../loader";
import { fetchFriendsEventsAction } from "../../store/actions/eventAction";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import EventCard from "../eventCard";

const FriendsBetLeader = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { events, status, page, pageSize, disableLoadMore } = useSelector((state) => state.event.friendsEvents);


  useEffect(() => {
    if (Array.isArray(events) && events.length === 0)
      handleFriendsFetchEvents({ page, pageSize })
  }, [])

  async function handleFriendsFetchEvents() {
    try {
      dispatch(fetchFriendsEventsAction({ page, pageSize }))
    } catch (ex) {
      toast.error(somethingWrong)
    }
  }


  return (
    <>
      <Loader loading={status === 'loading'} />
      <PlayersPopup handleClose={handleClose} open={open} />
      <h1 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        {" "}
        Your friend participated{" "}
      </h1>
      <div className="trending_images">
        {events.map((val, ind) => (
          <div key={ind} style={{ width: '100%', minWidth: "100%" }}>
            <EventCard data={val} />
          </div>
        ))}
        {/* {Cards.map((el) => (
          <>
            <div className="images">
              <img
                src={el.imgOne}
                alt="img"
                onClick={() => navigate("/event/eventid")}
                className="bg"
              />
              <div className="card_top_images">
                <span className="alram">
                  <img src={el.imgTwo} alt="" />
                </span>
                <span className="usertesti" onClick={handleOpen}>
                  <img src={el.imgThree} alt="" className="userM" />
                  <h6> 10k </h6>
                </span>
                <span className="dark" onClick={handleOpen}>
                  <img src={el.imgFour} alt="" />
                  <h6> 415k </h6>
                </span>
                <span
                  className="dots"
                  onClick={() => navigate("/more-for-this-event")}
                >
                  <img src={el.imgFive} alt="" />
                </span>
              </div>
              <div className="text" style={{ left: "7rem" }}>
                <p> {el.title} </p>
              </div>
            </div>
          </>
        ))} */}
      </div>
      <div className=" loadmore__container">
        <button disabled={disableLoadMore} onClick={handleFriendsFetchEvents} className="btn">Load More</button>
      </div>
    </>
  );
};

export default FriendsBetLeader;
