import { createSlice } from '@reduxjs/toolkit'
import { fetchOtherUserAction, fetchUserAction, isBlacklistedUserAction, unblacklistUserAction, updateUserAction, updateUserBlacklistAction, updateUserImgAction } from '../actions/userAction'

const initialState = {
  user: {},
  otherUser: {},
  status: 'idle',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoadingAction: (state, action) => {
      if (action?.payload)
        state.status = action?.payload
    },
    updateUserStateAction: (state, action) => {
      if (action?.payload)
        state.user = action?.payload
    },
    updateUserStateWithObjAction: (state, action) => {
      if (action?.payload)
        state.user = { ...state.user, ...action?.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchUserAction.rejected, (state, action) => {
        state.status = 'failed';
      })

      .addCase(fetchOtherUserAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOtherUserAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.otherUser = action.payload;
      })
      .addCase(fetchOtherUserAction.rejected, (state, action) => {
        state.status = 'failed';
      })

      .addCase(updateUserAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.status = 'failed';
      })


      .addCase(updateUserImgAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserImgAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(updateUserImgAction.rejected, (state, action) => {
        state.status = 'failed';
      });

    builder
      .addCase(updateUserBlacklistAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserBlacklistAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(updateUserBlacklistAction.rejected, (state, action) => {
        state.status = 'failed';
      });

    builder
      .addCase(unblacklistUserAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(unblacklistUserAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(unblacklistUserAction.rejected, (state, action) => {
        state.status = 'failed';
      });

    builder
      .addCase(isBlacklistedUserAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(isBlacklistedUserAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.isBlacklisted = action.payload.isBlacklisted;
      })
      .addCase(isBlacklistedUserAction.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
})

export const { userLoadingAction, updateUserStateAction, updateUserStateWithObjAction } = userSlice.actions

export default userSlice.reducer