import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MaxYield = ({ handleClose, open }) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <>
      {/* <Button onClick={handleOpen}> Info Max yield </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <Typography
            id="modal-modal-title"
            className="infoPopup_heading"
            variant="h6"
            component="h2"
            style={{ fontSize: "24px" }}
          >
            Max yield
          </Typography>
          <Typography
            id="modal-modal-title-desc"
            variant="h6"
            component="h2"
            style={{ fontSize: "16px", fontWeight: "400" }}
          >
            This is the maximum participate multiplier you can get. This coefficient
            evolves during participate time if new participants enter the event. Max yield
            is realised if only one prediction success the event.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default MaxYield;
