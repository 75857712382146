import React, { createContext, useContext } from "react";
import { WagmiProvider, createConfig, http, useAccount, useConnect, useDisconnect } from "wagmi";
import Web3AuthConnector from "../config/Web3AuthConnectorInstance";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { sepolia, polygon } from "wagmi/chains";

export const currentNodeEnv = process.env.REACT_APP_VERCEL_ENV ? process.env.REACT_APP_VERCEL_ENV : process.env.NODE_ENV
export const currentBlockChain = currentNodeEnv === 'production' ? polygon : sepolia


console.log('wagmi cure', currentBlockChain)
// Create a context to hold the WagmiProvider instance and related functionality
const WagmiContext = createContext();

// Custom hook to access the WagmiProvider instance and related functionality from context
export const useWagmi = () => useContext(WagmiContext);

// Wagmi provider component to initialize and manage the WagmiProvider instance
export const WagmiProviderWrapper = ({ children }) => {
    const Web3AuthConnectorInstance = Web3AuthConnector();
    // Set up client
    const config = createConfig({
        chains: [currentBlockChain],
        transports: {
            [currentBlockChain.id]: http(),
        },
        connectors: [
            Web3AuthConnectorInstance,
        ],
    });

    const queryClient = new QueryClient();

    // Pass client to React Context Provider
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <WagmiContext.Provider value={{ useAccount, useConnect, useDisconnect }}>
                    {children}
                </WagmiContext.Provider>
            </QueryClientProvider>
        </WagmiProvider>
    );
};
