import React from "react";
import "./stacking.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Close } from "../../../assets/images";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Stacking = ({ handleClose, open }) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  return (
    <>
      {/* <Button onClick={handleOpen}> Staking pop-up </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popup">
          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            Staking
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="input__groups__stacking">
              <label>
                Staking balance
                <span
                  style={{
                    color: "#CAE9FF",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  176 MAKS
                </span>
              </label>
              <p> Fully unlocked on 12/11/2024 </p>
            </div>
            <div className="input__groups__stacking_two">
              <label>
                Amount to stake <span> MAKS </span>
              </label>
              <p> Your Fame will be automatically locked for a full year </p>
            </div>
            <div className="extrafee">
              <span>No extra Fees</span>
              <span>0 %</span>
            </div>
            <button className="popups__btn_st"> Stake </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Stacking;
