import React from "react";
import { I } from "../../assets/images";
import { formatNumber } from "../../util/helper";
import "./trustNote.scss";

function TrustNoteProgressBar({
  averageTrustScore = "2.5",
  trustNoteRatingOutOf = "5",
  className,
  style,
}) {
  return (
    <>
      <div className={"trust__note " + className} style={style}>
        <p>
          Trust note : {formatNumber(averageTrustScore)} /{" "}
          {trustNoteRatingOutOf}
        </p>
        <span
          style={{
            width: `${averageTrustScore * 20}%`,
          }}
          className="trust_note_progress_bar"
        ></span>
      </div>
    </>
  );
}

export default TrustNoteProgressBar;
