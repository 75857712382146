import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../util/api';

// Define the async thunk to fetch data from the API
export const fetchCategoriesAction = createAsyncThunk('util/fetchCategories', async () => {
    try {
        const response = await api.get('category?status=true');
        return response.data;
    } catch (error) {
        throw error;
    }
});