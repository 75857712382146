import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
// import { OPENLOGIN_NETWORK, OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { TorusWalletAdapter } from "@web3auth/torus-evm-adapter";
import { CoinbaseAdapter } from "@web3auth/coinbase-adapter";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { WalletConnectV2Adapter } from "@web3auth/wallet-connect-v2-adapter";
import { currentBlockChain, currentNodeEnv } from "../context/useWagmiConnector";

const currentWeb3Network = currentNodeEnv === 'production' ? WEB3AUTH_NETWORK.SAPPHIRE_MAINNET : WEB3AUTH_NETWORK.SAPPHIRE_DEVNET

export const factoryContractAddress = process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS;
export const tokenAddress = process.env.REACT_APP_USDC_TOKEN_ADDRESS; // Placeholder ERC20 token address
export const tokenEthAddress = process.env.REACT_APP_ETH_TOKEN_ADDRESS;

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;
const appName = "makao";

const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x" + currentBlockChain.id.toString(16),
    rpcTarget: currentBlockChain.rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
    displayName: currentBlockChain.name,
    tickerName: currentBlockChain.nativeCurrency?.name,
    ticker: currentBlockChain.nativeCurrency?.symbol,
    blockExplorerUrl: currentBlockChain.blockExplorers?.default.url[0],
};


const ethereumPrivateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

// Create Web3Auth Instance
const web3AuthInstance = new Web3Auth({
    clientId,
    chainConfig,
    sessionTime: 604800,
    web3AuthNetwork: currentWeb3Network,
    privateKeyProvider: ethereumPrivateKeyProvider,
    uiConfig: {
        appName,
        mode: "dark",
        uxMode: 'redirect',
        logoDark: `${'https://makao-api-48so.onrender.com/'}MAKAO.svg`,
        theme: {
            primary: '#baeac8',
        },
    },
});


const torusWalletAdapter = new TorusWalletAdapter({
    web3AuthNetwork: currentWeb3Network,
    initParams: {
        whiteLabel: {
            theme: {
                isDark: true,
                colors: { torusBrand1: "#baeac8" },
            },
            logoLight: `${'https://makao-api-48so.onrender.com/'}MAKAO.svg`,
            logoDark: `${'https://makao-api-48so.onrender.com/'}MAKAO.svg`,
        },
    }
});

web3AuthInstance.configureAdapter(torusWalletAdapter);

const coinbaseAdapter = new CoinbaseAdapter({});
web3AuthInstance.configureAdapter(coinbaseAdapter);

const metamaskAdapter = new MetamaskAdapter({});
web3AuthInstance.configureAdapter(metamaskAdapter);

const walletConnectV2Adapter = new WalletConnectV2Adapter({
    clientId,
    chainConfig,
    sessionTime: 604800,
    web3AuthNetwork: currentWeb3Network,
    privateKeyProvider: ethereumPrivateKeyProvider,
    uiConfig: {
        appName,
        mode: "dark",
        logoDark: `${'https://makao-api-48so.onrender.com/'}MAKAO.svg`,
        theme: {
            primary: '#baeac8',
        },
    },
});

web3AuthInstance.configureAdapter(walletConnectV2Adapter);

export { web3AuthInstance }

