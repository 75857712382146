import React from "react";
import "./scan.scss";
import { LeftChevron, ScanQR } from "../../assets/images";
import { Link } from "react-router-dom";

const ScanQRCode = () => {
  //Commented code of now for reffernce, will remove later 

  return (
    <>
      <div>
        <Link to="/screens" className="more__btn_scan">
          <span>
            <img src={LeftChevron} alt="" />
          </span>
          <span> Share with QR code </span>
        </Link>
      </div>
      <div className="QR">
        <img src={ScanQR} alt="" />
      </div>
    </>
  );
};

export default ScanQRCode;
