import './biggestPredictions.scss'

import { useEffect, useState } from "react";
import { Challenge } from "../Popups/reviewDecision/challenge";
import { TabsComponent } from "../TabsComponent";
import { toast } from "react-toastify";
import { somethingWrong } from "../../util/helper";
import api from "../../util/api";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

import { Loader } from "../loader";

export function BiggestPredictions({ usingOn }) {
    const navigate = useNavigate()

    const [currentTab, setCurrentTab] = useState('volume') // odd
    const [volumePredictions, setVolumePredictions] = useState([])
    const [oddPredictions, setOddPredictions] = useState([])
    const [pageSize] = useState(usingOn === 'PREDICATIONS_ALL' ? 30 : 9)
    const [volumePage, setVolumePage] = useState(1)
    const [disableLoadMoreVolume, setDisableLoadMoreVolume] = useState(false)
    const [oddPage, setOddPage] = useState(1)
    const [disableLoadMoreOdd, setDisableLoadMoreOdd] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (currentTab === 'volume')
            handleFetchPredictionsVolume()
        if (currentTab === 'odd')
            handleFetchPredictionsOdd()

    }, [currentTab])

    async function handleFetchPredictionsVolume() {
        try {
            setLoading(true)
            const response = await api.get(`challenge/predictions?page=${volumePage}&pageSize=${pageSize}&sortAt=totalVolume`)
            if (usingOn === 'PREDICATIONS_ALL') {
                setVolumePredictions(prev => prev.concat(response?.data))
                setVolumePage(response?.page + 1)
                if (response?.data?.length < pageSize)
                    setDisableLoadMoreVolume(true)
            }
            else
                setVolumePredictions(response?.data)
        } catch (ex) {
            toast.error(ex?.message ?? somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    async function handleFetchPredictionsOdd() {
        try {
            setLoading(true)
            const response = await api.get(`challenge/predictions?page=${oddPage}&pageSize=${pageSize}&sortAt=odd`)

            if (usingOn === 'PREDICATIONS_ALL') {
                setOddPredictions(prev => prev.concat(response?.data))
                setOddPage(response?.page + 1)
                if (response?.data?.length < pageSize)
                    setDisableLoadMoreOdd(true)
            }
            else
                setOddPredictions(response?.data)
        } catch (ex) {
            toast.error(ex?.message ?? somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="biggest_predictions_container">
            <Loader loading={loading} />
            <div className=" tabs_container">
                <TabsComponent setTab={setCurrentTab} currentTab={currentTab} />
            </div>

            <div className="challenges_container">
                {currentTab === 'volume' ?
                    volumePredictions?.map((val, ind) => (
                        <Challenge
                            onClick={() => navigate(`/event/${val?.event}`)}
                            key={ind}
                            title={val?.title}
                            challengeVolume={get(val, 'plays[0].totalVolume')}
                            changePercentage={0}
                            odd={val?.odd}
                            badgeType="ODD"
                            positionNumber={ind + 1}
                        />))
                    : currentTab === 'odd' ?
                        oddPredictions?.map((val, ind) => (
                            <Challenge
                                onClick={() => navigate(`/event/${val?.event}`)}
                                key={ind}
                                title={val?.title}
                                challengeVolume={get(val, 'plays[0].totalVolume')}
                                changePercentage={0}
                                odd={val?.odd}
                                badgeType="ODD"
                                positionNumber={ind + 1}
                            />))
                        : <></>}
            </div>

            {usingOn === 'PREDICATIONS_ALL' ?
                <div className=" loadmore__container">
                    <button onClick={() => currentTab === 'volume' ? handleFetchPredictionsVolume() : handleFetchPredictionsOdd()}
                        disabled={(currentTab === 'volume' && disableLoadMoreVolume) || (currentTab === 'odd' && disableLoadMoreOdd)} className="btn">Load More</button>
                </div> : <></>}

        </div>
    )
}