import { useEffect, useMemo, useState } from "react";
import PossibleOutcomes from "../Popups/possibleOutcomes/PossibleOutcomes";
import moment from "moment";
import { toast } from "react-toastify";
import { formatNumber, somethingWrong } from "../../util/helper";
import { find, findIndex, get, includes, set } from "lodash";
import {
    ChevronDownPink,
    ChevronUpCyan,
    Dollar,
    I,
    PlusGames,
    UpArrow,
    DownArrowPink,
} from "../../assets/images";
import Place_a_bet_popup from "../Popups/place_a_bet-popup/Place_a_bet_popup";
import "../Event__page/event__page.scss";
import MaxYield from "../infoPopups/maxYield/MaxYield";
import MyPlay from "../infoPopups/myplay/MyPlay";
import Info_Possible_outcomes from "../infoPopups/Info Possible outcomes/Info_Possible_outcomes";
import Proposal from "../infoPopups/proposal/Proposal";
import { useDispatch, useSelector } from "react-redux";
import { updateEventAction, updateEventChallengeAction, updateEventLoadingStatus, updateEventWithObjAction } from "../../store/slices/eventSlice";
import api from "../../util/api";
import RefundChallengeOrPlayer from "../Popups/cancel&refund/RefundChallenge";
import { useReadContract, useSignTypedData } from 'wagmi'
import { useWeb3Auth } from "../../context/useWeb3auth";
import { makaoFixtureAbi } from "../../abi/makaoFixtureAbi";
import { ArrowsComponent, BadgeMakaoMoney, BadgeOdd, TextBadge } from "../badge";
import { ReactSVG } from "react-svg";
import { currentBlockChain } from "../../context/useWagmiConnector";

export function ChallengeSection(
    { challenges, type = 'DEFAULT', onSelectChallenge, data, isBlacklisted = false }) {

    const { signTypedDataAsync } = useSignTypedData()
    const dispatch = useDispatch()
    const event = useSelector((state) => state.event.event);
    const { loggedIn } = useWeb3Auth()

    const { eventId, selectedChallengeIds } = data ?? {}

    const [openPossibleOutcome, setOpenPossibleOutcome] = useState(false);
    const handleOpenPossibleOutcome = () => !isBlacklisted ? setOpenPossibleOutcome(true) : toast.error("Event's Organiser blocked you, You can't interact with his events.");
    const handleClosePossibleOutcome = () => setOpenPossibleOutcome(false);

    const [openOutcome, setOpenOutcome] = useState(false);
    const handleCloseOutcome = () => setOpenOutcome(false);

    const [openMyPlay, setOpenMyPlay] = useState(false);
    const handleOpenMyPlay = () => !isBlacklisted ? setOpenMyPlay(true) : toast.error("Event's Organiser blocked you, You can't interact with his events.");
    const handleCloseMyPlay = () => setOpenMyPlay(false);

    const [openMaxYield, setOpenMaxYield] = useState(false);
    const handleOpenMaxYield = () => !isBlacklisted ? setOpenMaxYield(true) : toast.error("Event's Organiser blocked you, You can't interact with his events.");
    const handleCloseMaxYield = () => setOpenMaxYield(false);

    const [openMakePlay, setOpenMakePlay] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState({})

    const [openProposal, setOpenProposal] = useState(false);
    const handleOpenProposal = () => !isBlacklisted ? setOpenProposal(true) : toast.error("Event's Organiser blocked you, You can't interact with his events.");
    const handleCloseProposal = () => setOpenProposal(false);

    const [selectedRefundChallenge, setSelectedRefundChallenge] = useState({})
    const [loading, setLoading] = useState(false)

    const [creatingChallenge, setCreatingChallenge] = useState({ title: '', logic: '' })

    const isDuringPlay = moment().isBefore(event?.endTime);

    const { data: digest, status } = useReadContract({
        address: event?.blockChain?.contractAddress,
        abi: makaoFixtureAbi,
        functionName: 'getDigestFromDescription',
        args: [creatingChallenge?.title]
    })

    useEffect(() => {
        console.log('digest', digest, status, creatingChallenge)
        if (creatingChallenge?.title && digest && status === 'success') {
            createChallenge(creatingChallenge)
        }
    }, [creatingChallenge?.title, status])

    const handleOpenOutcome = () => {
        if (loggedIn) {
            if (isBlacklisted)
                toast.error("Event's Organiser blocked you, You can't interact with his event.");
            else
                setOpenOutcome(true)
        } else
            toast.error("Please login")
    }

    const handleOpenMakePlay = (challenge) => {
        const { startTime, endTime, decisionTakenTime } = event ?? {}
        if (loggedIn) {
            if (isBlacklisted)
                toast.error("Event's Organiser blocked you, You can't interact with his event.");
            else {
                if (decisionTakenTime)
                    toast.info('Event is over!')
                else if (moment().isBetween(moment(startTime), moment(endTime))) {
                    setOpenMakePlay(true);
                    setSelectedChallenge(challenge)
                } else {
                    toast.error('Participation is only allowed between the start time and end time!')
                }
            }
        }
        else
            toast.error("Please login")
    };

    const handleProposalAccept = async (_id) => {
        if (!isBlacklisted) {
            try {
                dispatch(updateEventLoadingStatus('loading'))
                await api.patch(`/challenge/${_id}`, { status: true })

                const foundIndex = findIndex(event.challenges, (o) => o?._id === _id)
                if (foundIndex > -1) {
                    const rawChallenges = [...event.challenges]
                    set(rawChallenges, `[${foundIndex}]`, { ...rawChallenges[foundIndex], status: true })
                    dispatch(updateEventAction({ ...event, challenges: rawChallenges }))

                    toast.success('Prediction Accepted successfully')
                }
            } catch (ex) {

            } finally {
                dispatch(updateEventLoadingStatus('succeeded'))
            }
        }
    };

    const onSubmitChallenge = async (challenge) => {
        const found = find(challenges, (o) => o?.title === challenge?.title)
        if (found && found?.title)
            toast.error('Please add a unique name Prediction')
        else {
            setLoading(true)
            setCreatingChallenge(challenge)
        }
    }

    const createChallenge = async (challenge) => {
        try {
            setLoading(true)
            const signedSignature = await createTypeSignedMessage(challenge?.title)
            if (!signedSignature)
                return toast.error('Unable to create a signed signature! Please refresh and try again.')

            const newChallengeRes = await api.post('challenge', { ...challenge, event: eventId, createdBy: event?.createdBy, signedSignature, digest })
            if (newChallengeRes?.data)
                dispatch(updateEventChallengeAction(newChallengeRes?.data))

            toast.success('New prediction added successfully')
            handleCloseOutcome()
            setCreatingChallenge({ title: '', logic: '' })
        } catch (ex) {
            console.log('ex', ex)
            toast.error(somethingWrong)
        } finally {
            setLoading(false)
        }
    }

    async function createTypeSignedMessage(challengeString) {
        try {
            // Define the domain and type
            const domain = {
                name: 'challenges.makaoapp.com',
                version: '1',
                chainId: currentBlockChain.id, // Replace with the appropriate chain ID
                verifyingContract: event?.blockChain?.contractAddress, // Replace with the address of the event instance contract
            };

            const type = [
                { name: 'description', type: 'string' },
            ];

            // Define the data to be signed
            const message = {
                description: challengeString, // Provide the description of the challenge here
            };

            // Define the data object according to the ERC712 standard
            const data = {
                types: {
                    ChallengeInfo: type,
                },
                domain,
                primaryType: 'ChallengeInfo',
                message,
            };

            // Sign the typed data
            const signature = await signTypedDataAsync(data);

            // Store the signature in the backend for future use
            return signature
        } catch (ex) {
            console.log('Error in typed data', ex)
            return ''
        }
    }


    async function handleRefundChallenge() {
        try {
            if (!selectedRefundChallenge?._id)
                return toast.info('Please select at least a Prediction before making refund!')

            setLoading(true)

            const decisionRes = await api.post(`challenge/${selectedRefundChallenge?._id}/refund`)

            const { event } = decisionRes?.data ?? {}
            const foundIndex = findIndex(challenges, (o) => o?._id === selectedRefundChallenge?._id)
            if (foundIndex > -1) {
                const rawChallenges = [...challenges]
                set(rawChallenges, `[${foundIndex}]`, { ...rawChallenges[foundIndex], playStatus: 'REFUND' })
                dispatch(updateEventWithObjAction({ decisionTakenTime: event?.decisionTakenTime, playersCount: event?.playersCount, challenges: rawChallenges }))

                toast.success('Refund to prediction process successful')
            }
            setSelectedRefundChallenge({})

        } catch (ex) {
            toast.error(somethingWrong)
        } finally {
            setLoading(false)
        }
    }


    const { confirmedChallenges, waitingChallenges } = useMemo(() => {
        if (!challenges) {
            return { confirmedChallenges: [], waitingChallenges: [] };
        }
        // Separate challenges into true and false arrays
        return challenges.reduce(
            (result, challenge) => {
                if (challenge.status) {
                    result.confirmedChallenges.push(challenge);
                } else {
                    result.waitingChallenges.push(challenge);
                }
                return result;
            },
            { confirmedChallenges: [], waitingChallenges: [] }
        );
    }, [challenges, challenges?.length]);

    return <>
        <div className="event__main__box_second">
            <div className="box__event__page">
                <div className="box__head">
                    <div
                        className="box__head__left__event__page"
                        style={{ position: "relative" }}
                    >
                        <p># Predictions </p>
                    </div>
                    <div className="box__head__right__event__page">
                        <span className="span__Ps">
                            {
                                type === 'DECISION' || event?.decisionTakenTime ?
                                    <p style={{ position: "relative", marginLeft: 'auto' }}>
                                        Result
                                    </p>
                                    :
                                    type === 'PLAY' ?
                                        <></>
                                        : type === 'DEFAULT' &&
                                        <>
                                            <p style={{ position: "relative" }}>
                                            </p>
                                            <p style={{ position: "relative" }}>
                                                Max odds
                                            </p>
                                        </>
                            }
                        </span>
                    </div>
                </div>
                {
                    Array.isArray(confirmedChallenges) &&
                    confirmedChallenges.map((val, ind) => (
                        <div
                            key={ind}
                            className={` box__one__event__page ${includes(selectedChallengeIds, val?._id) ? 'selected__challenge' : ''}`}
                            onClick={() => {
                                if (!event?.decisionTakenTime)
                                    if (val?.playStatus === 'DEFAULT' && type === 'DEFAULT')
                                        handleOpenMakePlay(val)
                            }}
                        >
                            <div className="box__one__left">
                                <div className=" flex flex_direction_col logic_container">
                                    <h3 className=" truncate font_sm"> {val?.title} </h3>
                                    <div className="font_8px opacity_50 leading-none">${Number(get(val, 'challengeVolume[0].volume', 0))}</div>
                                </div>
                            </div>
                            {

                                type === 'DECISION' || type === 'PLAY' || val?.playStatus === 'REFUND' || event?.decisionTakenTime ?
                                    <div className="box__one__right">
                                        <span></span>
                                        {val?.playStatus === 'WIN'
                                            ?
                                            <TextBadge text={'Winner'} colorVariantClass="green" />
                                            :
                                            val?.playStatus === 'LOSS' || val?.playStatus === 'REFUND'
                                                ?
                                                <TextBadge text={val?.playStatus === 'LOSS' ? 'Loss' : 'Refunded'} colorVariantClass="pink" />
                                                : val?.playStatus === 'DEFAULT' ?
                                                    <>
                                                        {type === 'DECISION' &&
                                                            <span onClick={(e) => {
                                                                e.stopPropagation();
                                                                onSelectChallenge(val?._id)
                                                            }} className="span_one less_margin text_pointer"
                                                                style={{ backgroundColor: '#FFCACA', color: 'black' }}
                                                            >
                                                                <p>{includes(selectedChallengeIds, val?._id) ? 'Selected' : 'Select'}</p>
                                                            </span>}
                                                        {type !== 'DECISION' &&
                                                            <TextBadge
                                                                colorVariantClass="pink" text={'Refund'}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toast.info('Feature coming soon!')
                                                                    // (isDuringPlay && !setSelectedRefundChallenge?.(val)) || toast.info('A player only could be refunded during the Playtime.')
                                                                }}
                                                            />
                                                            // <span
                                                            //     onClick={(e) => {
                                                            //         e.stopPropagation();
                                                            //         (isDuringPlay && !setSelectedRefundChallenge?.(val)) || toast.info('A player only could be refunded during the Playtime.')
                                                            //     }
                                                            //     }
                                                            //     style={{ backgroundColor: '#FFCACA', color: 'black' }}
                                                            //     className="span_one less_margin text_pointer"
                                                            // >
                                                            //     <p>Refund</p>
                                                            // </span>
                                                        }

                                                    </> :
                                                    <></>
                                        }
                                    </div>

                                    :
                                    type === 'DEFAULT' && <div className="box__one__right">
                                        {/* <span className="span_one">
                                            <img src={Dollar} alt="" />
                                            <p>{formatNumber(get(val, 'plays[0].myPlay', 0))}</p>
                                        </span> */}
                                        <BadgeMakaoMoney amount={formatNumber(get(val, 'plays[0].myPlay', 0))} className="large" />
                                        <BadgeOdd odd={val?.odd ?? 1} changePercentage={get(val, 'changePercentage', 0)} className="large" />
                                        {/* <span className={`span_two ${get(val, 'changePercentage') > 0 ? 'odd_cyan_shadow' : 'odd_pink_shadow'}`}>
                                            <p>x {val?.odd ?? 1}</p>
                                            <ArrowsComponent changePercentage={get(val, 'changePercentage', 0)} />
                                        </span> */}
                                    </div>
                            }

                        </div>
                    ))}

                {
                    (type === 'PLAY' || (type === 'DEFAULT' && event?.proposal)) && moment().isBefore(event?.endTime) &&
                    <div className="box__three" onClick={handleOpenOutcome}>
                        <div className="plus_icon">+</div>
                        <p>Create a prediction</p>
                    </div>
                }

            </div>

            {
                type !== 'DECISION' && Array.isArray(waitingChallenges) && moment().isBefore(event?.endTime) &&
                waitingChallenges.map((val, ind) => (
                    <div key={ind} className="box__last" onClick={() => handleOpenMakePlay(val)}>
                        <div className="box__last__left">
                            <h3>{val?.title} </h3>
                        </div>
                        <div className="box__last__right">
                            {type === 'PLAY' &&
                                <span onClick={(e) => {
                                    e.stopPropagation();
                                    handleProposalAccept(val?._id)
                                }}
                                    className="span_one accept_button">
                                    <p> Accept </p>
                                </span>}
                            {
                                type === 'DEFAULT' &&
                                <span className="span_one">
                                    <p> Waiting approval </p>
                                </span>
                            }

                        </div>
                    </div>
                ))}

        </div >

        <PossibleOutcomes
            handleClose={handleCloseOutcome}
            open={openOutcome}
            buttonText={type === 'PLAY' ? "Add Prediction" : "Request Prediction"}
            onSubmitForm={onSubmitChallenge}
            loading={loading}
        />

        <Place_a_bet_popup
            handleClose={() => setOpenMakePlay(false)}
            open={openMakePlay}
            data={selectedChallenge}
            eventId={eventId}
        />

        {
            selectedRefundChallenge?._id &&
            <RefundChallengeOrPlayer data={selectedRefundChallenge} open={selectedRefundChallenge?._id}
                onConfirm={handleRefundChallenge}
                onCancel={() => setSelectedRefundChallenge({})}
                loading={loading}
            />
        }

        <MaxYield handleClose={handleCloseMaxYield} open={openMaxYield} />
        <MyPlay handleClose={handleCloseMyPlay} open={openMyPlay} />
        <Info_Possible_outcomes
            handleClose={handleClosePossibleOutcome}
            open={openPossibleOutcome}
        />
        <Proposal handleClose={handleCloseProposal} open={openProposal} />
    </>
}

