import React from "react";
import { Link } from "react-router-dom";
import "./transaction.scss";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { Close, GifTransaction } from "../../../assets/images";
import { ModalContainer } from "../../modalContainer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Transaction1 = ({ loadingStates, open }) => {
  const {
    openTran, isStakeApprovePending, isStakePending, isStakeApproveConfirming, isStakeConfirming
  } = loadingStates ?? {}
  return (
    <ModalContainer
      open={open}
      showClose={false}
      heading="Transaction loading">
      <div className="complete spin">
        <img src={GifTransaction} alt="" style={{ width: "80px" }} />
        <Typography
          id="modal-modal-description"
          sx={{ mt: 5, color: "#FFFFFF" }}
        >
          We are processing your participation.
          {/* {
            isStakeApprovePending || isStakeApproveConfirming ?
              `We are processing the stake approval from you account`
              : isStakePending || isStakeConfirming ?
                `We are processing the stake now`
                : openTran &&
                `We are processing your participate information`
          } */}
          <br />
          Please wait...
        </Typography>
      </div>
    </ModalContainer>
  );
};

export default Transaction1;
