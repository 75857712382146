import React from "react";
import CustomStepper from "../stapper/CustomStapper";
import StepOne from "../stapper/StepOne";


export default function Create__Event() {
  return (
    <CustomStepper step={0}>
      <StepOne />
    </CustomStepper>
  );
};

;
