import { createSlice } from '@reduxjs/toolkit'
import { CURRENT_EVENT, HISTORY_EVENT, ORGANISED_EVENT, fetchEventAction, fetchEventsAction, fetchFriendsEventsAction, fetchFriendsLeaderboardAction, fetchFriendsTrendingEventsAction, fetchOrganisersLeaderboardAction, fetchTimeUpEventsAction, fetchTimeUpEventsActionAll, fetchTrendingEventsAction, fetchUserEventsAction, fetchUserOrganisedEvent, } from '../actions/eventAction';
import { toast } from 'react-toastify';
import { somethingWrong } from '../../util/helper';

const initialState = {
  trendingEvents: [],
  timeUpEvents: [],
  events: [],
  page: 1,
  pageSize: 20,
  disableLoadMore: false,
  status: 'idle',

  event: {},

  user: {
    organised: {},
    current: {},
    history: {}
  },

  trendingFriendEvents: [],

  timeUpEventsAll: {
    events: [],
    page: 1,
    pageSize: 20,
    disableLoadMore: false,
    status: 'idle',
  },

  friendsEvents: {
    events: [],
    page: 1,
    pageSize: 20,
    disableLoadMore: false,
    status: 'idle',
  },

  friendsLeaderboard: {
    friends: [],
    page: 1,
    pageSize: 20,
    disableLoadMore: false,
    status: 'idle',
  },

  organisersLeaderboard: {
    organisers: [],
    page: 1,
    pageSize: 20,
    disableLoadMore: false,
    status: 'idle',
  },

}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    updateEventChallengeAction: (state, action) => {
      const challenges = state?.event?.challenges ?? []
      if (action?.payload)
        challenges.push(action.payload)
      state.event.challenge = challenges
    },
    updateEventAction: (state, action) => {
      if (action?.payload)
        state.event = action?.payload
    },
    updateEventWithObjAction: (state, action) => {
      if (action?.payload)
        state.event = { ...state.event, ...action.payload }
    },
    updateEventLoadingStatus: (state, action) => {
      if (action?.payload)
        state.status = action?.payload
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendingEventsAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTrendingEventsAction.fulfilled, (state, action) => {
        const { data } = action?.payload ?? {}
        state.status = 'succeeded';
        state.trendingEvents = data
      })
      .addCase(fetchTrendingEventsAction.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchTimeUpEventsAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTimeUpEventsAction.fulfilled, (state, action) => {
        const { data } = action?.payload ?? {}
        state.status = 'succeeded';
        state.timeUpEvents = data
      })
      .addCase(fetchTimeUpEventsAction.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchEventsAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventsAction.fulfilled, (state, action) => {
        const { data, page, pageSize } = action?.payload ?? {}
        state.status = 'succeeded';
        if (Array.isArray(data) && state.page === page) {
          state.events = state.events.concat(data)
          state.page = page + 1 ?? 1
          if (data.length < pageSize) {
            state.disableLoadMore = true
            if (page > 1)
              toast.info('No more events')
          }
        }
      })
      .addCase(fetchEventsAction.rejected, (state) => {
        state.status = 'failed';
      })

      //Time almost up
      .addCase(fetchTimeUpEventsActionAll.pending, (state) => {
        state.timeUpEventsAll.status = 'loading';
      })
      .addCase(fetchTimeUpEventsActionAll.fulfilled, (state, action) => {
        const { data, page, pageSize } = action?.payload ?? {}
        state.timeUpEventsAll.status = 'succeeded';
        if (Array.isArray(data) && state.page === page) {
          state.timeUpEventsAll.events = state.timeUpEventsAll?.events?.concat(data)
          state.timeUpEventsAll.page = page + 1 ?? 1
          if (data.length < pageSize) {
            state.timeUpEventsAll.disableLoadMore = true
            if (page > 1)
              toast.info('No more events')
          }
        }
      })
      .addCase(fetchTimeUpEventsActionAll.rejected, (state) => {
        state.timeUpEventsAll.status = 'failed';
      })

      //Single event --->>>
      .addCase(fetchEventAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.event = action.payload?.data ?? {}
      })
      .addCase(fetchEventAction.rejected, (state) => {
        state.status = 'failed';
        toast.error(somethingWrong)
      })

      //Organised  events --->>>
      .addCase(fetchUserOrganisedEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserOrganisedEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.organised = action.payload?.data ?? [];
      })
      .addCase(fetchUserOrganisedEvent.rejected, (state) => {
        state.status = 'failed';
        toast.error(somethingWrong)
      })

      //Current and History events --->>>
      .addCase(fetchUserEventsAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserEventsAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.meta.arg?.type === CURRENT_EVENT)
          state.user.current = action.payload?.data ?? [];

        if (action.meta.arg?.type === HISTORY_EVENT)
          state.user.history = action.payload?.data ?? [];
      })
      .addCase(fetchUserEventsAction.rejected, (state) => {
        state.status = 'failed';
        toast.error(somethingWrong)
      })



      .addCase(fetchFriendsTrendingEventsAction.fulfilled, (state, action) => {
        const { data } = action?.payload ?? {}
        state.trendingFriendEvents = data
      })

      .addCase(fetchFriendsTrendingEventsAction.rejected, (state) => {
        state.friendsEvents.status = 'failed';
      })

      //Friends events pagination--->>>
      .addCase(fetchFriendsEventsAction.pending, (state) => {
        state.friendsEvents.status = 'loading';
      })
      .addCase(fetchFriendsEventsAction.fulfilled, (state, action) => {
        const { data, page, pageSize } = action?.payload ?? {}
        state.friendsEvents.status = 'succeeded';
        if (Array.isArray(data) && state.friendsEvents.page === page) {
          state.friendsEvents.events = state.friendsEvents.events.concat(data)
          state.friendsEvents.page = page + 1 ?? 1
          if (data.length < pageSize) {
            state.friendsEvents.disableLoadMore = true
            if (page > 1)
              toast.info('No more events')
          }
        }
      })
      .addCase(fetchFriendsEventsAction.rejected, (state) => {
        state.friendsEvents.status = 'failed';
      })


      //Friends Leaderboard --->>>
      .addCase(fetchFriendsLeaderboardAction.pending, (state) => {
        state.friendsLeaderboard.status = 'loading';
      })
      .addCase(fetchFriendsLeaderboardAction.fulfilled, (state, action) => {
        const { data, page, pageSize } = action?.payload ?? {}
        state.friendsLeaderboard.status = 'succeeded';
        if (Array.isArray(data) && state.friendsLeaderboard.page === page) {
          state.friendsLeaderboard.friends = state.friendsLeaderboard.friends.concat(data)
          state.friendsLeaderboard.page = page + 1 ?? 1
          if (data.length < pageSize) {
            state.friendsLeaderboard.disableLoadMore = true
            if (page > 1)
              toast.info('No more friends details')
          }
        }
      })
      .addCase(fetchFriendsLeaderboardAction.rejected, (state) => {
        state.friendsLeaderboard.status = 'failed';
      })

      //Organiser Leaderboard --->>>
      .addCase(fetchOrganisersLeaderboardAction.pending, (state) => {
        state.organisersLeaderboard.status = 'loading';
      })
      .addCase(fetchOrganisersLeaderboardAction.fulfilled, (state, action) => {
        const { data, page, pageSize } = action?.payload ?? {}
        state.organisersLeaderboard.status = 'succeeded';
        if (Array.isArray(data) && state.organisersLeaderboard.page === page) {
          state.organisersLeaderboard.organisers = state.organisersLeaderboard.organisers.concat(data)
          state.organisersLeaderboard.page = page + 1 ?? 1
          if (data.length < pageSize) {
            state.organisersLeaderboard.disableLoadMore = true
            if (page > 1)
              toast.info('No more details')
          }
        }
      })
      .addCase(fetchOrganisersLeaderboardAction.rejected, (state) => {
        state.organisersLeaderboard.status = 'failed';
      })
  },
})

export const { updateEventChallengeAction, updateEventAction, updateEventLoadingStatus, updateEventWithObjAction } = eventSlice.actions

export default eventSlice.reducer