import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./event__page.scss";
import {
  Eye
} from "../../assets/images";
import { useParams } from "react-router-dom";
import EventCard from "../eventCard";
import { toast } from "react-toastify";
import { EventType, getAverageTrustNote, somethingWrong } from "../../util/helper";
import { fetchEventAction } from "../../store/actions/eventAction";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../loader";
import { ChallengeSection } from "../challengeSection";
import { fetchUserAction, isBlacklistedUserAction } from "../../store/actions/userAction";
import ReviewDecision from "../Popups/reviewDecision/ReviewDecision";
import Button from "../button/Button";
import { ActionButtons } from "../stapper/ActionButtons";
import TrustNotes from "../infoPopups/trustNotes/TrustNotes";
import TrustNoteProgressBar from "../TrustNote__ProgressBar";
import { useWeb3Auth } from "../../context/useWeb3auth";
import { ReactSVG } from "react-svg";
import { BadgeTrustNote } from "../badge";

const Event__Page = () => {
  const { loggedIn } = useWeb3Auth()
  const { _id: eventId } = useParams()
  const dispatch = useDispatch();
  const { event, status: loadingStatus } = useSelector((state) => state.event);
  const { user: { _id: userId, isBlacklisted } } = useSelector((state) => state.user);
  const { challenges, startTime, _id, decisionTakenTime, status, averageReview, createdBy, blockChain } = event ?? {}
  const currentUserIsPlayer = Array.isArray(challenges) && challenges.some(challenge => Array.isArray(challenge.plays) && challenge.plays.length > 0)
  const [showTrustNoteInfo, setShowTrustNoteInfo] = useState(false)

  const [isReviewDecisionOpen, setReviewDecisionOpen] = React.useState(false);
  const handleCloseReviewDecision = () => setReviewDecisionOpen(false);

  const [isReviewGiven, setIsReviewGiven] = useState(false)
  const [{ challengesList, claimsList }, setChangeList] = React.useState({
    challengesList: [],
    claimsList: [],
  });

  const onRefChange = useCallback((nodeOrState) => {
    if (nodeOrState) {
      setChangeList(nodeOrState);
    }
  }, []);


  const handleOpenReviewDecision = async () => {
    try {
      if (!isBlacklisted) {
        setReviewDecisionOpen(true)
      }
    } catch (error) {
      toast.error(somethingWrong)
    }
  }

  const averageTrustScore = useMemo(() => getAverageTrustNote(averageReview), [averageReview]);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventAction({ eventId }))
      if (loggedIn) {
        dispatch(isBlacklistedUserAction(eventId))
        //fetching users details, if not existed
        if (!userId)
          dispatch(fetchUserAction())
      }
    }
  }, [eventId, loggedIn])

  return (
    <>
      <Loader loading={loadingStatus === "loading"} />

      <div className="event__page">
        <div className="event__main__box">
          <div style={{ width: "100%" }}>
            <EventCard data={event} isReviewGiven={isReviewGiven || currentUserIsPlayer || userId === createdBy} />
          </div>

          <div className="action_buttons_container_event">
            <ActionButtons
              usingFor={"EVENT"}
              isBlacklisted={isBlacklisted}
            />
          </div>

          {loadingStatus === "succeeded" ? (
            currentUserIsPlayer &&
              (challengesList.length !== 0 || claimsList?.length !== 0) &&
              decisionTakenTime ? (
              <div className=" event_single_action_button">
                <Button
                  onClick={handleOpenReviewDecision}
                  colorVariant={'dark_outline_cyan'}
                >
                  <div className=" flex_center">
                    <ReactSVG src={Eye} alt="eye_icon" className="svg_container" />
                    <span>{status === EventType.refund ? 'Claim my refund' : 'Review & Claim'}</span>
                  </div>
                </Button>
              </div>
            ) : (
              (currentUserIsPlayer || userId === createdBy) &&
                decisionTakenTime && Array.isArray(averageReview) ?
                <>
                  {averageReview.length ? (
                    <TrustNoteProgressBar
                      {...{ status, averageTrustScore, setShowTrustNoteInfo }}
                      style={{ paddingInline: "3.5%" }}
                      className={" w_73 m-auto mt_6 "}
                    />
                  ) : averageReview.length === 0 && status !== 'REFUND' &&
                  <div className="w_fit mx-auto"><BadgeTrustNote noReview={true} sizeVariant="small" /></div>
                  }
                </>
                : <></>

            )
          ) : null}
        </div>

        <div className="event_challenges_container">
          <ChallengeSection
            challenges={challenges}
            data={{ startTime, eventId }}
            isBlacklisted={isBlacklisted}
          />
        </div>
      </div >

      <TrustNotes show={showTrustNoteInfo} setShow={setShowTrustNoteInfo} />

      <ReviewDecision
        ref={onRefChange}
        handleClose={handleCloseReviewDecision}
        open={isReviewDecisionOpen}
        eventId={_id}
        eventStatus={status}
        setIsReviewGiven={setIsReviewGiven}
        blockChain={blockChain}
      />
    </>
  );
};

export default Event__Page;
