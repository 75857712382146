/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import { getKeyFromCookie, removeCookie } from './helper';

if (!process.env.REACT_APP_API_URL) {
    throw new Error('REACT_APP_API_URL has not been exported');
}
export const API_ENDPOINT = process.env.REACT_APP_API_URL;


const api = axios.create({
    baseURL: `${API_ENDPOINT}api/v1/`,
    timeout: 200000,
    headers: {
        'Content-type': 'application/json',
    },
});

api.interceptors.request.use(function (config) {
    // const token = localStorage.getItem('token');
    const token = getKeyFromCookie('token');
    const secp256k1 = getKeyFromCookie('secp256k1')
    const address = getKeyFromCookie('address')


    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }

    if (secp256k1)
        config.headers.appPubKey = secp256k1;
    else if (address)
        config.headers.address = address;

    return config;
});

api.interceptors.response.use(
    function (response) {
        // You can process the response data here before returning it
        return { status: response.status, ...response.data }; // Return the response data
    },
    function (error) {
        if (error?.response?.status === 401) {
            localStorage?.clear();
            var Cookies = document.cookie.split(";");
            // set 1 Jan, 1970 expiry for every cookies
            for (var i = 0; i < Cookies.length; i++)
                document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
        }

        // Handle any error that occurred during the request
        const data = error?.response.data ?? {};
        return Promise.reject({ status: error?.response?.status, ...data });
    },
);

export default api;
if (global.window) {
    (window).api = api;
}