import { useNavigate } from "react-router-dom";
import Button from "../components/button/Button";
import { useWeb3Auth } from "../context/useWeb3auth";

const ProtectedComponent = ({ children }) => {
  const { loggedIn } = useWeb3Auth()
  const navigate = useNavigate()

  if (loggedIn)
    return children
  return <div className="not_login_container">
    <Button className={'max_width_30vh'} onClick={() => {
      navigate('/signup');
      window?.location?.reload();
    }}>Login</Button>
  </div>
};

export default ProtectedComponent;
