import React, { useEffect, useState } from "react";
import "./linksForUser.scss";
import { Box, Modal, Typography } from "@mui/material";
import { Close, Eye, EditIcon } from "../../../assets/images";
import api from "../../../util/api";
import { toast } from "react-toastify";
import { somethingWrong } from "../../../util/helper";
import { useDispatch } from "react-redux";
import { updateEventWithObjAction } from "../../../store/slices/eventSlice";
import { updateUserStateWithObjAction } from "../../../store/slices/userSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const LinksForUser = ({ handleClose, open, data, isEditable = false, onlyUser = false }) => {
  const dispatch = useDispatch()
  const { username, _id } = data ?? {}
  const [editLinkType, updateEditLinkType] = useState('');
  const [link, setLink] = useState('')
  const [loading, setLoading] = useState(false)

  const [links, setLinks] = useState({ videoLink: data?.videoLink, instagramLink: data?.instagramLink, discordLink: data?.discordLink })

  const [linksDetails, setLinksDetails] = useState(onlyUser ?
    [{ name: `@${username} on Instagram`, emptyTitle: 'Instagram link not added', type: 'instagramLink', }]
    : [
      { name: 'Watch live event', emptyTitle: 'No link added', type: 'videoLink' },
      { name: 'Join our discord to discuss the event', emptyTitle: 'Discord link not added', type: 'discordLink' },
      { name: `@${username} on Instagram`, emptyTitle: 'Instagram link not added', type: 'instagramLink', }
    ])

  useEffect(() => {
    const { videoLink, discordLink, instagramLink } = data ?? {}
    if (videoLink || discordLink || instagramLink)
      setLinks({ videoLink, discordLink, instagramLink })
  }, [data])

  const handleUpdateLink = async () => {
    try {
      setLoading(true)
      if (editLinkType === 'videoLink' || editLinkType === 'discordLink') {
        const payload = {}
        if (editLinkType === 'videoLink')
          payload.videoLink = link
        else if (editLinkType === 'discordLink')
          payload.discordLink = link
        await api.patch(`event/${_id}`, payload)
        dispatch(updateEventWithObjAction(payload))
      } else if (editLinkType === 'instagramLink') {
        const payload = { instagramLink: link }
        await api.patch('user', payload)
        dispatch(updateUserStateWithObjAction(payload))

      }
      toast.success('Link updated successfully')
      setLoading(false)
      updateEditLinkType('')
    } catch (ex) {
      toast.error(somethingWrong)
      setLoading(false)
      updateEditLinkType('')
    }
  }

  return (
    <>
      {/* <Button onClick={handleOpen}>
        Pop up links for users (Live video and other links)
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popup">

          <div className="image-container">
            <img src={Close} alt="Image" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            className="popup_heading"
            variant="h6"
            component="h2"
          >
            {isEditable ? "Edit Links" : 'Links'}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="vertical__buttons__popups">

              {
                linksDetails.map((val, ind) => (<>
                  <div className=" horizontal_two">
                    <div onClick={() => {
                      if (links?.[val?.type])
                        window.open(links?.[val?.type], '_blank', 'noreferrer');
                    }} className="vertical__buttons__first__pop">
                      {links?.[val?.type] ? <><img src={Eye} alt="img" />
                        <span>{val?.name}</span></>
                        : <span>{val?.emptyTitle ?? 'No link added'}</span>}
                    </div>
                    {
                      isEditable ? <div>
                        <div className="noti__head__right">
                          <img
                            src={EditIcon}
                            alt=""
                            className=" icon_img"
                            onClick={() => {
                              updateEditLinkType((prev) => { return prev === val?.type ? '' : val?.type });
                              setLink(links?.[val?.type] ?? '')
                            }}
                          />
                        </div>
                      </div> : <></>
                    }
                  </div>
                  {
                    editLinkType === val?.type &&
                    <div className=" edit__event__continer">
                      <div
                        className="input__groups__outcome"
                        style={{ marginTop: "1rem" }}
                      >
                        <label>Update link </label>
                        <input
                          type="text"
                          placeholder=""
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                        />{false && <span className="error_email">This field is required</span>}
                      </div>
                      <button disabled={loading} onClick={handleUpdateLink} className="update__link__btn">Update</button>
                    </div>
                  }</>))
              }

              {/* <div className=" horizontal_two">
                <div onClick={() => {
                  if (videoLink)
                    window.open(videoLink, '_blank', 'noreferrer');
                }} className="vertical__buttons__first__pop">
                  {videoLink ? <><img src={Eye} alt="img" />
                    <span> Watch live event </span></>
                    : <span> No Link Added</span>}
                </div>
                {
                  isEditable ? <div>
                    <div className="noti__head__right">
                      <img
                        src={EditIcon}
                        alt=""
                        className=" icon_img"
                        onClick={() => {
                          updateEditLinkType((prev) => !prev);
                          setLink(videoLink)
                        }}
                      />
                    </div>
                  </div> : <></>
                }
              </div> */}


              {/* <div className="vertical__buttons__secound__pop">
                <span> Join our discord to discuss the event </span>
              </div>
              <div className="vertical__buttons__third__pop">
                <span> Steve on Instagram </span>
              </div> */}

            </div >
          </Typography>
        </Box>
      </Modal >
    </>
  );
};

export default LinksForUser;
