import React from "react";
import "./cancelRefund.scss";
import { Typography } from "@mui/material";
import EventCard from "../../eventCard";
import { Loader } from "../../loader";
import { ModalContainer } from "../../modalContainer";
import Button from "../../button/Button";


const CancelAndRefundEvent = ({ data, open, handleConfirm, handleClose, loading,
  heading = 'Cancel & Refund event',
  subHeading = 'Are you sure you want to terminate and refund the following event' }) => {
  return (
    <ModalContainer
      open={open}
      handleClose={handleClose}
      heading={heading}>
      <Loader loading={loading} />
      <Typography
        id="modal-modal-description"
        sx={{ mt: 2, color: "#FFFFFF" }}
      >
        {subHeading}
      </Typography>

      <EventCard data={data} />

      <div className="cancel_refund_btns">
        <Button disabled={loading} onClick={handleClose} colorVariant="pink" >
          <span>Cancel</span>
        </Button>
        <Button disabled={loading} onClick={handleConfirm} colorVariant="green" >
          <span>Confirm</span>
        </Button>
      </div>
    </ModalContainer>
  );
};

export default CancelAndRefundEvent;
