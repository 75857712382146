import React from "react";
import HistoryReferrals from "../Popups/historyRefrellals/HistoryReferrals";
import BetsHistory from "../Popups/BetsHistory/BetsHistory";
import Claim from "../Popups/claim/Claim";
import ClaimsPopup from "../Popups/claimsPopup/ClaimsPopup";
import LinksForUser from "../Popups/linksForUser/LinksForUser";
import EditLinks from "../Popups/editLinks/EditLinks";
import PossibleOutcomes from "../Popups/possibleOutcomes/PossibleOutcomes";
import AddComment from "../Popups/addComment/AddComment";
import ReadMore from "../Popups/readMore/ReadMore";
import ManagePlayers from "../Popups/managePlayers/ManagePlayers";
import Following from "../Popups/following/Following";
import PlayersPopup from "../Popups/players/PlayersPopup";
import Transaction1 from "../Popups/Transactions1/Transaction1";
import Transaction2 from "../Popups/Transactions1/Transaction2";
import Transaction3 from "../Popups/Transactions1/Transaction3";
import Transaction4 from "../Popups/Transactions1/Transaction4";
import ChangeNetwork from "../Popups/network/ChangeNetwork";
import ReviewDecision from "../Popups/reviewDecision/ReviewDecision";
import Stacking from "../Popups/stacking/Stacking";
import PopupTimeline from "../Popups/Pop up Timeline/PopupTimeline";
import InfoOrganizer from "../infoPopups/infoOrganizer/InfoOrganizer";
import TimeLine from "../infoPopups/eventTimeline/TimeLine";
import TrustNotes from "../infoPopups/trustNotes/TrustNotes";
import MyPlay from "../infoPopups/myplay/MyPlay";
import MaxYield from "../infoPopups/maxYield/MaxYield";
import Proposal from "../infoPopups/proposal/Proposal";
import TimeLine2 from "../infoPopups/timeline/TimeLine2";
import Celebrity from "../infoPopups/celebrity/Celebrity";
import CurrentTop from "../infoPopups/currentTop/CurrentTop";
import OGs from "../infoPopups/OGs/OGs";
import Trusted from "../infoPopups/trusted/Trusted";
import Unknown from "../infoPopups/unknown/Unknown";
import NotTrusted from "../infoPopups/not_trusted/NotTrusted";
import SecreteEvent from "../infoPopups/secrentEvent/SecreteEvent";
import PrivateEvents from "../infoPopups/privateEvents/PrivateEvents";
import PublicEvents from "../infoPopups/publicEvents/PublicEvents";
import Won from "../infoPopups/won/Won";
import Lost from "../infoPopups/lost/Lost";
import PotentialWin from "../infoPopups/PotentialWIn/PotentialWin";
import CurrentBalance from "../infoPopups/CurrentBalance/CurrentBalance";
import WalletInfo from "../infoPopups/walletInfo/WalletInfo";
import PrivateAccount from "../infoPopups/privateAccount/PrivateAccount";
import PublicAccount from "../infoPopups/PublicAccount/PublicAccount";
import Approval from "../infoPopups/approval/Approval";
import CancelAndRefundEvent from "../Popups/cancel&refund/CancelAndRefundEvent";
import RefundChallengeOrPlayer from "../Popups/cancel&refund/RefundChallenge";
import "./pup.scss";
import InfoKYCverified from "../infoPopups/infoKYCverified/InfoKYCverified";
import Place_a_bet_popup from "../Popups/place_a_bet-popup/Place_a_bet_popup";
import Place_a_bet_insufficient from "../Popups/place_a_bet-popup/Place_a_bet_insufficien";
import Place_a_bet_pop_up_waiting from "../Popups/place_a_bet-popup/Place_a_bet_pop_up_waiting";
import Info_Possible_outcomes from "../infoPopups/Info Possible outcomes/Info_Possible_outcomes";

const AllPopups = () => {
  return (
    <>
      <div className="all__pups">
        <button className="all__btn">
          <HistoryReferrals />
        </button>
        <button className="all__btn">
          <BetsHistory />
        </button>
        <button className="all__btn">
          <Claim />
        </button>
        <button className="all__btn">
          <ClaimsPopup />
        </button>
        <button className="all__btn">
          <CancelAndRefundEvent />
        </button>
        <button className="all__btn">
          <PopupTimeline />
        </button>
        <button className="all__btn">
          <RefundChallengeOrPlayer />
        </button>
        <button className="all__btn">
          <LinksForUser />
        </button>
        <button className="all__btn">
          <EditLinks />
        </button>
        <button className="all__btn">
          <PossibleOutcomes />
        </button>
        <button className="all__btn">
          <AddComment />
        </button>
        <button className="all__btn">
          <ReadMore />
        </button>
        <button className="all__btn">
          <ManagePlayers />
        </button>
        <button className="all__btn">
          <Following />
        </button>
        <button className="all__btn">
          <PlayersPopup />
        </button>
        <button className="all__btn">
          <Place_a_bet_popup />
        </button>
        <button className="all__btn">
          <Place_a_bet_insufficient />
        </button>
        <button className="all__btn">
          <Place_a_bet_pop_up_waiting />
        </button>
        <button className="all__btn">
          <Transaction1 />
        </button>
        <button className="all__btn">
          <Transaction2 />
        </button>
        <button className="all__btn">
          <Transaction3 />
        </button>
        <button className="all__btn">
          <Transaction4 />
        </button>
        <button className="all__btn">
          <ChangeNetwork />
        </button>
        <button className="all__btn">
          <ReviewDecision />
        </button>
        <button className="all__btn">
          <Stacking />
        </button>
      </div>
      <h1 style={{ marginTop: "2rem" }}> Info Popups </h1>
      <div className="all__pups">
        <button className="all__btn">
          <InfoOrganizer />
        </button>
        <button className="all__btn">
          <TimeLine />
        </button>
        <button className="all__btn">
          <TrustNotes />
        </button>
        <button className="all__btn">
          <Info_Possible_outcomes />
        </button>
        <button className="all__btn">
          <MyPlay />
        </button>
        <button className="all__btn">
          <MaxYield />
        </button>
        <button className="all__btn">
          <Proposal />
        </button>
        <button className="all__btn">
          <TimeLine2 />
        </button>
        <button className="all__btn">
          <InfoKYCverified />
        </button>
        <button className="all__btn">
          <Celebrity />
        </button>
        <button className="all__btn">
          <CurrentTop />
        </button>
        <button className="all__btn">
          <OGs />
        </button>
        <button className="all__btn">
          <Trusted />
        </button>
        <button className="all__btn">
          <Unknown />
        </button>
        <button className="all__btn">
          <NotTrusted />
        </button>
        <button className="all__btn">
          <SecreteEvent />
        </button>
        <button className="all__btn">
          <PrivateEvents />
        </button>
        <button className="all__btn">
          <PublicEvents />
        </button>
        <button className="all__btn">
          <Won />
        </button>
        <button className="all__btn">
          <Lost />
        </button>
        <button className="all__btn">
          <PotentialWin />
        </button>
        <button className="all__btn">
          <CurrentBalance />
        </button>
        <button className="all__btn">
          <WalletInfo />
        </button>
        <button className="all__btn">
          <PrivateAccount />
        </button>
        <button className="all__btn">
          <PublicAccount />
        </button>
        <button className="all__btn">
          <Approval />
        </button>
      </div>
    </>
  );
};

export default AllPopups;
